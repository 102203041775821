import axios from 'axios';
import _ from 'lodash';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/notes`;
  },

  create(note, success, failure) {
    if (!success) return axios.post(this.baseUrl(), { note });
    return axios.post(this.baseUrl(), { note }).then(success).catch(failure);
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters });
    return axios.get(this.baseUrl(), { params: filters }).then(success);
  },

  async update(note, errorCallback = handleError) {
    const response = await axios
      .patch(`${this.baseUrl()}/${note.id}`, { note: _.omit(note, ['isEditing', 'editedText']) })
      .catch(errorCallback);
    return response?.data;
  },

  async destroy(note) {
    const response = await axios
      .delete(`${this.baseUrl()}/${note.id}`, { note: _.omit(note, ['isEditing', 'editedText']) })
      .catch(console.error);
    return response?.data;
  },
};
