import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/team_memberships`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(team_membership) {
    return axios.post(this.baseUrl(), { team_membership }).catch(handleError);
  },

  async destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  async index(params) {
    return axios.get(this.baseUrl(), { params });
  },
};
