<template>
  <div
    class="h-100 w-100pc bg-white bb-1 bc-extra-light-gray bottom-200 pa-4"
    data-cy="message-thread-header"
  >
    <div class="d-flex align-center mb-2">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        :size="!$vuetify.display.mdAndUp && 'small'"
        class="c-black me-2"
        variant="text"
        icon
      >
        <v-icon>west</v-icon>
      </v-btn>
      <div
        v-text="title"
        :class="titleClass"
        class="d-inline-block"
      />
    </div>

    <v-btn
      v-if="isAssessment()"
      :active="false"
      :class="subtitleClass"
      :to="{ name: 'AssessmentShow', params: { assessmentId: assessment.id } }"
      class="c-light-black"
      data-testid="message-assessment-btn"
      variant="text"
    >
      {{ subtitle }}
      &nbsp;
      <v-icon> open_in_new </v-icon>
    </v-btn>

    <div
      v-else
      v-text="subtitle"
      :class="subtitleClass"
      class="c-light-black"
    />
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    assessment: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },

  emits: ['back'],

  computed: {
    subtitleClass() {
      if (this.$vuetify.display.mdAndUp) {
        return 'fs-16';
      }
      return 'fs-14';
    },

    titleClass() {
      if (this.$vuetify.display.mdAndUp) {
        return 'fs-22 fw-600';
      }
      return 'fs-18 fw-600';
    },
  },

  methods: {
    isAssessment() {
      return this.assessment;
    },
  },
};
</script>
