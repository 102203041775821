<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col
        class="labeled-input"
        cols="12"
      >
        <span>{{ $t(label) }}</span>

        <RequiredIndicator v-if="mandatory" />
      </v-col>

      <template v-if="description">
        <v-col
          class="fs-16 c-light-black my-1"
          cols="12"
        >
          <span>{{ $t(description) }}</span>
        </v-col>
      </template>

      <v-col cols="12">
        <v-text-field
          @click="open_date_dialog = !open_date_dialog"
          @click:clear="this.handleUpdateModelValue(null)"
          @keypress="open_date_dialog = !open_date_dialog"
          :append-inner-icon="locked ? 'lock' : ''"
          :aria-label="$t(ariaLabel)"
          :density="dense ? 'compact' : undefined"
          :disabled="disabled || locked"
          :model-value="localeDateString"
          :placeholder="disabled ? $t(disabledMessage) : placeholderText"
          data-cy="labeled-date-picker"
          prepend-inner-icon="event"
          variant="filled"
          clearable
          hide-details
          readonly
          tile
          v-bind="attrsOmitDataPrefix"
        />

        <v-dialog
          v-model="open_date_dialog"
          width="320px"
        >
          <v-date-picker
            v-model="dateModel"
            :allowed-dates="allowedDates"
            :max="max"
            :min="min"
            scrollable
          />
        </v-dialog>
      </v-col>
    </v-row>

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import Schematized from '@/shared/mixins/schematized';
import FilteredAttrs from '@/shared/mixins/FilteredAttrs';

export default {
  compatConfig: { MODE: 3 },

  components: {
    RequiredIndicator,
  },

  mixins: [Labeled, Schematized, FilteredAttrs],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: null,
    },
    disallowAfterDate: {
      type: String,
      default: null,
    },
    disallowBeforeDate: {
      type: String,
      default: null,
    },
    dividedBottom: {
      type: Boolean,
      default: false,
    },
    max: {
      default: null,
      type: String,
    },
    min: {
      default: null,
      type: String,
    },
    placeholderText: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      open_date_dialog: false,
    };
  },

  computed: {
    localeDateString() {
      if (!this.localValue) return null;

      return new Date(this.localValue).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'utc',
      });
    },

    dateModel: {
      get() {
        return this.localValue == null ? null : this.$vuetify.date.parseISO(this.localValue);
      },
      set(val) {
        this.open_date_dialog = false;
        this.handleUpdateModelValue(this.$vuetify.date.toISO(val));
      },
    },
  },

  methods: {
    allowedDates(date) {
      let allowed = true;
      if (this.disallowBeforeDate)
        allowed = allowed && new Date(date) >= new Date(this.disallowBeforeDate);
      if (this.disallowAfterDate)
        allowed = allowed && new Date(date) <= new Date(this.disallowAfterDate);
      return allowed;
    },
  },
};
</script>
