import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/parent/household_members/${id}`;

    return `${window.api_url}/parent/household_members`;
  },

  create(householdMember) {
    return axios.post(this.baseUrl(), { household_member: householdMember }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  update(householdMember) {
    return axios
      .patch(this.baseUrl(householdMember.id), { household_member: householdMember })
      .catch(handleError);
  },
};
