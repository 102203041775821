export default {
  dob_year_options: [
    {
      text: '2021',
      value: '2021',
    },
    {
      text: '2020',
      value: '2020',
    },
    {
      text: '2019',
      value: '2019',
    },
    {
      text: '2018',
      value: '2018',
    },
    {
      text: '2017',
      value: '2017',
    },
    {
      text: '2016',
      value: '2016',
    },
    {
      text: '2015',
      value: '2015',
    },
    {
      text: '2014',
      value: '2014',
    },
    {
      text: '2013',
      value: '2013',
    },
    {
      text: '2012',
      value: '2012',
    },
    {
      text: '2011',
      value: '2011',
    },
    {
      text: '2010',
      value: '2010',
    },
    {
      text: '2009',
      value: '2009',
    },
    {
      text: '2008',
      value: '2008',
    },
    {
      text: '2007',
      value: '2007',
    },
    {
      text: '2006',
      value: '2006',
    },
    {
      text: '2005',
      value: '2005',
    },
    {
      text: '2004',
      value: '2004',
    },
    {
      text: '2003',
      value: '2003',
    },
    {
      text: '2002',
      value: '2002',
    },
    {
      text: '2001',
      value: '2001',
    },
    {
      text: '2000',
      value: '2000',
    },
  ],
  month_day_options: [
    {
      text: '1',
      value: '01',
    },
    {
      text: '2',
      value: '02',
    },
    {
      text: '3',
      value: '03',
    },
    {
      text: '4',
      value: '04',
    },
    {
      text: '5',
      value: '05',
    },
    {
      text: '6',
      value: '06',
    },
    {
      text: '7',
      value: '07',
    },
    {
      text: '8',
      value: '08',
    },
    {
      text: '9',
      value: '09',
    },
    {
      text: '10',
      value: '10',
    },
    {
      text: '11',
      value: '11',
    },
    {
      text: '12',
      value: '12',
    },
    {
      text: '13',
      value: '13',
    },
    {
      text: '14',
      value: '14',
    },
    {
      text: '15',
      value: '15',
    },
    {
      text: '16',
      value: '16',
    },
    {
      text: '17',
      value: '17',
    },
    {
      text: '18',
      value: '18',
    },
    {
      text: '19',
      value: '19',
    },
    {
      text: '20',
      value: '20',
    },
    {
      text: '21',
      value: '21',
    },
    {
      text: '22',
      value: '22',
    },
    {
      text: '23',
      value: '23',
    },
    {
      text: '24',
      value: '24',
    },
    {
      text: '25',
      value: '25',
    },
    {
      text: '26',
      value: '26',
    },
    {
      text: '27',
      value: '27',
    },
    {
      text: '28',
      value: '28',
    },
    {
      text: '29',
      value: '29',
    },
    {
      text: '30',
      value: '30',
    },
    {
      text: '31',
      value: '31',
    },
  ],
  month_options: [
    {
      text: 'January',
      value: '01',
    },
    {
      text: 'February',
      value: '02',
    },
    {
      text: 'March',
      value: '03',
    },
    {
      text: 'April',
      value: '04',
    },
    {
      text: 'May',
      value: '05',
    },
    {
      text: 'June',
      value: '06',
    },
    {
      text: 'July',
      value: '07',
    },
    {
      text: 'August',
      value: '08',
    },
    {
      text: 'September',
      value: '09',
    },
    {
      text: 'October',
      value: '10',
    },
    {
      text: 'November',
      value: '11',
    },
    {
      text: 'December',
      value: '12',
    },
  ],
};
