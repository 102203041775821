<template>
  <div>
    <p>Redirecting...</p>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  created() {
    const { path } = this.$route;
    const redirect = this.$store.state.config.site_redirects.find(
      (siteRedirect) => siteRedirect.path === path,
    );
    if (redirect) {
      window.location.href = redirect.redirect_url;
    } else {
      this.$router.replace('/dashboard');
    }
  },
};
</script>
