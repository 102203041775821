export default {
  generate(colors) {
    let css = '';
    css += `body { --primary-color: ${colors.primary}; }`;
    css += `.bc-primary { border-color: ${colors.primary} !important; }`;
    css += `.bc-primary-light { border-color: ${this.hexToRgbA(colors.primary, '0.2')} !important; }`;
    css += `.bg-primary {
      background-color: ${colors.primary} !important;
      color: white !important;
    }`;
    css += `.bg-primary > * { color: white !important; }`;
    css += `.bg-primary-light { background-color: ${this.hexToRgbA(colors.primary, '0.6')} !important; }`;
    css += `.bg-primary-extra-light { background-color: ${this.hexToRgbA(colors.primary, '0.06')} !important; }`;
    css += `.c-primary{ color: ${colors.primary} !important; }`;
    css += `.hover-bg-primary-lite:hover { background-color: ${this.hexToRgbA(colors.primary, '0.2')} !important; }`;

    css += `.bc-secondary { border-color: ${colors.secondary} !important; }`;
    css += `.bg-secondary { background-color: ${colors.secondary} !important; }`;
    css += `.bg-secondary-light { background-color: ${colors.secondary} !important; }`;
    css += `.bg-secondary-extra-light { background-color: ${this.hexToRgbA(colors.secondary, '0.06')} !important; }`;
    css += `.c-secondary{ color: ${colors.secondary} !important; }`;
    css += `.hover-bg-secondary-lite:hover { background-color: ${this.hexToRgbA(colors.secondary, '0.2')} !important; }`;

    css += `.bc-accent { border-color: ${colors.accent} !important; }`;
    css += `.bg-accent { background-color: ${colors.accent} !important; }`;
    css += `.bg-accent-light { background-color: ${colors.accent} !important; }`;
    css += `.c-accent{ color: ${colors.accent} !important; }`;
    css += `.hover-bg-accent-lite:hover { background-color: ${this.hexToRgbA(colors.accent, '0.2')} !important; }`;

    const style = document.createElement('style');
    document.head.appendChild(style);
    style.appendChild(document.createTextNode(css));
  },

  hexToRgbA(hex, opacity) {
    let c;
    if (!hex.startsWith('#')) {
      hex = `#${hex}`;
    }
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
    }
    throw new Error('Bad Hex');
  },
};
