<template>
  <v-dialog
    v-model="visible"
    max-width="800"
    scrim="transparent"
  >
    <v-card
      border
      flat
      tile
    >
      <template v-if="claim">
        <v-card-title class="pe-1">
          <v-row>
            <v-col>
              <template v-if="isSpecialist">
                <v-tooltip location="bottom">
                  <template #activator="tooltipOptions">
                    <v-btn
                      @click="toggleLock()"
                      v-bind="tooltipOptions.props"
                      :disabled="!canEdit || claim.approved || claim.denied"
                      class="me-3"
                      color="primary"
                      data-cy="lock-button"
                      size="small"
                      variant="text"
                      icon
                    >
                      <v-icon>{{ claim.locked ? 'lock' : 'lock_open' }}</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      $t(
                        claim.locked
                          ? 'The claim is locked. The applicant cannot make changes.'
                          : 'The claim is unlocked. The applicant can make changes.',
                      )
                    }}
                  </span>
                </v-tooltip>
              </template>
              <span class="va-sub">{{ claim.id.slice(0, 6).toUpperCase() }}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-tooltip location="bottom">
                <template #activator="tooltipOptions">
                  <span
                    v-bind="tooltipOptions.props"
                    :class="getStatusClasses()"
                    class="rounded b-1 fs-16 px-4 py-1 me-2 my-2"
                  >
                    {{ $t(getClaimStatus()) }}
                  </span>
                </template>
                <span>
                  <LongDate
                    :date="claim.approved_at || claim.denied_at || claim.submitted_at"
                    short-month
                  />
                </span>
              </v-tooltip>
              <v-btn
                @click="close()"
                variant="text"
                icon
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />

        <template v-if="loaded">
          <v-row
            class="mt-1"
            dense
          >
            <v-col class="fw-600 fs-14 ta-center">
              {{ $t('Created at') }}
            </v-col>
            <template v-if="claim.approved">
              <v-col class="fw-600 fs-14 ta-center">
                {{ $t('Approved amount') }}
              </v-col>
              <v-col class="fw-600 fs-14 ta-center">
                {{ $t('Approved at') }}
              </v-col>
            </template>
            <v-col
              v-else
              class="fw-600 fs-14 ta-center"
            >
              {{ $t('Requested') }}
            </v-col>
            <v-col
              v-if="claim.denied"
              class="fw-600 fs-14 ta-center"
            >
              {{ $t('Denied') }}
            </v-col>
            <v-col class="fw-600 fs-14 ta-center">
              {{ $t('Last updated') }}
            </v-col>
          </v-row>

          <v-row
            class="c-black fs-14 fw-500 mb-1"
            dense
          >
            <v-col class="ta-center">
              <LongDate
                :date="claim.created_at"
                short-month
              />
            </v-col>
            <template v-if="claim.approved">
              <v-col class="ta-center">
                <div
                  @click="$refs.approveClaimDialog.open()"
                  class="bc-link-hover"
                >
                  <span>{{ currency(claim.approved_amount) }}</span>
                </div>
              </v-col>
              <v-col class="ta-center">
                <LongDate
                  :date="claim.approved_at"
                  short-month
                />
              </v-col>
            </template>
            <v-col
              v-else
              class="ta-center"
            >
              <span>{{ currency(claim.requested_amount) }}</span>
            </v-col>
            <v-col
              v-if="claim.denied"
              class="ta-center"
            >
              <LongDate
                :date="claim.denied_at"
                short-month
              />
            </v-col>
            <v-col class="ta-center">
              <LongDate
                :date="claim.updated_at"
                short-month
              />
            </v-col>
          </v-row>
          <v-divider />
        </template>
      </template>

      <v-card-text>
        <template v-if="loaded">
          <QuestionSet
            v-model="claim"
            @change="save(isSpecialist)"
            :attachment-group-id="familySubsidy?.group_id"
            :attachment-owner-id="claim.id"
            :attachment-owner-type="'Claim'"
            :attachment-readonly="!canEdit"
            :attachments="claimAttachments"
            :questions="validQuestions"
            :readonly="!canEdit"
            :schema="claimSchema.definition"
            key-name="question"
            condensed
            display-answer
            expanded
            hide-actions
            very-dense
          />
        </template>
        <template v-else>
          <v-progress-linear
            class="my-8"
            indeterminate
          />
        </template>
      </v-card-text>
      <v-divider />

      <v-card-actions class="mt-0 px-6 pb-4">
        <v-btn
          @click="close()"
          :loading="processing"
          class="focus-very-visible"
          color="primary"
          size="large"
          variant="outlined"
        >
          {{ $t('Close') }}
        </v-btn>

        <template v-if="claim">
          <template v-if="isSpecialist && canEdit">
            <v-spacer />
            <template v-if="!(claim.approved || claim.denied)">
              <v-btn
                @click="$refs.approveClaimDialog.open()"
                :loading="processing"
                class="focus-very-visible"
                color="primary"
                size="large"
              >
                {{ $t('Approve') }}
              </v-btn>

              <v-btn
                @click="reject()"
                :loading="processing"
                class="focus-very-visible"
                color="red"
                size="large"
                variant="outlined"
              >
                {{ $t('Deny') }}
              </v-btn>
            </template>

            <ResourceDialog
              @save="approveOrUpdateApprovedAmount"
              ref="approveClaimDialog"
              :fields="[
                {
                  text: 'Approved amount',
                  value: 'approved_amount',
                  type: 'currency',
                  required: true,
                },
              ]"
              :processing="processing"
            >
              <template #append>
                <v-alert
                  v-if="invalidApprovedAmount"
                  type="error"
                >
                  <div>
                    <div>
                      {{
                        $t(
                          'Approved amount must be greater than zero and no more than requested amount.',
                        )
                      }}
                    </div>
                  </div>
                </v-alert>
              </template>
            </ResourceDialog>
          </template>

          <template v-if="!isSpecialist && canEdit">
            <v-spacer />
            <v-btn
              @click="save(!isSpecialist)"
              :loading="processing"
              class="focus-very-visible"
              color="primary"
              size="large"
            >
              {{ $t('Save') }}
            </v-btn>
          </template>
        </template>
      </v-card-actions>
    </v-card>

    <ConfirmDialog ref="confirmDialog" />
  </v-dialog>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import { currency } from '@/plugins/filters';
import LongDate from '@/shared/components/LongDate.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import { useStore } from 'vuex';

const store = useStore();
const isManager = store.state.role === 'manager';
const isParent = store.state.role === 'parent';
const isSpecialist = store.state.role === 'specialist';

const props = defineProps({
  familySubsidy: {
    type: Object,
    default: null,
  },
});

defineExpose({
  open,
});

const emit = defineEmits(['change']);

const approveClaimDialog = ref(null);
const claim = ref(null);
const claimSchema = ref(null);
const claimAttachments = ref([]);
const confirmDialog = ref(null);
const invalidApprovedAmount = ref(false);
const ownerDataType = ref(null);
const ownerId = ref(null);
const processing = ref(false);
const visible = ref(false);

const { questionsLoaded, validQuestions } = useQuestionable({
  ownerDataType,
  ownerId,
  syncedObject: claim,
});

const loaded = computed(() => {
  return (
    claim.value &&
    (props.familySubsidy || claim.value.grant_id) &&
    claimSchema.value &&
    questionsLoaded.value
  );
});

const canEdit = computed(() => {
  if (isSpecialist) {
    if (claim.value.family_subsidy_id) {
      return (
        store.state.profile.org_family_subsidies_edit ||
        (store.state.profile.org_family_subsidies_admin && !claim.value?.locked)
      );
    } else if (claim.value.grant_id) {
      return (
        store.state.profile.org_grants_edit ||
        (store.state.profile.org_grants_admin && !claim.value?.locked)
      );
    } else {
      // If not associated to family subsidy or grant return false (no other associations configured)
      return false;
    }
  }
  if (isParent || isManager) return !claim.value?.locked;

  return false;
});

const claimRepository = computed(() => {
  if (isSpecialist) return api.organization.claim;
  if (isParent) return api.parent.claim;
  if (isManager) return api.manager.claim;
  return null;
});

const attachmentRepository = computed(() => {
  if (isSpecialist) return api.organization.attachment;
  if (isParent) return api.member.attachment;
  if (isManager) return api.manager.attachment;
  return null;
});

async function approveOrUpdateApprovedAmount(fields) {
  if (fields.approved_amount <= 0 || fields.approved_amount > claim.value.requested_amount) {
    invalidApprovedAmount.value = true;
    return;
  }

  invalidApprovedAmount.value = false;
  processing.value = true;
  const params = {
    approved_amount: fields.approved_amount,
    approved: true,
  };

  const resp = await claimRepository.value.update(claim.value.id, params);
  if (resp?.status !== 200) {
    processing.value = false;
    return;
  }

  approveClaimDialog.value.close();
  processing.value = false;
  claim.value = resp.data;
  emit('change');
  return;
}

function close() {
  visible.value = false;
}

function getClaimStatus() {
  if (claim.value.approved) return 'Approved';
  if (claim.value.denied) return 'Denied';
  return 'Submitted';
}

function getStatusClasses() {
  if (claim.value.approved) return 'bc-primary c-primary';
  if (claim.value.denied) return 'bc-red c-red';
  return isSpecialist ? 'bc-gray c-gray' : 'bc-black c-black';
}

async function loadAttachments() {
  processing.value = true;
  const resp = await attachmentRepository.value.index({
    owner_id: claim.value.id,
    owner_type: 'Claim',
  });
  if (resp?.status !== 200) {
    processing.value = false;
    return;
  }

  claimAttachments.value = resp.data;
  processing.value = false;
}

async function loadClaim(id) {
  const resp = await claimRepository.value.get(id);
  if (resp?.status !== 200) return;

  claim.value = resp.data;
  ownerDataType.value = 'Schema';
  ownerId.value = claim.value.schema_id;
}

async function loadSchema() {
  const resp = await api.public_api.organization.schema.get(claim.value.schema_id);
  if (resp?.status !== 200) return;

  claimSchema.value = resp.data;
}

async function open(claimId) {
  processing.value = true;
  visible.value = true;
  await loadClaim(claimId);
  await loadSchema();

  await loadAttachments();
  processing.value = false;
}

async function reject() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to deny this claim?',
  );
  if (!confirm) return;

  processing.value = true;
  const params = { denied: true };
  const resp = await claimRepository.value.update(claim.value.id, params);
  processing.value = false;
  if (resp?.status !== 200) return;
  claim.value = resp.data;
  emit('change');
}

// Note : permittedSave is used to allow admins to "live" edit/save while only permitting
//        parents to save once editing is complete and they've clicked "Save"
async function save(permittedSave) {
  if (!permittedSave || !canEdit.value) return;

  await nextTick(); // Required for claim to be correctly updated change/save
  const resp = await claimRepository.value.update(claim.value.id, claim.value);
  if (resp?.status !== 200) return;

  claim.value = resp.data;
  emit('change');
  if (!isSpecialist) close();
}

// Specialist only
async function toggleLock() {
  const resp = await claimRepository.value.update(claim.value.id, { locked: !claim.value.locked });
  if (resp?.status !== 200) return;

  claim.value = resp.data;
  emit('change');
}
</script>
