import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/organization/funding_sources/${id}`;
    return `${window.api_url}/organization/funding_sources`;
  },

  create(resource) {
    return axios.post(this.baseUrl(), { funding_source: resource }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index(params) {
    return axios.get(this.baseUrl(), { params }).catch(handleError);
  },

  reorder(fundingSourceId, params) {
    const reorderUrl = `${this.baseUrl(fundingSourceId)}/reorder`;
    return axios.post(reorderUrl, params).catch(handleError);
  },

  update(id, resource) {
    return axios.patch(this.baseUrl(id), { funding_source: resource }).catch(handleError);
  },
};
