<template>
  <div>
    <v-row
      class="mb-4"
      dense
    >
      <v-col>
        <div class="bc-h6">
          {{ $t('Licensing & inspection history') }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <div class="c-black fw-600 bc-body-sm">
          {{ $t('Certificates of compliance') }}
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mb-3"
      dense
    >
      <v-col
        v-for="(cert, index) in license.certifications"
        :key="index"
        cols="12"
        lg="4"
        md="4"
      >
        <div class="b-1 b-radius-4 bc-light-gray bg-extra-light-gray c-black bc-caption px-3 py-2">
          <div
            v-text="cert.type"
            class="fw-600 mb-1"
          />
          <div
            v-text="cert.number"
            class="mb-1"
          />
          <div
            v-text="cert.issue_date + ' - ' + cert.expiration_date"
            class="mb-1"
          />
        </div>
      </v-col>

      <v-col
        v-if="license.certifications.length == 0"
        cols="12"
      >
        <div class="c-light-black bc-body-sm">
          {{ $t('No certificates of compliance.') }}
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mb-1"
      dense
    >
      <v-col>
        <div class="c-black fw-600 bc-body-sm">
          {{ $t('Inspections') }}
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mb-3"
      dense
    >
      <v-col
        v-for="(inspection, index) in license.inspections"
        :key="index"
        cols="12"
        lg="4"
        md="4"
      >
        <div class="b-1 b-radius-4 bc-light-gray bg-extra-light-gray c-black bc-caption px-3 py-2">
          <div class="fw-600 mb-1">
            {{ $t('Inspection') }}
          </div>
          <div
            v-text="inspection.date"
            class="mb-1"
          />
          <div
            v-text="inspection.number"
            class="mb-1"
          />
          <div
            v-text="`${inspection.findings.length} ${$t('corrections')}`"
            class="mb-1"
          />
          <div
            @click="showInspection(inspection)"
            class="mb-1 c-secondary pointer"
          >
            <span class="bc-link-hover">{{ $t('View inspection details') }}</span>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="license.inspections.length == 0"
        cols="12"
      >
        <div class="c-light-black bc-body-sm">
          {{ $t('No inspections.') }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <div class="c-black fw-600 bc-body-sm">
          {{ $t('Waivers of regulation') }}
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mb-3"
      dense
    >
      <v-col
        v-for="(waiver, index) in license.waivers"
        :key="index"
        cols="12"
        lg="4"
        md="4"
      >
        <div class="b-1 b-radius-4 bc-light-gray bg-extra-light-gray c-black bc-caption px-3 py-2">
          <div
            v-text="`${'Regulation'} # ${waiver.regulation_number}`"
            class="fw-600 mb-1"
          />
          <div
            v-text="waiver.regulation_description"
            class="mb-1"
          />
          <div
            v-text="waiver.date"
            class="mb-1"
          />
          <div
            v-text="waiver.status"
            class="mb-1"
          />
        </div>
      </v-col>
      <v-col
        v-if="license.waivers.length == 0"
        cols="12"
      >
        <div class="c-light-black bc-body-sm">
          {{ $t('No waivers requested.') }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <div class="c-black fw-600 bc-body-sm">
          {{ $t('Negative Sanctions') }}
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mb-3"
      dense
    >
      <v-col
        v-for="(sanction, index) in license.sanctions"
        :key="index"
        cols="12"
        lg="4"
        md="4"
      >
        <div class="b-1 b-radius-4 bc-light-gray bg-extra-light-gray c-black bc-caption px-3 py-2">
          <div
            v-text="sanction.type"
            class="fw-600 mb-1"
          />
          <div
            v-text="sanction.number"
            class="mb-1"
          />
          <div
            v-text="sanction.date"
            class="mb-1"
          />
          <div
            v-text="sanction.status"
            class="mb-1"
          />
        </div>
      </v-col>
      <v-col
        v-if="license.sanctions.length == 0"
        cols="12"
      >
        <div class="c-light-black bc-body-sm">
          {{ $t('No negative sanctions.') }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="ta-right">
        <a
          class="bc-caption bc-link-hover"
          href="https://www.compass.state.pa.us/compass.web/ProviderSearch/Home#/BasicSearch"
          target="_blank"
        >
          {{ $t('Licensing data provided by Compass') }}
        </a>
      </v-col>
    </v-row>

    <v-dialog
      v-model="inspectionDialogIsVisible"
      max-width="770"
    >
      <v-card v-if="inspection">
        <v-card-text>
          <v-row>
            <v-col cols="11">
              <div class="c-primary bc-h6">
                {{ $t('Inspection details') }}
              </div>
            </v-col>
            <v-col
              class="ta-right"
              cols="1"
            >
              <v-btn
                @click="inspectionDialogIsVisible = false"
                variant="text"
                icon
              >
                <v-icon icon="close" />
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <div class="fs-10 c-light-black">
                {{ $t('INSPECTION ID') }}
              </div>
              <div
                v-text="inspection.number"
                class="c-black bc-caption"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <div class="fs-10 c-light-black">
                {{ $t('INSPECTION DATE') }}
              </div>
              <div
                v-text="inspection.date"
                class="c-black bc-caption"
              />
            </v-col>
          </v-row>
          <Finding
            v-for="(finding, index) in inspection.findings"
            :key="index"
            :finding="finding"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import Finding from '@/shared/components/search/finding.vue';

defineProps({
  license: {
    type: Object,
    default: null,
  },
});

const inspection = ref(null);
const inspectionDialogIsVisible = ref(false);

function showInspection(inspectionValue) {
  inspectionDialogIsVisible.value = true;
  inspection.value = inspectionValue;
}
</script>
