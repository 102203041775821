import axios from 'axios';
import qs from 'qs';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/manager/enrollments`;
    const amendedUrl = id ? (url += `/${id}`) : url;
    return amendedUrl;
  },

  create(enrollment, success, failure) {
    if (!success) return axios.post(this.baseUrl(), { enrollment }).catch(handleError);
    return axios.post(this.baseUrl(), { enrollment }).then(success).catch(failure);
  },

  downloadUrl(filters, filename, format) {
    const combinedParams = filters;
    combinedParams.filename = `${filename}.${format}`;
    return `${this.baseUrl()}.${format}?${qs.stringify(combinedParams, { arrayFormat: 'brackets' })}`;
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id)).catch(handleError);
    return axios.get(this.baseUrl(id)).then(success);
  },

  index(params, success, failure) {
    if (!success) return axios.get(this.baseUrl(), { params }).catch(handleError);
    return axios.get(this.baseUrl(), { params }).then(success).catch(failure);
  },

  update(id, enrollment, success, failure) {
    if (!success) return axios.patch(this.baseUrl(id), { enrollment }).catch(handleError);
    return axios.patch(this.baseUrl(id), { enrollment }).then(success).catch(failure);
  },
};
