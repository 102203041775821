import axios from 'axios';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/public/organizations/${window.organization_id}/subsidy_programs`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id));
    axios.get(this.baseUrl(id)).then(success);
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters });

    axios.get(this.baseUrl(), { params: filters }).then(success);
  },
};
