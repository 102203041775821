import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(subsidyProgramId) {
    return `${window.api_url}/organization/subsidy_programs/${subsidyProgramId}/seat_types`;
  },

  create(seatType) {
    return axios
      .post(this.baseUrl(seatType.subsidy_program_id), { seat_type: seatType })
      .catch(handleError);
  },

  destroy(seatType) {
    return axios
      .delete(`${this.baseUrl(seatType.subsidy_program_id)}/${seatType.id}`)
      .catch(handleError);
  },

  index(subsidyProgramId) {
    return axios.get(this.baseUrl(subsidyProgramId)).catch(handleError);
  },

  update(seatType) {
    return axios
      .patch(`${this.baseUrl(seatType.subsidy_program_id)}/${seatType.id}`, { seat_type: seatType })
      .catch(handleError);
  },
};
