import axios from 'axios';

export default {
  baseUrl(childId) {
    if (childId) {
      return `${window.api_url}/children/${childId}`;
    }
    return `${window.api_url}/children`;
  },

  create(child, success, failure) {
    if (!success) return axios.post(this.baseUrl(), { child });

    axios.post(this.baseUrl(), { child }).then(success).catch(failure);
  },

  destroy(childId, success) {
    axios.delete(this.baseUrl(childId)).then(success);
  },

  get(childId, success) {
    if (success) {
      axios.get(this.baseUrl(childId)).then(success);
    } else {
      return axios.get(this.baseUrl(childId));
    }
  },

  index(success) {
    if (!success) return axios.get(this.baseUrl());

    axios.get(this.baseUrl()).then(success);
  },

  update(childId, child, success, failure) {
    if (!success) return axios.patch(this.baseUrl(childId), { child });

    axios.patch(this.baseUrl(childId), { child }).then(success).catch(failure);
  },
};
