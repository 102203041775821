import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/member/attachments`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  },

  create(attachment) {
    return axios.post(this.baseUrl(), { attachment }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index(params) {
    return axios.get(this.baseUrl(), { params }).catch(handleError);
  },

  update(id, attachment) {
    return axios.patch(this.baseUrl(id), { attachment }).catch(handleError);
  },
};
