import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/manager/claims`;
    if (id) url += `/${id}`;
    return url;
  },

  index(params) {
    return axios.get(this.baseUrl(), { params }).catch(handleError);
  },

  create(claim) {
    return axios.post(this.baseUrl(), { claim }).catch(handleError);
  },

  update(id, claim) {
    return axios.patch(this.baseUrl(id), { claim }).catch(handleError);
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  submit(id) {
    return axios.patch(`${this.baseUrl(id)}/submit`).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },
};
