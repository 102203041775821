/**
 * @deprecated This mixin is replaced by useAgeCalculations.js
 */
export default {
  data() {
    return {
      siteAgeGroups: this.$store.state.ageGroups,
    };
  },

  methods: {
    ageGroupFromDate(date, from) {
      return this.ageGroupFromMonths(this.dateToMonthsOld(date, from));
    },

    ageGroupFromMonths(months) {
      if (months < 0) {
        months = 0;
      }
      return this.siteAgeGroups.find((group) => months >= group.min && months <= group.max);
    },

    dateToMonthsOld(date, from) {
      const today = from || new Date();
      const msOld = today - date;
      return parseInt(msOld / (1000 * 60 * 60 * 24 * 30.4));
    },

    monthsToAgeString(val) {
      if (val <= 0) {
        return this.$t('Newborn');
      }
      if (val < 24) {
        return [val, this.$t('months')].join(' ');
      }
      const years = parseInt(val / 12);
      const months = val % 12;

      if (months == 0) {
        return [years, this.$t('years')].join(' ');
      }
      return [years, this.$t('years'), months, this.$t('months')].join(' ');
    },
  },
};
