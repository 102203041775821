<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <template v-if="label">
        <v-col
          class="labeled-input"
          cols="12"
        >
          <span>{{ $t(label) }}</span>
          <RequiredIndicator v-if="mandatory" />
        </v-col>
      </template>
      <template v-if="description">
        <v-col
          class="fs-16 c-light-black my-1"
          cols="12"
        >
          <span>{{ $t(description) }}</span>
        </v-col>
      </template>
      <v-col cols="12">
        <VCurrencyField
          @blur="handleBlur"
          @update:model-value="handleUpdateModelValue"
          ref="text_field"
          :append-inner-icon="locked ? 'lock' : ''"
          :aria-label="$t(label)"
          :density="dense ? 'compact' : undefined"
          :disabled="locked"
          :distraction-free="false"
          :model-value="localValue"
          prepend-inner-icon="attach_money"
          variant="filled"
          hide-details
          tile
          v-bind="attrsOmitDataPrefix"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import FilteredAttrs from '@/shared/mixins/FilteredAttrs';
import Labeled from '@/shared/mixins/labeled';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import Schematized from '@/shared/mixins/schematized';
import VCurrencyField from '@/shared/components/form/VCurrencyField.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    RequiredIndicator,
    VCurrencyField,
  },

  mixins: [Labeled, Schematized, FilteredAttrs],
};
</script>
