import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/organization/providers`;
    if (id) url += `/${id}`;
    return url;
  },

  create(params, success, failure) {
    axios.post(this.baseUrl(), { provider: params }).then(success).catch(failure);
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id)).catch(handleError);
    return axios.get(this.baseUrl(id)).then(success);
  },

  index(params, success) {
    if (!success) return axios.get(this.baseUrl(), { params }).catch(handleError);
    return axios.get(this.baseUrl(), { params }).then(success);
  },

  update(id, provider, success, failure) {
    if (!success) return axios.patch(this.baseUrl(id), { provider }).catch(handleError);
    return axios.patch(this.baseUrl(id), { provider }).then(success).catch(failure);
  },

  dashboard: {
    baseUrl(providerId) {
      return `${window.api_url}/organization/providers/${providerId}/dashboard`;
    },

    get(providerId, filters) {
      return axios.get(this.baseUrl(providerId), { params: filters }).catch(handleError);
    },
  },

  staff_member: {
    base_url(providerId) {
      return `${window.api_url}/organization/providers/${providerId}/staff_members`;
    },

    create(providerId, staffMember, success, failure) {
      axios
        .post(this.base_url(providerId), { staff_member: staffMember })
        .then(success)
        .catch(failure);
    },

    destroy(providerId, staffMemberId, success) {
      axios.delete(`${this.base_url(providerId)}/${staffMemberId}`).then(success);
    },

    index(providerId, success) {
      axios.get(this.base_url(providerId)).then(success);
    },

    update(providerId, staffMemberId, staffMember, success, failure) {
      axios
        .patch(`${this.base_url(providerId)}/${staffMemberId}`, { staff_member: staffMember })
        .then(success)
        .catch(failure);
    },
  },
};
