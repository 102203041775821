import mitt from 'mitt';

const emitter = mitt();

export const EventBus = {
  $on: emitter.on,
  $once: emitter.once,
  $off: emitter.off,
  $emit: emitter.emit,
};
