import axios from 'axios';

export default {
  baseUrl(lessonId, id) {
    let url = `${window.api_url}/organization/lessons/${lessonId}/assignments`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  },

  create(lessonId, assignment, success, failure) {
    axios.post(this.baseUrl(lessonId), { assignment }).then(success).catch(failure);
  },

  destroy(lessonId, id, success) {
    axios.delete(this.baseUrl(lessonId, id)).then(success);
  },

  get(lessonId, id, success) {
    axios.get(this.baseUrl(lessonId, id)).then(success);
  },

  index(lessonId, success) {
    axios.get(this.baseUrl(lessonId)).then(success);
  },

  update(lessonId, id, assignment, success, failure) {
    axios.patch(this.baseUrl(lessonId, id), { assignment }).then(success).catch(failure);
  },
};
