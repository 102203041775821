<script setup>
import { useCurrencyInput } from 'vue-currency-input';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: 0,
  },
});

const { inputRef, formattedValue, setValue } = useCurrencyInput({
  currency: 'USD',
  locale: 'en-US',
  precision: 2,
  hideGroupingSeparatorOnFocus: false,
});

watch(
  () => props.modelValue,
  (value) => {
    setValue(value);
  },
);
</script>

<template>
  <VTextField
    ref="inputRef"
    :modelValue="formattedValue"
  />
</template>
