import { useI18n } from 'vue-i18n';

/**
 * Access utility functions to make working with Uppy easier.
 *
 * @returns {{
 *  getUploadAPIEndpoint: () => string
 *  getUppyLocale: () => object
 * }}
 */
export default function useUppyUtils() {
  const { t } = useI18n();

  function getUppyLocale() {
    return {
      strings: {
        addingMoreFiles: t('Adding more files'),
        addMore: t('Add more'),
        addMoreFiles: t('Add more files'),
        back: t('Back'),
        browseFiles: t('browse files'),
        browseFolders: t('browse folders'),
        cancel: t('Cancel'),
        cancelUpload: t('Cancel upload'),
        complete: t('Complete'),
        done: t('Done'),
        dropHint: t('Drop your files here'),
        dropPasteBoth: t('Drop files here or %{browseFiles}', {
          browseFiles: '%{browseFiles}',
        }),
        dropPasteFiles: t('Drop files here or %{browseFiles}', {
          browseFiles: '%{browseFiles}',
        }),
        dropPasteFolders: t('Drop files here or %{browseFiles}', {
          browseFiles: '%{browseFiles}',
        }),
        removeFile: t('Remove file'),
        save: t('Save'),
        uploadComplete: t('Upload complete'),
        uploadXFiles: {
          0: t('Upload %{smart_count} file'),
          1: t('Upload %{smart_count} files'),
        },
        uploadingXFiles: {
          0: t('Uploading %{smart_count} file'),
          1: t('Uploading %{smart_count} files'),
        },
        xFilesSelected: {
          0: t('%{smart_count} file selected'),
          1: t('%{smart_count} files selected'),
        },
      },
    };
  }

  return {
    getUploadAPIEndpoint,
    getUppyLocale,
  };
}

function getUploadAPIEndpoint() {
  const path = 'attachments/upload';

  // Path namespaced API, e.g. https://childcare.twc.texas.gov/api
  if (import.meta.env.VUE_APP_SITE_ID) return [window.api_url, path].join('/');

  // Domain namespaced API, e.g. https://api.getbridgecare.com/api
  const slicedUrl = window.api_url.slice(0, -3);
  return [slicedUrl, path].join('');
}
