<template>
  <v-container class="pt-16">
    <v-card
      class="pa-4 mx-auto"
      max-width="600"
      border
      flat
      tile
    >
      <h1
        class="d-flex justify-center fs-28 pa-4 code-sent-header"
        tabindex="0"
      >
        {{ $t(translateHeader()) }}
      </h1>
      <v-card-text>
        <v-row>
          <v-col>
            <p
              class="mb-0 fs-16"
              tabindex="0"
            >
              {{ $t(translateLoginMessage()) }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center justify-center">
            <v-otp-input
              v-model="code"
              @finish="check"
              ref="otp"
              :disabled="processing || confirmed"
              class="mxw-600"
              data-cy="otp-input"
              length="6"
              autofocus
            />
            <template v-if="confirmed">
              <v-icon
                color="green"
                size="36"
                end
              >
                check_circle
              </v-icon>
            </template>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            v-if="isEmail()"
            @click="openEmailConfirmation"
            variant="plain"
          >
            {{ $t('Resend login code') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <DecisionDialog
      @cancel="routeToReenterEmail()"
      @confirm="createLoginCode()"
      ref="confirmEmailDialog"
      :cancel-button-text="$t('Try another email')"
      :confirm-button-text="$t('Confirm and send')"
      :content="routeQuery.email_sent_to"
      :processing="processing"
      :title="$t('Resend login code to') + ':'"
    />

    <AddPhoneDialog
      @cancel="routeToDestination()"
      @change="routeToDestination()"
      ref="phoneDialog"
      cancel-button-text="Skip for now"
      save-button-text="Send confirmation code"
      title="Add a cell phone number"
    />
  </v-container>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import AddPhoneDialog from '@/shared/components/AddPhoneDialog.vue';
import DecisionDialog from '@/shared/components/DecisionDialog.vue';
import RouteProps from '@/shared/mixins/route-props';
import _ from 'lodash';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AddPhoneDialog,
    DecisionDialog,
  },

  mixins: [RouteProps],

  emits: ['replace'],

  data() {
    return {
      code: null,
      confirmed: false,
      processing: false,
    };
  },

  methods: {
    check() {
      this.processing = true;
      const vm = this;

      Api.member.session.create(
        { code: this.code, code_id: this.routeQuery.token },
        (response) => {
          if (response.data?.status === 'require_sso') {
            this.$eventBus.$emit('chime', 'Your account is required to use Single Sign-On.');
            return this.reroute({ path: '/' });
          }

          vm.processing = false;
          vm.$store.dispatch('identify', {
            success() {
              vm.confirmed = true;

              vm.$eventBus.$emit('chime', 'Login successful');
              if (vm.$store.state.config.enable_optional_phone && response.data.prompt_for_phone) {
                vm.$refs.phoneDialog.open();
              } else {
                vm.routeToDestination();
              }
            },
            failure() {
              vm.$eventBus.$emit(
                'chime',
                'An unknown problem has occurred. Please contact support.',
              );
            },
          });

          return true;
        },
        () => {
          vm.processing = false;
          const message =
            'Incorrect login code. If you need assistance logging in, please contact support.';
          vm.$eventBus.$emit('chime', message);
          vm.code = null;
        },
      );
    },

    createLoginCode() {
      const email = this.routeQuery.email_sent_to;
      this.processing = true;
      Api.member.login_code.create(
        {
          email,
          organization_id: window.organization_id,
          site_id: window.site_id,
          is_provider: this.routeQuery.is_provider,
          is_specialist: this.routeQuery.is_specialist,
        },
        (resp) => {
          this.$refs.confirmEmailDialog?.close();
          this.processing = false;
          this.reroute({
            name: 'CodeSent',
            query: {
              ...this.routeQuery,
              mode: 'login',
              token: resp.data.code_id,
              email_sent_to: email,
            },
          });
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    isEmail() {
      return !_.isNil(this.routeQuery.email_sent_to);
    },

    openEmailConfirmation() {
      return this.$refs.confirmEmailDialog?.open();
    },

    reroute(route) {
      this.$emit('replace', route);
    },

    routeToReenterEmail() {
      if (this.routeQuery.mode === 'register') {
        this.reroute({ name: 'Signup', query: { mode: 'signup' } });
      } else if (this.routeQuery.mode === 'login') {
        this.reroute({ name: 'Signup', query: { mode: 'login' } });
      } else {
        this.reroute({ name: 'Welcome' });
      }
    },

    routeToDestination() {
      if (this.routeQuery.redirect_url) {
        this.reroute(this.routeQuery.redirect_url);
        return;
      }
      if (this.routeQuery.action === 'apply') {
        this.reroute({
          name: 'ChildSubsidyNew',
          params: { subsidyProgramId: this.routeQuery.subsidyProgramId },
        });
      } else if (this.routeQuery.action === 'childNew') {
        this.reroute({ name: 'ChildNew' });
      } else if (this.routeQuery.action === 'favorite') {
        const page = this.routeQuery.page || 1;
        const providerId = this.routeQuery.providerId;
        const searchId = this.routeQuery.searchId;
        this.reroute({ name: 'SearchShow', params: { searchId }, query: { providerId, page } });
      } else if (this.routeQuery.action === 'providerReferral') {
        this.reroute({
          name: 'ChildSubsidyNew',
          params: { subsidyProgramId: this.routeQuery.subsidyProgramId },
          query: { programId: this.routeQuery.programId },
        });
      } else if (this.routeQuery.destination === 'messages') {
        this.reroute({ name: 'MessageIndex' });
      } else if (this.routeQuery.destination === 'tour') {
        this.reroute({
          name: 'TourShow',
          params: { tourId: this.routeQuery.tour_id },
          query: { action: 'confirm' },
        });
      } else if (this.routeQuery.destination === 'provider') {
        this.reroute({
          name: 'PublicProviderShow',
          params: { providerId: this.routeQuery.provider_id },
        });
      } else {
        this.reroute({ name: 'Dashboard' });
      }
    },

    translateHeader() {
      const email = this.routeQuery.email_sent_to;
      const { phone } = this.routeQuery;
      return `${this.$t('A login code was sent to')} ${email || phone}`;
    },

    translateLoginMessage() {
      const email = this.routeQuery.email_sent_to;
      if (email) {
        return this.$t(
          'Enter the login code below or follow the link in the email to login automatically.',
        );
      }
      return this.$t('Enter the login code sent via text.');
    },
  },
};
</script>

<style>
.v-otp-input input {
  text-transform: capitalize;
}

.code-sent-header {
  font-size: 1.75rem;
  padding: 1rem;
  word-break: break-word;
}

@media (max-width: 37.5rem) {
  .code-sent-header {
    font-size: 1.375rem;
    padding: 0.5rem;
  }
}
</style>
