<template>
  <v-dialog
    v-model="userSessionTimeoutIsVisible"
    max-width="600px"
  >
    <v-card
      border
      flat
      tile
    >
      <v-card-title class="pb-4">
        {{ $t('Are you still there?') }}
      </v-card-title>

      <v-card-text class="pb-0">
        <p>{{ $t('If you do not respond, your session will be logged out soon.') }}</p>
      </v-card-text>

      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          @click="checkIdentity"
          color="primary"
          size="x-large"
        >
          Stay logged in
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const MILLISECONDS_IN_MINUTE = 60000;

export default {
  compatConfig: { MODE: 2 },

  props: {
    millisecondsToSignout: {
      type: Number,
      default: MILLISECONDS_IN_MINUTE,
    },
  },

  data() {
    return {
      displayTimerValue: null,
      displayTimerInterval: null,
      userSessionTimer: null,
      userSessionTimeoutIsVisible: false,
    };
  },

  computed: {
    timerLength() {
      return this.$store.state.userSessionTimeout * MILLISECONDS_IN_MINUTE - MILLISECONDS_IN_MINUTE;
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    '$store.state.userSessionActionTimestamp': function () {
      this.clearTimers();
      this.userSessionTimer = setTimeout(this.displaySessionTimeoutDialog, this.timerLength);
    },
  },

  methods: {
    async checkIdentity() {
      this.clearTimers();

      this.$store.dispatch('identify', {
        success() {},
        failure() {
          window.location.reload();
        },
      });
    },

    displaySessionTimeoutDialog() {
      // Before the session timeout dialog appears, check for an identity.
      //
      // If there is no user identity, the user is already logged out and the timeout
      // dialog need not appear. To omit the timeout dialog, the failure() callback will
      // reload the current page, which should be the login page, and omits the timer.
      //
      // If there is a user identity, operation continues as normal. The
      // success() callback is a no-op and the subsequent code starts the
      // interval for the timeout dialog to appear.
      this.checkIdentity();

      this.displayTimerValue = this.millisecondsToSignout / 1000;

      this.displayTimerInterval = setInterval(() => {
        this.displayTimerValue -= 1;
      }, 1000);

      this.userSessionTimeoutIsVisible = true;
      this.userSessionTimer = setTimeout(this.checkIdentity, this.millisecondsToSignout + 3000);
    },

    clearTimers() {
      clearInterval(this.displayTimerInterval);
      clearTimeout(this.userSessionTimer);
      this.userSessionTimeoutIsVisible = false;
    },
  },
};
</script>
