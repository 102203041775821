<template>
  <v-container
    :class="containerClass"
    class="my-8"
  >
    <grid-layout
      v-if="$i18n.locale && webpage"
      v-model:layout="webpage.layout[size]"
      :auto-size="true"
      :col-num="36"
      :is-draggable="false"
      :is-resizable="false"
      :row-height="8"
    >
      <grid-item
        v-for="item in webpage.layout[size]"
        :key="item.i"
        :h="item.h"
        :i="item.i"
        :w="item.w"
        :x="item.x"
        :y="item.y"
      >
        <PageElement
          v-model="elements[item.i]"
          :item="elements[item.i]"
          :lang="$i18n.locale"
          mode="view"
        />
      </grid-item>
    </grid-layout>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
// eslint-disable-next-line no-unused-vars
import { GridLayout, GridItem } from 'grid-layout-plus';
import PageElement from '@/shared/components/PageElement.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useDisplay } from 'vuetify';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const display = useDisplay();
const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const elements = ref({});
const webpage = ref(null);

const containerClass = computed(() => {
  if (size.value === 'lg') return 'mxw-1200';
  if (size.value === 'md') return 'mxw-800';

  return 'mxw-440';
});

const size = computed(() => {
  if (display.lgAndUp.value) return 'lg';
  if (display.mdAndUp.value) return 'md';

  return 'sm';
});

function load() {
  if (route?.query?.chime) eventBus.chime(route.query.chime);
  if (!store.state.siteWebpageId) return router?.replace({ name: 'Welcome' });

  Api.public_api.organization.webpage.get(store.state.siteWebpageId, (resp) => {
    webpage.value = resp.data;
    if (webpage.value.redirect_url) {
      window.location.href = webpage.value.redirect_url;
    }

    webpage.value.elements.forEach((element) => {
      elements.value[element.i] = element;
    });
  });
}

onMounted(load);
</script>

<style>
.v-btn__content {
  white-space: pre-wrap;
}
</style>
