<template>
  <FormQuestion
    @back="$emit('back')"
    @click:notes="$emit('click:notes')"
    @next="$emit('next')"
    :bold="question.is_bold"
    :class="landscape ? 'my-0' : 'mb-6'"
    :color="color"
    :dense="dense"
    :display-notes="displayNotes"
    :elevation="elevation"
    :header="header"
    :hide-actions="hideActions"
    :landscape="landscape"
    :mandatory="question.mandatory"
    :model-value="modelValue"
    :next-disabled="nextDisabled"
    :notes-count="notesCount"
    :outlined="outlined"
    :paddingless="paddingless"
    :processing="processing"
    :published="question.published"
    :reversible="reversible"
    :show-indicators="showIndicators"
    :subtitle="question.subtitle"
    :tile="tile"
    :title="question.title"
    :very-dense="veryDense"
  >
    <div
      v-show="!attachmentReadonly"
      class="px-0 py-6"
    >
      <AttachmentUploader
        @uploaded="$emit('change:attachments')"
        :ref="['uploader', question.id].join('')"
        :owner="owner"
        data-testid="attachment-uploader"
      />
    </div>

    <div
      v-show="localAttachments.length > 0"
      class="mt-4"
    >
      <AttachmentList
        @change="$emit('change:attachments')"
        @delete="$emit('change:attachments')"
        :attachments="localAttachments"
        :hide-remove="attachmentReadonly"
        :processing="processing"
      />
    </div>
  </FormQuestion>
</template>

<script>
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import _ from 'lodash';

export default {
  compatConfig: { MODE: 3 },

  components: {
    AttachmentList,
    AttachmentUploader,
    FormQuestion,
  },

  props: {
    attachmentGroupId: {
      type: String,
      default: null,
    },
    attachmentOwnerId: {
      type: String,
      default: null,
    },
    attachmentOwnerType: {
      type: String,
      default: null,
    },
    attachmentTagsSupplements: {
      type: Array,
      default: () => [],
    },
    attachmentReadonly: {
      type: Boolean,
      default: false,
    },
    attachments: {
      type: Array,
      default: null,
    },
    attachmentStatus: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    bold: {
      default: false,
      type: Boolean,
    },
    color: {
      default: 'transparent',
      type: String,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    displayNotes: {
      type: Boolean,
      default: false,
    },
    divided: {
      type: Boolean,
      default: false,
    },
    veryDense: {
      type: Boolean,
      default: false,
    },
    elevation: {
      default: 0,
      type: Number,
    },
    expanded: {
      default: false,
      type: Boolean,
    },
    header: {
      type: String,
      default: null,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    showIndicators: {
      type: Boolean,
      default: false,
    },
    landscape: {
      type: Boolean,
      default: false,
    },
    notesCount: {
      type: Number,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    processing: {
      default: false,
      type: Boolean,
    },
    question: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    reversible: {
      type: Boolean,
      default: true,
    },
    schema: {
      type: Object,
      default: null,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    modelValue: Object,
  },

  emits: ['back', 'change:attachments', 'click:notes', 'next'],

  data() {
    return {
      localAttachments: [],
    };
  },

  computed: {
    nextDisabled() {
      if (this.question.mandatory && this.localAttachments.length === 0) {
        return true;
      }

      return false;
    },

    owner() {
      return {
        group_id: this.attachmentGroupId,
        type: this.attachmentOwnerType,
        id: this.attachmentOwnerId,
        tags: [this.question.id, this.attachmentOwnerId].concat(this.attachmentTagsSupplements),
        description: this.question.attachment_description || this.question.title,
      };
    },
  },

  watch: {
    attachments: {
      immediate: true,
      handler() {
        if (this.attachments) {
          if (this.attachmentTagsSupplements.length > 0) {
            const ownerTags = _.sortBy(this.owner.tags);
            this.localAttachments = this.attachments.filter((attachment) => {
              const attachmentTags = _.sortBy(attachment.tags);
              return _.isEqual(attachmentTags, ownerTags);
            });
          } else {
            this.localAttachments = this.attachments.filter((attachment) =>
              attachment.tags.includes(this.question.id),
            );
          }
        } else {
          this.localAttachments = [];
        }
      },
    },
  },

  created() {
    this.documentUploadVisible = true;
  },
};
</script>
