<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col class="labeled-input">
        {{ $t(message) }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          v-model.number="months"
          @update:model-value="combine"
          :append-inner-icon="locked ? 'lock' : ''"
          :aria-label="$t('age')"
          :density="dense ? 'compact' : undefined"
          :disabled="locked"
          maxlength="2"
          min="0"
          type="number"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>
      <v-col cols="8">
        <v-select
          v-model="period"
          @update:model-value="combine"
          :append-inner-icon="locked ? 'lock' : ''"
          :aria-label="$t('months or years')"
          :density="dense ? 'compact' : undefined"
          :disabled="locked"
          :item-title="(item) => $t(item.text)"
          :items="[
            { text: 'months', value: 'months' },
            { text: 'years', value: 'years' },
          ]"
          variant="filled"
          hide-details
          tile
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized],

  data() {
    return {
      months: this.splitMonths(this.modelValue),
      period: this.splitYears(this.modelValue),
    };
  },

  watch: {
    modelValue(newVal) {
      if (newVal) {
        if (this.period === 'years' && this.months * 12 !== newVal) {
          this.period = 'months';
          this.months = newVal;
        } else if (this.period === 'months' && this.months !== newVal) {
          this.period = 'months';
          this.months = newVal;
        }
      } else {
        this.period = 'months';
        this.months = newVal;
      }
    },
  },

  methods: {
    combine() {
      let newVal;
      if (this.period === 'years') {
        newVal = this.months * 12;
      } else {
        newVal = this.months;
      }
      this.handleUpdateModelValue(newVal);
      this.handleBlur(newVal);
    },

    splitMonths(val) {
      return val % 12 === 0 && val > 24 ? val / 12 : val;
    },

    splitYears(val) {
      return val % 12 === 0 && val > 24 ? 'years' : 'months';
    },
  },
};
</script>
