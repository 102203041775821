import axios from 'axios';

export default {
  baseUrl(courseId, id) {
    let url = `${window.api_url}/organization/courses/${courseId}/lessons`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  },

  create(courseId, lesson, success, failure) {
    axios.post(this.baseUrl(courseId), { lesson }).then(success).catch(failure);
  },

  destroy(courseId, id, success) {
    axios.delete(this.baseUrl(courseId, id)).then(success);
  },

  get(courseId, id, success) {
    if (!success) return axios.get(this.baseUrl(courseId, id));
    return axios.get(this.baseUrl(courseId, id)).then(success);
  },

  index(courseId, success) {
    if (!success) return axios.get(this.baseUrl(courseId));
    return axios.get(this.baseUrl(courseId)).then(success);
  },

  update(courseId, id, lesson, success, failure) {
    axios.patch(this.baseUrl(courseId, id), { lesson }).then(success).catch(failure);
  },
};
