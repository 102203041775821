import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  base_url() {
    return `${window.api_url}/manager/profile`;
  },

  get(success) {
    if (!success) return axios.get(this.base_url()).catch(handleError);
    return axios.get(this.base_url()).then(success);
  },

  update(profile, success, failure) {
    axios.patch(this.base_url(), { profile }).then(success).catch(failure);
  },
};
