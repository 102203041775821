<template>
  <v-row dense>
    <v-col
      class="ta-center"
      cols="2"
      md="1"
    >
      <v-icon
        :color="color"
        class="fs-24 mb-1"
      >
        {{ icon }}
      </v-icon>
    </v-col>
    <v-col
      cols="10"
      md="11"
    >
      <a
        v-if="href"
        v-t="text"
        :href="href"
        class="underlined fs-16 fw-400 ms-2"
      />
      <div
        v-else
        v-text="text"
        class="c-black fs-16 fw-400 ms-2"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    color: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
