import SegmentService from '@/shared/services/analytics/SegmentService';
import { isNavigationFailure, useRouter } from 'vue-router';

/**
 * Creates a Vue Router navigation listener that sends page view events to Segment.
 *
 * @param {SegmentService} segment
 */
export default function useSegmentPageViews(segment) {
  const router = useRouter();

  if (!segment || typeof segment !== 'object' || !(segment instanceof SegmentService)) {
    throw new TypeError(
      `useSegmentPageViews() must be called with a ${SegmentService.name} instance.`,
    );
  }

  router.afterEach((to, from, failure) => {
    if (!isNavigationFailure(failure) && to.path !== from.path) {
      segment.page(to.name);
    }
  });
}
