<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col class="labeled-input">
        {{ $t(message) }}
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <v-text-field
          v-model="hours"
          @update:model-value="combine"
          :density="dense ? 'compact' : undefined"
          label="Hours"
          max="60"
          maxlength="2"
          min="0"
          placeholder="0"
          type="number"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="minutes"
          @update:model-value="combine"
          :aria-label="$t('Minutes')"
          :density="dense ? 'compact' : undefined"
          label="Minutes"
          max="59"
          maxlength="2"
          min="0"
          placeholder="0"
          type="number"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized],

  data() {
    return {
      hours: null,
      minutes: null,
    };
  },

  watch: {
    modelValue() {
      this.split();
    },
  },

  created() {
    this.split();
  },

  methods: {
    combine() {
      const hours = parseInt(this.hours, 10) || 0;
      const minutesInHours = hours * 60;
      const minutes = parseInt(this.minutes, 10) || 0;

      const combinedValue = minutesInHours + minutes;

      this.handleUpdateModelValue(combinedValue);
      this.handleBlur(combinedValue);
    },

    split() {
      const totalMinutes = parseInt(this.modelValue, 10);

      if (isNaN(totalMinutes)) {
        this.hours = null;
        this.minutes = null;
        return;
      } else {
        this.hours = parseInt(totalMinutes / 60, 10);
        this.minutes = totalMinutes % 60;

        if (this.minutes === 0) this.minutes = null;
      }
    },
  },
};
</script>
