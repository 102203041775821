<template>
  <canvas
    id="draw-canvas"
    data-cy="esign-canvas"
    height="125"
    width="350"
  >
    {{ $t('Draw your signature with your finger or mouse') }}
  </canvas>
</template>

<script setup>
defineExpose({ clear, filled, getDataUrl, stopDrawing });

const props = defineProps({
  initialCtx: {
    type: Object,
    default: null,
  },
  locked: {
    type: Boolean,
    default: true,
  },
});

const canvas = ref(null);
const ctx = ref(null);
const drawing = ref(false);
const lastPos = ref(null);
const mousePos = ref(null);

onMounted(() => initializeEsign());

function clear() {
  // eslint-disable-next-line no-self-assign
  canvas.value.width = canvas.value.width;
  setContext();
}

function drawLoop() {
  window.requestAnimationFrame(drawLoop);
  renderCanvas();
}

function filled() {
  const pixelBuffer = new Uint32Array(
    ctx.value.getImageData(0, 0, canvas.value.width, canvas.value.height).data.buffer,
  );
  return pixelBuffer.some((color) => color !== 0);
}

function getMousePos(canvasDom, mouseEvent) {
  const rect = canvasDom.getBoundingClientRect();
  return {
    x: mouseEvent.clientX - rect.left,
    y: mouseEvent.clientY - rect.top,
  };
}

function getTouchPos(canvasDom, touchEvent) {
  const rect = canvasDom.getBoundingClientRect();
  return {
    x: touchEvent.touches[0].clientX - rect.left,
    y: touchEvent.touches[0].clientY - rect.top,
  };
}

function renderCanvas() {
  if (drawing.value && !props.locked) {
    ctx.value.moveTo(lastPos.value.x, lastPos.value.y);
    ctx.value.lineTo(mousePos.value.x, mousePos.value.y);
    ctx.value.stroke();
    lastPos.value = mousePos.value;
  }
}

function getDataUrl() {
  return canvas.value.toDataURL();
}

function initializeEsign() {
  canvas.value = document.getElementById('draw-canvas');
  setContext();

  drawing.value = false;
  mousePos.value = { x: 0, y: 0 };
  lastPos.value = mousePos.value;

  canvas.value.addEventListener(
    'mousedown',
    (event) => {
      drawing.value = true;
      lastPos.value = getMousePos(canvas.value, event);
    },
    false,
  );

  canvas.value.addEventListener(
    'mouseup',
    () => {
      drawing.value = false;
    },
    false,
  );

  canvas.value.addEventListener(
    'mousemove',
    (event) => {
      mousePos.value = getMousePos(canvas.value, event);
    },
    false,
  );

  // Add touch event support for mobile
  canvas.value.addEventListener('touchstart', () => {}, false);

  canvas.value.addEventListener(
    'touchmove',
    (event) => {
      const touch = event.touches[0];
      const me = new MouseEvent('mousemove', {
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
      canvas.value.dispatchEvent(me);
    },
    false,
  );

  canvas.value.addEventListener(
    'touchstart',
    (event) => {
      mousePos.value = getTouchPos(canvas.value, event);
      const touch = event.touches[0];
      const me = new MouseEvent('mousedown', {
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
      canvas.value.dispatchEvent(me);
    },
    false,
  );

  canvas.value.addEventListener(
    'touchend',
    () => {
      const me = new MouseEvent('mouseup', {});
      canvas.value.dispatchEvent(me);
    },
    false,
  );

  // Prevent scrolling when touching the canvas
  document.body.addEventListener(
    'touchstart',
    (event) => {
      if (event.target === canvas.value) {
        event.preventDefault();
      }
    },
    false,
  );

  document.body.addEventListener(
    'touchend',
    (event) => {
      if (event.target === canvas.value) {
        event.preventDefault();
      }
    },
    false,
  );

  document.body.addEventListener(
    'touchmove',
    (event) => {
      if (event.target === canvas.value) {
        event.preventDefault();
      }
    },
    false,
  );

  drawLoop();
}

function setContext() {
  ctx.value = props.initialCtx || canvas.value.getContext('2d');
  ctx.value.strokeStyle = '#222222';
  ctx.value.lineWidth = 4;
}

function stopDrawing() {
  drawing.value = false;
}
</script>

<style>
#draw-canvas {
  border: 2px solid #cccccc;
  border-radius: 15px;
  cursor: crosshair;
}
</style>
