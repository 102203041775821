import axios from 'axios';
import { handleError } from '@/shared/services/api';

import child from '@/specialist/services/api/group/child';
import groupActivity from '@/specialist/services/api/group/group-activity';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/organization/groups`;
    if (id) url += `/${id}`;
    return url;
  },

  create(group, success, failure) {
    axios.post(this.baseUrl(), { group }).then(success).catch(failure);
  },

  index(params, success) {
    if (!success) return axios.get(this.baseUrl(), { params });

    return axios.get(this.baseUrl(), { params }).then(success);
  },

  get(id, success) {
    if (success) return axios.get(this.baseUrl(id)).then(success).catch(handleError);

    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  update(id, group, success, failure) {
    if (!success) return axios.patch(this.baseUrl(), { group });

    axios.patch(this.baseUrl(id), { group }).then(success).catch(failure);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id));
  },

  promiseUpdate(id, group) {
    return axios.patch(this.baseUrl(id), { group }).catch(handleError);
  },

  child,

  group_activity: groupActivity,
};
