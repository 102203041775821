export default {
  sort(list) {
    if (!list || list.length === 0) {
      return [];
    }
    if (list.length === 1) {
      return list;
    }

    // Using 'filter' instead of 'find' for easier vitest testing
    let newList = list.filter((item) => !item.leading_question_id);
    let counter = 0;
    const breakLimit = 1000;

    while (counter < breakLimit) {
      if (newList.length === list.length) break;
      const currentItem = newList[counter];

      const followingItem = list.find((item) => item.leading_question_id === currentItem.id);

      if (followingItem) newList.splice(counter + 1, 0, followingItem);

      counter += 1;
    }
    return newList;
  },
};
