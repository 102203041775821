<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="properties.role == 'formula'">
      <v-text-field
        v-model="localValue"
        @change="$emit('change', $event)"
        :append-inner-icon="processing ? 'pending' : 'lock'"
        :aria-label="$t(`Enter ${label}`)"
        :autofocus="autofocus"
        :density="dense || veryDense ? 'compact' : undefined"
        :required="mandatory"
        :rules="rules"
        data-cy="field-input"
        prefix="="
        variant="filled"
        disabled
        hide-details
      />
    </div>

    <template v-else>
      <div v-if="properties.type == 'array'">
        <template v-if="properties.items.type == 'object'">
          <v-card
            v-for="(item, itemIndex) in localValue"
            :key="itemIndex"
            class="mb-3"
            border
            flat
            tile
          >
            <v-card-text>
              <v-row dense>
                <LabeledControl
                  v-for="field in itemFields"
                  :key="[itemIndex, field].join('.')"
                  :cols="field[1].format == 'date' ? '12' : groupedFieldCols"
                  :format="field[1].format"
                  :message="field[1].alias || field[1].title"
                  :type="field[1].type"
                >
                  <Field
                    v-model="item[field[0]]"
                    @change="$emit('change', $event)"
                    @input="inputEventHandler()"
                    :append-inner-icon="appendInnerIcon"
                    :booleans-as-checkboxes="booleansAsCheckboxes"
                    :hard-lock="locked"
                    :multiple-boolean-fields="multipleBooleanFields"
                    :properties="field[1]"
                    :readonly="readonly"
                    :required="mandatory"
                    :rules="rules"
                    :sibling-values="modelValue[0]"
                    condensed
                  />
                </LabeledControl>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <template v-if="!locked">
                <v-btn
                  @click="removeItem(itemIndex)"
                  class="focus-very-visible"
                  color="red"
                  variant="text"
                >
                  <span>{{ $t('Remove') }}</span>
                </v-btn>
              </template>
            </v-card-actions>
          </v-card>

          <template v-if="!locked">
            <v-btn
              @click="addItem(properties.items.properties)"
              class="focus-very-visible"
              color="primary"
            >
              {{ $t('Add') }}
            </v-btn>
          </template>
        </template>

        <template v-else>
          <v-row dense>
            <v-col>
              <template v-if="properties.items.enum?.length > 10 || condensed">
                <v-select
                  v-model="localValue"
                  @update:model-value="$emit('change', $event)"
                  :append-inner-icon="appendInnerIcon"
                  :aria-label="$t(`Select ${label}`)"
                  :density="dense || veryDense ? 'compact' : undefined"
                  :disabled="locked"
                  :items="items"
                  :readonly="readonly"
                  :required="mandatory"
                  :rules="rules"
                  data-cy="field-input"
                  variant="filled"
                  chips
                  closable-chips
                  hide-details
                  multiple
                />
              </template>

              <template v-else>
                <v-checkbox
                  v-for="(option, index) in properties.items.enum"
                  v-model="localValue"
                  @update:model-value="$emit('change', $event)"
                  :key="index"
                  :append-inner-icon="appendInnerIcon"
                  :aria-label="$t(option)"
                  :autofocus="autofocus"
                  :disabled="locked"
                  :label="$t(option)"
                  :readonly="readonly"
                  :required="mandatory"
                  :rules="rules"
                  :value="option"
                  class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 mx-0 my-1"
                  data-cy="field-input"
                  hide-details
                  multiple
                />
              </template>
            </v-col>
          </v-row>
        </template>
      </div>

      <div v-if="properties.type == 'boolean'">
        <v-row dense>
          <template v-if="multipleBooleanFields && booleansAsCheckboxes">
            <v-col cols="12">
              <v-checkbox
                v-model="localValue"
                @update:model-value="$emit('change', $event)"
                :append-inner-icon="appendInnerIcon"
                :density="dense || veryDense ? 'compact' : undefined"
                :disabled="locked"
                :label="checkboxLabel"
                :readonly="readonly"
                :required="mandatory"
                :rules="rules"
                class="bg-super-light-blue pa-2 bc-extra-light-gray b-1"
                data-cy="field-input"
                hide-details
              />
            </v-col>
          </template>

          <template v-else>
            <v-col cols="12">
              <v-radio-group
                v-model="localValue"
                @update:model-value="$emit('change', $event)"
                ref="radioButton"
                :label="multipleBooleanFields ? $t(label) : undefined"
                :required="mandatory"
                :rules="rules"
                class="mt-0"
                data-cy="field-input"
                hide-details
              >
                <v-radio
                  :disabled="locked"
                  :label="$t(trueLabel)"
                  :readonly="readonly"
                  :value="true"
                  class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
                />
                <v-radio
                  v-if="falseLabel"
                  :disabled="locked"
                  :label="$t(falseLabel)"
                  :readonly="readonly"
                  :value="false"
                  class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
                />
              </v-radio-group>
            </v-col>

            <v-col class="d-flex justify-end">
              <v-btn
                @click="clearResponse"
                :disabled="locked"
                class="b-1 my-1 fs-14"
                data-cy="clear_response_btn"
                size="small"
                variant="outlined"
              >
                <span>{{ $t('Clear response') }}</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </div>

      <div v-if="properties.type == 'integer'">
        <v-row dense>
          <v-col>
            <template
              v-if="properties.maximum && properties.maximum - (properties.minimum || 0) < 100"
            >
              <v-select
                v-model.number="localValue"
                @update:model-value="$emit('change', $event)"
                :append-inner-icon="appendInnerIcon"
                :aria-label="$t(`Select ${label}`)"
                :density="dense || veryDense ? 'compact' : undefined"
                :disabled="locked"
                :items="maxMinArray"
                :readonly="readonly"
                :required="mandatory"
                :rules="rules"
                data-cy="field-input"
                variant="filled"
                hide-details
              />
            </template>

            <template v-else>
              <v-text-field
                v-model.number="localValue"
                @change="$emit('change', $event)"
                @update:model-value="inputEventHandler(properties)"
                :append-inner-icon="appendInnerIcon"
                :aria-label="$t(`Enter ${label}`)"
                :autofocus="autofocus"
                :density="dense || veryDense ? 'compact' : undefined"
                :disabled="locked"
                :readonly="readonly"
                :required="mandatory"
                :rules="rules"
                data-cy="field-input"
                variant="filled"
                hide-details
                tile
              />
            </template>
          </v-col>
        </v-row>
      </div>

      <div v-if="properties.type == 'number'">
        <v-row dense>
          <v-col>
            <template v-if="properties.role == 'currency-us'">
              <VCurrencyField
                v-model.number="localValue"
                @change="handleMaybeNumberChange($event)"
                @update:model-value="inputEventHandler(properties)"
                :append-inner-icon="appendInnerIcon"
                :aria-label="$t(`Enter ${label}`)"
                :autofocus="autofocus"
                :density="dense || veryDense ? 'compact' : undefined"
                :disabled="locked"
                :readonly="readonly"
                :required="mandatory"
                :rules="rules"
                data-cy="field-input"
                variant="filled"
                hide-details
                tile
              />
            </template>

            <template v-else>
              <template
                v-if="properties.maximum && properties.maximum - (properties.minimum || 0) < 100"
              >
                <v-select
                  v-model.number="localValue"
                  @update:model-value="$emit('change', $event)"
                  :append-inner-icon="appendInnerIcon"
                  :aria-label="$t(`Select ${label}`)"
                  :density="dense || veryDense ? 'compact' : undefined"
                  :disabled="locked"
                  :items="maxMinArray"
                  :readonly="readonly"
                  :required="mandatory"
                  :rules="rules"
                  data-cy="field-input"
                  variant="filled"
                  condensed
                  hide-details
                />
              </template>

              <template v-else>
                <v-text-field
                  v-model.number="localValue"
                  @change="$emit('change', $event)"
                  @update:model-value="inputEventHandler(properties)"
                  :append-inner-icon="appendInnerIcon"
                  :aria-label="$t(`Enter ${label}`)"
                  :autofocus="autofocus"
                  :density="dense || veryDense ? 'compact' : undefined"
                  :disabled="locked"
                  :readonly="readonly"
                  :required="mandatory"
                  :rules="rules"
                  data-cy="field-input"
                  type="number"
                  variant="filled"
                  hide-details
                  tile
                />
              </template>
            </template>
          </v-col>
        </v-row>
      </div>

      <div v-if="properties.type == 'string'">
        <template v-if="properties.enum && properties.enum.length > 0">
          <template v-if="condensed || properties.enum.length > 5">
            <v-select
              v-model="localValue"
              @update:model-value="
                $emit('change', $event);
                inputEventHandler(properties);
              "
              :append-inner-icon="appendInnerIcon"
              :aria-label="$t(`Select ${label}`)"
              :density="dense || veryDense ? 'compact' : undefined"
              :disabled="locked"
              :items="items"
              :readonly="readonly"
              :required="mandatory"
              :rules="rules"
              data-cy="field-input"
              variant="filled"
              hide-details
              tile
            />
          </template>

          <template v-else>
            <v-row dense>
              <v-col cols="12">
                <v-radio-group
                  v-model="localValue"
                  @update:model-value="$emit('change', $event)"
                  ref="radioButton"
                  :required="mandatory"
                  :rules="rules"
                  class="mt-0"
                  data-cy="field-input"
                  hide-details
                >
                  <v-radio
                    v-for="(option, index) in properties.enum"
                    :key="index"
                    :disabled="locked"
                    :label="$t(option)"
                    :readonly="readonly"
                    :value="option"
                    class="bg-super-light-blue pa-2 bc-extra-light-gray b-1 my-1"
                  />
                </v-radio-group>
              </v-col>

              <v-col class="d-flex justify-end">
                <v-btn
                  @click="clearResponse"
                  :disabled="locked"
                  class="b-1 my-1 fs-14"
                  size="small"
                  variant="outlined"
                >
                  <span>{{ $t('Clear response') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </template>

        <template v-if="properties.format == 'date'">
          <v-row dense>
            <LabeledDate
              v-model="localValue"
              @change="$emit('change', $event)"
              :autofocus="autofocus"
              :dense="dense || veryDense"
              :disabled="locked"
              :hard-lock="locked"
              :maximum="dateMaximum(properties, siblingValues)"
              :minimum="dateMinimum(properties, siblingValues)"
              :readonly="readonly"
              :required="mandatory"
              :rules="rules"
              data-cy="field-input"
            />
          </v-row>
        </template>

        <template v-if="properties.role == 'phone-us'">
          <MaskedInput
            v-model="localValue"
            v-slot="{ inputRef, masked }"
            mask="(###) ###-####"
          >
            <v-text-field
              v-model="masked.value"
              @change="$emit('change', $event)"
              :ref="inputRef"
              :append-inner-icon="appendInnerIcon"
              :aria-label="$t('Enter phone number')"
              :autofocus="autofocus"
              :density="dense || veryDense ? 'compact' : undefined"
              :disabled="locked"
              :readonly="readonly"
              :required="mandatory"
              :rules="rules"
              data-cy="field-input"
              placeholder="(555) 555-5555"
              variant="filled"
              hide-details
              tile
            />
          </MaskedInput>
        </template>

        <template v-if="properties.role == 'text-long'">
          <v-textarea
            v-if="!locked"
            v-model="localValue"
            @change="$emit('change', $event)"
            @update:model-value="inputEventHandler(properties)"
            :append-inner-icon="appendInnerIcon"
            :aria-label="$t(label)"
            :autofocus="autofocus"
            :density="dense || veryDense ? 'compact' : undefined"
            :disabled="locked"
            :readonly="readonly"
            :required="mandatory"
            :rules="rules"
            data-cy="field-input"
            variant="filled"
          />
          <div
            v-else
            class="disabled-textarea-container"
          >
            <v-icon icon="lock" />
            <div
              v-html="localValue.replace(/\n/g, '<br>')"
              class="textarea-content"
            ></div>
          </div>
        </template>

        <template
          v-if="
            !(properties.enum && properties.enum.length > 0) &&
            properties.format != 'date' &&
            !properties.role
          "
        >
          <MaskedInput
            v-model="localValue"
            @update:model-value="inputEventHandler(properties)"
            v-slot="{ inputRef, masked }"
            :mask="properties.mask"
          >
            <v-text-field
              v-model="masked.value"
              @change="$emit('change', $event)"
              :ref="inputRef"
              :append-inner-icon="appendInnerIcon"
              :aria-label="$t(`Enter ${label}`)"
              :autofocus="autofocus"
              :density="dense || veryDense ? 'compact' : undefined"
              :disabled="locked"
              :placeholder="properties.placeholder"
              :readonly="readonly"
              :required="mandatory"
              :rules="rules"
              data-cy="field-input"
              variant="filled"
              hide-details
              tile
            />
          </MaskedInput>
        </template>
      </div>
      <p
        v-if="displayAnswer"
        class="my-1 fw-600"
      >
        {{ $t('Answer') }}: {{ answer }}
      </p>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledDate from '@/shared/components/form/LabeledDate.vue';
import MaskedInput from '@/shared/components/form/MaskedInput.vue';
import VCurrencyField from '@/shared/components/form/VCurrencyField.vue';
import { dateMaximum, dateMinimum } from '@/shared/services/schema-date-validation';
import { capitalize } from '@/plugins/filters';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LabeledControl,
    LabeledDate,
    MaskedInput,
    VCurrencyField,
  },

  props: {
    answer: {
      type: String,
      default: null,
    },
    answerType: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    booleansAsCheckboxes: {
      type: Boolean,
      default: false,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    displayAnswer: {
      type: Boolean,
      default: false,
    },
    falseLabel: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    landscape: {
      type: Boolean,
      default: false,
    },
    multipleBooleanFields: {
      type: Boolean,
      default: false,
    },
    properties: {
      type: Object,
      default() {
        return {};
      },
    },
    processing: {
      default: false,
      type: Boolean,
    },
    siblingValues: {
      type: Object,
      default() {
        return {};
      },
    },
    trueLabel: {
      type: String,
      default: null,
    },
    veryDense: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    modelValue: null,
  },

  emits: ['change', 'change:input', 'update:modelValue'],

  data() {
    return {
      emittedMountedChangeEvent: false,
      localValue: this.getInitialValue(),
    };
  },

  computed: {
    appendInnerIcon() {
      return this.locked ? 'lock' : undefined;
    },

    checkboxLabel() {
      return capitalize(this.$t(this.label));
    },

    items() {
      if (this.properties.type === 'array') {
        return this.properties.items.enum?.map((item) => ({ text: this.$t(item), value: item }));
      }

      if (this.properties.type === 'string' && this.properties.enum) {
        return this.properties.enum?.map((item) => ({ text: this.$t(item), value: item }));
      }

      return [];
    },

    itemFields() {
      return _.sortBy(
        Object.entries(this.properties.items?.properties || {}),
        (object) => object[1].index,
      );
    },

    groupedFieldCols() {
      if (this.$vuetify.display.smAndDown) return '12';

      return Math.max(3, 12 / this.itemFields.length).toString();
    },

    label() {
      return this.$t(this.properties.alias || this.properties.title);
    },

    locked() {
      if (this.readonly) return true;

      if (Array.isArray(this.properties?.editRoles)) {
        return !this.properties.editRoles.includes(this.$role);
      }

      return false;
    },

    maxMinArray() {
      if (this.properties.maximum && this.properties.minimum !== undefined) {
        return [...Array(this.properties.maximum - (this.properties.minimum || 0)).keys()].map(
          (item) => item + (this.properties.minimum || 0),
        );
      }
      return [];
    },

    rules() {
      const rules = [];

      if (this.mandatory) {
        const rule = (value) =>
          (value !== null && value !== undefined && value.length !== 0) ||
          `${this.label} ${this.$t('is required')}`;
        rules.push(rule);
      }

      return rules;
    },
  },

  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        if (newValue === oldValue || (newValue?.length === 0 && !oldValue)) return;

        this.localValue = newValue;
      },
    },

    localValue: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal);
      },
      deep: true,
    },
  },

  mounted() {
    if (this.properties.type === 'boolean' && this.autofocus) this.$refs.radioButton.focus();
  },

  methods: {
    addItem(props) {
      return this.localValue.push(
        Object.keys(props).reduce((accumulator, value) => ({ ...accumulator, [value]: '' }), {}),
      );
    },

    clearResponse() {
      this.localValue = null;
      this.$emit('change', null);
    },

    dateMaximum,

    dateMinimum,

    getInitialValue() {
      if (!this.modelValue && this.properties.type === 'array') return [];

      return this.modelValue;
    },

    handleMaybeNumberChange(event) {
      // Event can sometimes be an object, so we need to check if it's a number
      if (typeof event !== 'number' && event != null) return;

      // Hack in place until this is resolved: https://github.com/dm4t2/vue-currency-input/issues/421
      if (!this.emittedMountedChangeEvent) {
        this.emittedMountedChangeEvent = true;
        return;
      }

      this.$emit('change', event);
    },

    inputEventHandler(properties) {
      if (properties?.mask) return;

      this.$emit('change:input');
    },

    removeItem(index) {
      this.localValue.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.disabled-textarea-container {
  background-color: rgba(#eee, 40%);
  color: darken(#bdbdbd, 13%);
  font-size: 1rem;
  height: 136px;
  position: relative;
  width: 100%;

  i.v-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .textarea-content {
    height: 136px;
    overflow-y: auto;
    padding: 10px 36px 10px 16px;
    white-space: pre-wrap;
    width: 100%;
  }
}
.v-selection-control {
  > :deep(.v-label) {
    width: 100%;
    padding-left: 56px;
    z-index: -1;
  }
}
</style>
