import axios from 'axios';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/screener_programs`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(screenerProgram) {
    return axios.post(this.baseUrl(), { screener_program: screenerProgram });
  },

  async get(id) {
    return axios.get(this.baseUrl(id));
  },

  async index(filters) {
    return axios.get(this.baseUrl(), { params: filters });
  },

  async update(screenerProgram) {
    return axios.patch(this.baseUrl(screenerProgram.id), { screener_program: screenerProgram });
  },

  async destroy(screenerProgram) {
    return axios.delete(this.baseUrl(screenerProgram.id));
  },
};
