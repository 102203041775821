export function isAuthenticated(store) {
  return store.state.profile && store.state.profile.organization_id === window.organization_id;
}

export function needsMFA(store) {
  return (
    store.state.config.enable_mfa_authentication &&
    store.state.config.enable_mfa_requirement &&
    !store.state.profile.workos_mfa_factor_confirmed
  );
}

export function needsPasswordReset(config, profile, to) {
  return (
    config.enable_password_authentication &&
    (to.query.password_reset === 'true' ||
      profile?.password_reset_required === true ||
      !profile?.password_set)
  );
}
