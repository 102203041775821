import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/v2/forms`;
    if (id) url += `/${id}`;
    return url;
  },

  create(schema) {
    return axios.post(this.baseUrl(), schema).catch(handleError);
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  update(id, attributes) {
    return axios.patch(this.baseUrl(id), { data: { attributes } }).catch(handleError);
  },
};
