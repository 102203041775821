import { useStore } from 'vuex';

/**
 * We currently provide legacy support for site terms as we migrate to using
 * organization terms only. This returns the combined site and organization terms
 * with organization terms given priority over site terms.
 *
 * @returns {
 *   terms: () => ref
 * }
 */
export default function useTerms() {
  const store = useStore();

  const terms = computed(() => {
    const organizationTerms = store.state.config.terms;
    const siteTerms = store.state.site.terms;

    return { ...siteTerms, ...organizationTerms };
  });

  return {
    terms,
  };
}
