import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) {
      return `${window.api_url}/organization/subsidy_awards/${id}`;
    }
    return `${window.api_url}/organization/subsidy_awards`;
  },

  close(id, award) {
    return axios.patch(`${this.baseUrl(id)}/close`, { subsidy_award: award }).catch(handleError);
  },

  create(award) {
    return axios.post(this.baseUrl(), { subsidy_award: award }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  open(id) {
    return axios.patch(`${this.baseUrl(id)}/open`).catch(handleError);
  },

  update(id, award) {
    return axios.patch(this.baseUrl(id), { subsidy_award: award }).catch(handleError);
  },
};
