import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/custom_views`;
  },

  create(attributes, subsidyProgramId) {
    return axios
      .post(this.baseUrl(), {
        data: {
          type: 'custom_view',
          attributes,
          relationships: {
            content_template: {
              data: {
                type: 'subsidy_program_id',
                id: subsidyProgramId,
              },
            },
          },
        },
      })
      .catch(handleError);
  },

  destroy(id) {
    return axios.delete(`${this.baseUrl()}/${id}`);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters });
  },

  get(id) {
    return axios.get(`${this.baseUrl()}/${id}`);
  },

  update(id, attributes) {
    return axios
      .patch(`${this.baseUrl()}/${id}`, {
        data: {
          type: 'custom_view',
          attributes,
        },
      })
      .catch(handleError);
  },
};
