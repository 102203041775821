<template>
  <v-card
    v-if="displayPaymentDetails"
    class="mb-4"
  >
    <v-card-title>
      <h2 class="fs-20 fw-800">
        {{ $t('Financial programs') }}
      </h2>
    </v-card-title>
    <v-card-text>
      <v-row v-if="provider.accepts_subsidy">
        <div class="mb-2">
          <v-icon
            class="me-2 ms-2 fs-16"
            color="#00A391"
            icon="check_circle_outline"
            size="24"
          />
          <span class="fs-16 fw-400">
            {{ $t('This child care provider accepts financial aid') }}
          </span>
        </div>
      </v-row>
      <v-row
        v-if="provider.payment_subsidies_accepted.length > 0"
        class="mb-2 fs-16"
        dense
      >
        <v-col cols="1">
          <v-icon
            class="fs-24 mt-0"
            color="#578052"
            icon="attach_money"
          />
        </v-col>
        <v-col cols="11">
          <div class="mb-1 fs-16 fw-500">
            {{ $t('Financial assistance options available') }}
          </div>
          <div
            v-for="(subsidy, index) in provider.payment_subsidies_accepted"
            v-text="subsidy"
            :key="index"
            class="fs-16 fw-400"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="provider.payment_other_programs.length > 0"
        class="mb-2"
        dense
      >
        <v-col cols="1">
          <img
            :src="$a.url('icons.headstart')"
            alt="Head Start icon"
            class="h-24"
          />
        </v-col>
        <v-col cols="11">
          <div class="mb-1 fs-16 fw-500">
            {{ getLabel('payment_other_programs', 'Preschool assistance programs') }}
          </div>
          <div
            v-for="(program, index) in provider.payment_other_programs"
            v-text="$t(program)"
            :key="index"
            class="fs-16 fw-400"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="provider.payment_other_description"
        class="mb-4"
        dense
      >
        <v-col cols="1">
          <v-icon
            class="fs-24"
            color="primary"
            icon="loyalty"
          />
        </v-col>
        <v-col cols="11">
          <div class="fs-16 fw-500 mb-1">
            {{ getLabel('payment_other_description', 'Additional payment options') }}
          </div>
          <div class="fs-16 fw-400">
            {{ $t(provider.payment_other_description) }}
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="provider.payment_methods_accepted.length > 0"
        dense
      >
        <v-col cols="12">
          <div class="fs-16 fw-500 mb-2">
            {{ $t('Payment options:') }}
          </div>
          <div
            v-for="(method, index) in provider.payment_methods_accepted"
            :key="index"
            class="d-inline-block me-3"
          >
            <v-icon
              class="me-1 va-text-center"
              color="primary"
              icon="check_circle"
              size="24"
            />
            <span
              v-text="$t(method)"
              class="fs-16 fw-400"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const getLabel = (property, defaultLabel) => {
  return getProperties()[property]?.alias || defaultLabel;
};

const getProperties = () => {
  return store.state.schemas[props.provider.schema_id].definition.properties;
};

const displayPaymentDetails = computed(() => {
  return (
    props.provider.accepts_subsidy ||
    props.provider.payment_subsidies_accepted.length > 0 ||
    props.provider.payment_other_programs.length > 0 ||
    props.provider.payment_other_description ||
    props.provider.payment_methods_accepted.length > 0
  );
});
</script>
