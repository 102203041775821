<!-- eslint-disable vue/no-v-html -->
<template>
  <span
    v-if="translatedAndRenderedHtml"
    v-html="translatedAndRenderedHtml"
    class="markdown-content"
    data-testid="markdown-content"
  />
</template>

<script>
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const addTargetAndRelToLinks = (node) => {
  if (node.tagName === 'A') {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'nofollow');
  }
  return node;
};
DOMPurify.addHook('afterSanitizeAttributes', addTargetAndRelToLinks);

export default {
  compatConfig: { MODE: 3 },

  props: {
    content: {
      type: String,
      default: null,
    },
  },

  computed: {
    translatedAndRenderedHtml() {
      if (!this.content) return null;
      const html = this.renderMarkdown(this.$t(this.content));
      return DOMPurify.sanitize(html);
    },
  },

  methods: {
    renderMarkdown(string) {
      if (!string) return null;

      const renderer = new marked.Renderer();

      renderer.paragraph = function (text) {
        return `${text}`;
      };
      return marked(string, { renderer });
    },
  },
};
</script>
<style>
.markdown-content > ol,
ul {
  list-style-position: inside;
}
</style>
