import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/parent/application_summaries`;
  },

  async index() {
    return axios.get(this.baseUrl()).catch(handleError);
  },
};
