<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title>
      <h2 class="fs-20 fw-800">
        {{ $t(title) }}
      </h2>
    </v-card-title>
    <v-card-text>
      <div
        v-if="provider.quality_rating_score > 0"
        class="mb-2"
      >
        <span class="fs-16 fw-400 me-2">{{ $t($store.state.site.ratings.name) }}:</span>
        <v-rating
          v-if="provider.quality_rating_score"
          v-model="provider.quality_rating_score"
          :length="$store.state.site.ratings.max"
          active-color="primary"
          class="d-inline-block me-2 va-middle"
          color="#eecb54"
          density="compact"
          size="small"
          readonly
        >
          <template
            v-if="$store.state.site.ratings.icons.length > 0"
            #item="props"
          >
            <CustomRating
              :index="props.index"
              :passed="props.isFilled"
              :score="provider.quality_rating_score"
            />
          </template>
        </v-rating>

        <span class="c-light-black fs-16 fw-400 me-1">
          ({{ provider.quality_rating_score }} {{ $t('out of') }}
          {{ $store.state.site.ratings.max }})
        </span>
      </div>
      <div class="mb-2 fs-16 fw-400">
        <span>
          {{
            $t(
              $store.state.schemas[provider.schema_id].definition.properties.state_provider_id
                .alias,
            )
          }}
          {{ provider.state_provider_id }}
        </span>
      </div>
      <div
        v-if="
          provider.state_location_id &&
          $store.state.schemas[provider.schema_id].definition.properties.state_location_id.enabled
        "
        class="mb-2 fs-16 fw-400"
      >
        <span class="fs-16">
          {{
            $t(
              $store.state.schemas[provider.schema_id].definition.properties.state_location_id
                .alias,
            )
          }}
          {{ provider.state_location_id }}
        </span>
      </div>
      <div>
        <a
          v-if="!provider.full_license && provider.license_url"
          :href="provider.license_url"
          class="c-primary fs-16 fw-600"
          style="text-decoration: none"
          target="_blank"
        >
          <span class="me-1">{{ $t('View certificates and inspection reports') }}</span>
          <v-icon
            color="primary"
            icon="arrow_right"
            size="24"
          />
        </a>
        <div
          v-if="provider.full_license"
          @click="showLicense()"
          class="c-primary fw-600 pointer"
        >
          <span class="me-1">{{ $t('View certificates and inspection reports') }}</span>
          <v-icon
            color="primary"
            icon="arrow_drop_down"
          />
        </div>
      </div>
      <v-row v-if="license">
        <v-col class="pb-1">
          <ProviderLicense :license="license" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import publicApi from '@/shared/services/api/public-api';
import CustomRating from '@/shared/components/CustomRating.vue';
import ProviderLicense from '@/shared/components/provider/ProviderLicense.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
  provider: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
});

const license = ref(null);

watch(
  () => route.params.providerId,
  () => {
    license.value = null;
  },
  { immediate: true },
);

function showLicense() {
  if (license.value) {
    license.value = null;
  } else {
    publicApi.provider.license.get(props.provider.id, (resp) => {
      license.value = resp.data;
    });
  }
}
</script>
