<template>
  <v-row
    class="mb-1"
    dense
  >
    <v-col
      aria-hidden="true"
      cols="7"
    >
      <div class="c-black fs-16 fw-600">
        {{ $t(title) }}
      </div>
      <div
        v-if="subtitle"
        class="c-light-black fs-16 fw-500"
      >
        {{ $t(subtitle) }}
      </div>
    </v-col>
    <v-col
      class="justify-end d-flex align-center age-group"
      cols="5"
    >
      <v-btn
        @click="decrease"
        ref="minus"
        :aria-label="decreaseLabel"
        :disabled="count <= 0"
        class="focus-visible h-28 w-28 me-0"
        variant="text"
        icon
      >
        <v-icon class="c-primary fs-24"> remove_circle </v-icon>
      </v-btn>
      <v-avatar
        :aria-label="countLabel"
        :class="countClass"
        aria-live="polite"
        class="c-white"
        data-testid="count"
        size="28"
        tabindex="-1"
      >
        {{ count }}
      </v-avatar>
      <v-btn
        @click="increase"
        @keyup.delete="decrease"
        @keyup.down="$emit('down')"
        @keyup.up="$emit('up')"
        ref="plus"
        :aria-label="increaseLabel"
        class="focus-visible ms-0"
        data-cy="increase_child_selection"
        variant="text"
        icon
      >
        <v-icon class="c-primary h-28 w-28 fs-24"> add_circle </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },

  props: {
    count: {
      type: Number,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },

  emits: ['decrese', 'down', 'increase', 'up'],

  computed: {
    countClass() {
      return this.count > 0 ? 'bg-primary' : 'bg-light-gray';
    },

    decreaseLabel() {
      const str = [this.$t('decrease'), this.$t(this.title), this.$t('by'), 1].join(' ');
      return str;
    },

    increaseLabel() {
      let str = [this.$t('increase'), this.$t(this.title), this.$t('by'), 1].join(' ');
      str += this.$t(', Press Delete or Backspace to decrease by 1');
      return str;
    },

    countLabel() {
      return [this.$t(this.count.toString()), this.$t(this.title)].join(' ');
    },
  },

  methods: {
    decrease() {
      if (this.count <= 0) return;

      this.$emit('decrease');
    },

    increase() {
      if (this.count >= 1000) return;

      this.$emit('increase');
    },

    focus() {
      this.$refs.plus.$el.focus();
    },
  },
};
</script>
