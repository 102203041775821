<template>
  <v-card
    class="mx-auto bc-extra-light-gray b-1 mt-16 pa-8"
    max-width="700px"
  >
    <v-card-text class="ta-center">
      <img
        class="mb-4"
        src="/icons/login_check.svg"
      />
      <v-row class="fs-32 fw-800 mb-8">
        <v-col>
          <span>
            {{ $t('Welcome back!') }}
          </span>
        </v-col>
      </v-row>
      <v-btn
        @click="check()"
        :loading="processing"
        color="primary"
        size="x-large"
      >
        {{ $t('Continue') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/shared/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const display = ref(true);
const eventBus = useEventBus();
const processing = ref(false);
const route = useRoute();
const router = useRouter();
const store = useStore();

onMounted(() => {
  if (!_.isEmpty(route.query.redirect_url)) {
    check();
  } else {
    store.dispatch('identify', {
      success() {
        if (store.state.profile.is_anonymous) {
          check();
        } else {
          routeToDestination();
        }
      },
      failure() {
        load();
      },
    });
  }
});

function check() {
  processing.value = true;
  Api.member.session.create(
    { login_code: route.params.login_code },
    () => {
      store.dispatch('identify', {
        success() {
          routeToDestination();
        },
        failure() {
          error();
          processing.value = false;
        },
      });
    },
    error,
  );
}

function error() {
  eventBus.chime('Login link is expired or incorrect. Please try again.');
  router.push({ name: 'Welcome' });
}

function load() {
  display.value = true;
}

function routeToDestination() {
  if (route.query.redirect_url) {
    router.push(route.query.redirect_url);
    return;
  }
  if (route.query.destination === 'messages') {
    router.push({ name: 'MessageIndex' });
  } else if (route.query.destination === 'tour') {
    router.push({
      name: 'TourShow',
      params: { tourId: route.query.tour_id },
      query: { action: 'confirm' },
    });
  } else if (route.query.destination === 'provider') {
    router.push({ name: 'PublicProviderShow', params: { providerId: route.query.provider_id } });
  } else if (route.query.destination === 'documents') {
    router.push({ name: 'DocumentIndex' });
  } else if (route.query.destination === 'forms') {
    router.push({ name: 'FormIndex' });
  } else if (route.query.destination === 'grants') {
    router.push({ name: 'GrantIndex' });
  } else {
    router.push({ name: 'Dashboard' });
  }
}
</script>
