<template>
  <span
    :aria-label="$t('required')"
    :class="fontSize"
    class="c-red"
  >
    *
  </span>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    size: {
      default: '16',
      type: String,
    },
  },
  computed: {
    fontSize() {
      if (this.size === 'inherit') {
        return 'fs-inherit';
      }
      return `fs-${this.size}`;
    },
  },
};
</script>
