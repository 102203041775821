<template>
  <v-col
    :class="extraClasses"
    :cols="cols"
    :md="md"
    :sm="sm"
    class="fw-600 c-black d-none d-md-flex align-center"
  >
    <slot name="prefix" />
    <span>{{ $t(title) }}</span>
    <slot name="suffix" />
  </v-col>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },

  props: {
    classes: {
      type: String,
      default: null,
    },
    cols: {
      type: String,
      default: null,
    },
    dense: Boolean,
    md: {
      type: String,
      default: null,
    },
    paddingStart: {
      type: String,
      default: null,
    },
    sm: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    extraClasses() {
      const ary = [];
      if (this.dense) {
        ary.push('fs-14');
      } else {
        ary.push('fs-16');
      }

      if (this.classes) {
        ary.push(this.classes);
      }

      return ary.join(' ');
    },
  },
};
</script>
