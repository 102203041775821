import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/terms_agreements`;
    if (id) url += `/${id}`;
    return url;
  },

  create(agreement, success, failure) {
    axios.post(this.baseUrl(), { terms_agreement: agreement }).then(success).catch(failure);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  downloadUrl(id, params) {
    const urlParams = new URLSearchParams(params).toString();
    return `${this.baseUrl(id)}.pdf?${urlParams}`;
  },

  async index(params = {}, successCallback = null, failureCallback = null) {
    return axios
      .get(this.baseUrl(), { params })
      .then((response) => {
        if (successCallback) successCallback(response);
        return response;
      })
      .catch((error) => {
        if (failureCallback) return failureCallback(error);
        return handleError(error);
      });
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  update(id, agreement, success, failure) {
    axios.patch(this.baseUrl(id), { terms_agreement: agreement }).then(success).catch(failure);
  },
};
