import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

/**
 * Works with form input fields to leverage our schematized field definitions.
 * (see LabeledSelect.vue for example of use)
 *
 * Append emits to your component emit definition
 * Append props to your component prop definition
 *
 * @param {*} fieldRef
 * @param {*} hardLockRef
 * @param {*} mandatoryRef
 * @param {*} messageRef
 * @param {*} schemaIdRef
 * @returns {
 *  enabled: boolean
 *  label: ref
 *  ariaLabel: string
 *  locked: ref
 *  minimum: any
 *  mask: any
 *  propertyBySchema: ref
 * }
 */
export default function useSchematizedField(
  fieldRef,
  hardLockRef,
  mandatoryRef,
  messageRef,
  schemaIdRef,
) {
  const store = useStore();
  const { t } = useI18n();

  const schemaProperties = computed(() => {
    if (!fieldRef.value) return null;
    if (!schemaIdRef.value) {
      throw new Error('schemaId required to use field');
    }
    if (!store.state.schemas[schemaIdRef.value]) {
      throw new Error(`Schema missing from site schemas - ID: ${schemaIdRef.value}`);
    }

    const schema = store.state.schemas[schemaIdRef.value]?.definition.properties;
    const namespace = fieldRef.value.split('.').reduce((value, index) => value[index], schema);
    return namespace || {};
  });

  const enabled = computed(() => {
    if (!fieldRef.value) return true;
    return schemaProperties.value?.enabled;
  });

  const label = computed(() => {
    if (messageRef.value) return messageRef.value;
    return schemaProperties.value?.alias || schemaProperties.value?.title || '';
  });

  const ariaLabel = computed(() => {
    if (!label.value) return null;

    const translatedLabel = t(label.value);
    if (mandatoryRef.value) {
      const translatedRequired = t('required');
      return `${translatedLabel} - ${translatedRequired}`;
    }

    return translatedLabel;
  });

  const locked = computed(() => {
    if (hardLockRef.value) return true;
    if (schemaProperties.value) {
      return !(schemaProperties.value.editRoles || []).includes(store.state.role);
    }
    return false;
  });

  const minimum = computed(() => {
    return schemaProperties.value?.minimum;
  });

  const schemaMask = computed(() => {
    return schemaProperties.value?.mask;
  });

  const propertyBySchema = computed(() => {
    if (fieldRef.value) {
      const schemaProperties = store.state.schemas[schemaIdRef.value].definition.properties;
      return (
        fieldRef.value.split('.').reduce((value, index) => value[index], schemaProperties) || {}
      );
    }
    return null;
  });

  return {
    enabled,
    label,
    ariaLabel,
    locked,
    minimum,
    schemaMask,
    propertyBySchema,
  };
}

useSchematizedField.props = {
  field: {
    type: String,
    default: null,
  },
  hardLock: {
    type: Boolean,
    default: false,
  },
  hardLockMessage: {
    type: String,
    default: 'Locked',
  },
  mandatory: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: null,
  },
  schemaId: {
    type: String,
    default: null,
  },
  enforceMinMaxRules: {
    type: Boolean,
    default: true,
  },
};

// Must match order of params accepted by composable
useSchematizedField.paramKeys = ['field', 'hardLock', 'mandatory', 'message', 'schemaId'];
