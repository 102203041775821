import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/subsidies`;
    if (id) url += `/${id}`;
    return url;
  },

  create(subsidy, success) {
    if (!success) return axios.post(this.baseUrl(), { subsidy }).catch(handleError);
    return axios.post(this.baseUrl(), { subsidy }).then(success);
  },

  destroy(subsidyId, success, failure) {
    if (!success) return axios.delete(this.baseUrl(subsidyId)).catch(handleError);
    axios.delete(this.baseUrl(subsidyId)).then(success).catch(failure);
  },

  downloadUrl(id, filename, order) {
    return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf&order=${order}`;
  },

  bulkDownloadUrl(ids, filename) {
    const urlParams = new URLSearchParams({ format: 'zip', filename });

    ids.forEach((id) => {
      urlParams.append('subsidy_ids[]', id);
    });

    return `${this.baseUrl()}?${urlParams}`;
  },

  get(subsidyId, success) {
    if (success) {
      return axios.get(this.baseUrl(subsidyId)).then(success);
    }

    return axios.get(this.baseUrl(subsidyId)).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  update(subsidyId, subsidy, success, failure) {
    if (!success) return axios.patch(this.baseUrl(subsidyId), { subsidy }).catch(handleError);
    return axios.patch(this.baseUrl(subsidyId), { subsidy }).then(success).catch(failure);
  },

  submit(subsidyId, params) {
    return axios.patch(`${this.baseUrl(subsidyId)}/submit`, params).catch(handleError);
  },

  updateIepReferralProgram(subsidyId, params) {
    const url = `${this.baseUrl(subsidyId)}/iep_referral_program`;
    return axios.patch(url, params).catch(handleError);
  },
};
