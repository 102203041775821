import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/organization/claims/${id}`;
    return `${window.api_url}/organization/claims`;
  },

  create(claim) {
    return axios.post(this.baseUrl(), { claim }).catch(handleError);
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  update(id, claim) {
    return axios.patch(this.baseUrl(id), { claim }).catch(handleError);
  },
};
