import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/organization/webpages`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  },

  create(webpage) {
    return axios.post(this.baseUrl(), { webpage }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id));
  },

  get(id) {
    return axios.get(this.baseUrl(id));
  },

  index() {
    return axios.get(this.baseUrl());
  },

  update(id, webpage) {
    return axios.patch(this.baseUrl(id), { webpage }).catch(handleError);
  },

  duplicate(id) {
    return axios.post(`${this.baseUrl(id)}/duplicate`);
  },
};
