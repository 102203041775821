<template>
  <div :class="containerClass">
    <div
      @click="$emit('click')"
      class="d-flex align-center pointer"
    >
      <div>
        <div
          v-if="header"
          v-text="header"
          class="fs-16 d-block"
        />
        <div>
          <span
            v-text="name"
            :class="nameClass"
          />
        </div>
      </div>
      <div class="d-flex justify-end shrink">
        <v-icon
          @click.stop="$emit('remove')"
          class="ms-4"
          color="red"
          data-testid="remove-button"
          size="24"
        >
          close
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  block: {
    type: Boolean,
    default: false,
  },
  dense: {
    type: Boolean,
    default: false,
  },
  header: {
    type: String,
    default: null,
  },
  item: {
    type: Object,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
});

defineEmits(['click', 'remove']);

const containerClass = computed(() => {
  const classes = ['rounded bc-primary b-2 me-3 mb-2'];
  if (props.block) classes.push('w-100pc d-block');
  else classes.push('d-inline-block');

  if (props.dense) classes.push('px-3 py-2');
  else classes.push('px-4 py-3');

  return classes.join(' ');
});

const nameClass = computed(() => {
  if (props.dense) return 'fs-18 fw-500';

  return 'fs-20 fw-500';
});
</script>
