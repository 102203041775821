<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-title>
      {{ $t('Danger') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div class="fs-16 fw-500 mb-4">
        {{ $t('Be careful! Some of these actions cannot be undone.') }}
      </div>

      <div>
        <v-btn
          @click="$emit('destroy')"
          :loading="processing"
          color="red"
          prepend-icon="delete"
        >
          {{ $t('Delete') }}
        </v-btn>

        <v-btn
          v-if="onDuplicate"
          @click="$emit('duplicate')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="content_copy"
        >
          {{ $t('Duplicate') }}
        </v-btn>

        <v-btn
          v-if="publishable && !published"
          @click="$emit('publish')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="publish"
        >
          {{ $t('Publish') }}
        </v-btn>

        <v-btn
          v-if="publishable && published"
          @click="$emit('unpublish')"
          :loading="processing"
          class="mx-2"
          color="primary"
          prepend-icon="move_to_inbox"
          variant="outlined"
        >
          {{ $t('Unpublish') }}
        </v-btn>

        <template v-if="otherActions">
          <v-btn
            v-for="action in otherActions"
            @click="$emit(action.value)"
            :key="action.value"
            :loading="processing"
            class="mx-2"
            color="primary"
            variant="outlined"
          >
            {{ $t(action.text) }}
          </v-btn>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    onDuplicate: [Array, Function],
    otherActions: {
      default: () => [],
      type: Array,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    publishable: {
      type: Boolean,
      default: false,
    },
    published: {
      type: Boolean,
      default: false,
    },
  },

  // TODO: Determine how to manage 'dynamic' emit action names --> action.value
  // emits: ['destroy', 'duplicate', 'publish', 'unpublish']
};
</script>
