<template>
  <FormQuestion
    @back="$emit('back')"
    @click:notes="$emit('click:notes')"
    @next="validateAddress"
    :bold="question.is_bold"
    :class="landscape ? 'my-0' : 'mb-6'"
    :color="color"
    :conditional="question.conditions.length > 0"
    :dense="dense"
    :display-notes="displayNotes"
    :elevation="elevation"
    :header="header"
    :hide-actions="hideActions"
    :landscape="landscape"
    :mandatory="question.mandatory"
    :model-value="modelValue"
    :next-disabled="nextDisabled"
    :notes-count="notesCount"
    :outlined="outlined"
    :paddingless="paddingless"
    :processing="processing || localProcessing"
    :published="question.published"
    :reversible="reversible"
    :show-indicators="showIndicators"
    :subtitle="question.subtitle"
    :tile="tile"
    :title="question.title"
    :very-dense="veryDense"
  >
    <v-row class="d-flex align-end">
      <DefinedPropertyElement
        v-for="attribute in validAddressFields"
        :key="attribute.name"
        :attribute-name="attribute.name"
        :lg="lgForAttribute(attribute)"
        :md="lgForAttribute(attribute)"
        :properties="attributeProperties(attribute.name)"
      >
        <template #default="{ properties }">
          <v-row>
            <LabeledControl
              :message="properties.alias || properties.title"
              mandatory
            >
              <Field
                v-model="modelValue[attribute.name]"
                @change="handleChange('change', $event, attribute)"
                @change:input="handleChange('change:input', $event, attribute)"
                :dense="dense"
                :landscape="landscape"
                :processing="processing"
                :properties="properties"
                :readonly="readonly"
                :very-dense="veryDense"
                condensed
                mandatory
              />
            </LabeledControl>
          </v-row>
        </template>
      </DefinedPropertyElement>
    </v-row>

    <AddressVerificationDialog
      @confirm="updateHomeAddress"
      ref="validateAddressDialog"
      :address-key="addressKey"
      :city-key="cityKey"
      :state-key="stateKey"
      :zip-key="zipKey"
    />
  </FormQuestion>
</template>

<script>
import API from '@/shared/mixins/api';
import AddressVerificationDialog from '@/shared/components/AddressVerificationDialog.vue';
import Field from '@/shared/components/form/Field.vue';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import DefinedPropertyElement from '@/shared/components/DefinedPropertyElement.vue';
import { validateInputAgainstAttributeMask } from '@/shared/services/MaskValidator';

const ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR =
  'Your address could not be validated. Please check for any errors and retry. If you believe this address to be correct, please contact support for assistance.';
const USER_ENTERED_ADDRESS_ATTRIBUTES = ['address', 'city', 'state', 'zip'];

export default {
  compatConfig: { MODE: 3 },

  components: {
    AddressVerificationDialog,
    DefinedPropertyElement,
    Field,
    FormQuestion,
    LabeledControl,
  },

  mixins: [API],

  props: {
    color: {
      default: 'transparent',
      type: String,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    displayNotes: {
      type: Boolean,
      default: false,
    },
    veryDense: {
      type: Boolean,
      default: false,
    },
    elevation: {
      default: 0,
      type: Number,
    },
    expanded: {
      default: false,
      type: Boolean,
    },
    header: {
      type: String,
      default: '',
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideField: {
      type: Boolean,
      default: false,
    },
    showIndicators: {
      type: Boolean,
      default: false,
    },
    landscape: {
      type: Boolean,
      default: false,
    },
    notesCount: {
      type: Number,
      default: 0,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    processing: {
      default: false,
      type: Boolean,
    },
    question: {
      default: () => ({}),
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    reversible: {
      type: Boolean,
      default: true,
    },
    schema: {
      default: () => ({}),
      type: Object,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    modelValue: Object,
  },

  emits: ['back', 'change', 'change:input', 'click:notes', 'next'],

  data() {
    return {
      localProcessing: false,
    };
  },

  computed: {
    nextDisabled() {
      return this.validAddressFields.some((attribute) => {
        let value;

        if (attribute.name?.includes('custom.')) {
          value = this.modelValue.custom[attribute.name.split('.')[1]];
        } else {
          value = this.modelValue[attribute.name];
        }

        if (value == null || value.toString().length === 0) return true;

        const properties = this.attributeProperties(attribute.name);

        if (Array.isArray(value)) {
          if (value.length === 0) return true;

          const itemsProperties = properties?.items?.properties;

          return value.some((item) => {
            const entries = Object.entries(item);

            if (entries.length === 0) return true;
            if (entries.some((value) => value[1] == null || value[1].toString().length === 0))
              return true;

            if (!itemsProperties) return false;

            const invalidEntries = entries.filter((entry) => {
              const itemProperties = itemsProperties[entry[0]];
              const itemValue = entry[1];
              return !validateInputAgainstAttributeMask(itemProperties, itemValue);
            });

            if (invalidEntries.length !== 0) return true;

            return false;
          });
        }

        return !validateInputAgainstAttributeMask(properties, value);
      });
    },

    validAddressFields() {
      // eslint-disable-next-line arrow-body-style
      return USER_ENTERED_ADDRESS_ATTRIBUTES.map((attributeName) => {
        return this.question.synced_attributes.find((attribute) =>
          attribute.name.includes(attributeName),
        );
      });
    },

    attributeKeys() {
      return this.question.synced_attributes.map((attribute) => attribute.name);
    },

    addressKey() {
      return this.extractKey('address');
    },

    stateKey() {
      return this.extractKey('state');
    },

    cityKey() {
      return this.extractKey('city');
    },

    zipKey() {
      return this.extractKey('zip');
    },

    latitudeKey() {
      return this.extractKey('latitude');
    },

    longitudeKey() {
      return this.extractKey('longitude');
    },

    countyKey() {
      return this.extractKey('county');
    },
  },

  methods: {
    attributeProperties(attributeName) {
      return this.schema.properties[attributeName] || {};
    },

    handleChange(emitType, value, attribute) {
      return this.$emit(emitType, {
        attribute,
        value,
      });
    },

    lgForAttribute(attribute) {
      if (attribute.name.includes('address')) return '12';
      if (attribute.name.includes('city')) return '6';
      if (attribute.name.includes('state')) return '3';
      if (attribute.name.includes('zip')) return '3';

      return '6';
    },

    next() {
      this.$emit('next');
    },

    updateHomeAddress(uspsData) {
      const address = uspsData.standardizedAddress;
      this.modelValue[this.addressKey] = address.firstAddressLine;
      this.modelValue[this.cityKey] = address.city;
      this.modelValue[this.stateKey] = address.state;
      this.modelValue[this.zipKey] = address.zipCode;
      this.modelValue[this.latitudeKey] = uspsData.location.latitude;
      this.modelValue[this.longitudeKey] = uspsData.location.longitude;
      this.modelValue[this.countyKey] = uspsData.county;
      this.next();
    },

    extractKey(key) {
      return this.attributeKeys.find((attr) => attr.includes(key));
    },

    async validateAddress() {
      this.localProcessing = true;

      const params = {
        addressLines: [this.modelValue[this.addressKey]],
        administrativeArea: this.modelValue[this.stateKey],
        locality: this.modelValue[this.cityKey],
        postalCode: this.modelValue[this.zipKey],
      };
      const response = await this.api.member.address_validation.validate(params);

      this.localProcessing = false;
      if (!response.data) {
        return this.$eventBus.$emit(
          'chime',
          'Unknown error occurred attempting to validate your address.',
        );
      }

      const { hasUnconfirmedComponents } = response.data.verdict;

      if (hasUnconfirmedComponents) {
        const allSubpremise = response.data.address.addressComponents.every((addressComponent) => {
          if (addressComponent.confirmationLevel === 'CONFIRMED') return true;
          if (addressComponent.componentType === 'subpremise') return true;
          return false;
        });

        if (!allSubpremise)
          return this.$eventBus.$emit('longChime', ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR);
      }

      const { geocode, uspsData } = response.data;

      if (this.validatedAddressMatches(uspsData.standardizedAddress)) {
        this.modelValue[this.latitudeKey] = geocode.location.latitude;
        this.modelValue[this.longitudeKey] = geocode.location.longitude;
        this.modelValue[this.countyKey] = uspsData.county;
        return this.next();
      }

      this.$refs.validateAddressDialog.open({ value: this.modelValue, ...uspsData, ...geocode });

      return true;
    },

    validatedAddressMatches(validated) {
      return (
        this.modelValue[this.zipKey] === validated.zipCode &&
        this.modelValue[this.stateKey].toLowerCase() === validated.state.toLowerCase() &&
        this.modelValue[this.cityKey].toLowerCase() === validated.city.toLowerCase() &&
        this.modelValue[this.addressKey].toLowerCase() === validated.firstAddressLine.toLowerCase()
      );
    },
  },
};
</script>
