<template>
  <v-menu
    v-model="open"
    @update:model-value="$emit('open', $event)"
    :close-on-content-click="true"
    :left="left"
    :right="right"
    data-cy="action-menu"
    data-testid="action-menu"
  >
    <template #activator="menuOptions">
      <button
        v-show="items.length"
        @click.prevent
        v-bind="menuOptions.props"
        :aria-label="$t(label)"
        :class="fullButtonClass"
        :data-cy="buttonDataCy"
        :data-testid="buttonTitle || 'action-menu-button'"
        :disabled="disabled"
      >
        <v-icon
          v-if="(buttonIcon && !buttonIconSide) || (buttonIconSide === 'left' && items.length > 0)"
          :color="buttonColor"
          :size="buttonIconSize"
          :start="buttonIconSide === 'left'"
        >
          {{ buttonIcon }}
        </v-icon>

        <span v-if="buttonTitle">{{ $t(buttonTitle) }}</span>

        <v-icon
          v-if="buttonIcon && buttonIconSide === 'right' && items.length > 0"
          :color="buttonColor"
          :size="buttonIconSize"
          end
        >
          {{ buttonIcon }}
        </v-icon>
      </button>
    </template>

    <v-list
      v-if="items.length > 0"
      class="bc-black b-1 py-0"
      tile
    >
      <div
        v-for="(item, index) in items"
        :key="item.title"
      >
        <v-list-item
          @click="$emit('click:action:' + item.event, item)"
          :aria-label="$t(item.title)"
          :disabled="item.disabled ? true : null"
          :href="item.href"
          :lines="item.subtitle ? 'two' : undefined"
          :subtitle="item.subtitle"
          :title="$t(item.title)"
          :to="item.link"
          data-cy="action-menu-item"
          data-testid="action-menu-item"
          target="_blank"
        >
          <template
            v-if="!!item.avatar"
            #prepend
          >
            <v-avatar :size="avatarWidth(item)">
              <v-icon :size="avatarWidth(item)">
                {{ item.avatar }}
              </v-icon>
            </v-avatar>
          </template>

          <v-list-item-action v-if="!!item.action">
            <v-icon>{{ item.action }}</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="index + 1 < items.length"
          :key="index + '-divider'"
          class="bc-black"
        />
      </div>
    </v-list>
  </v-menu>
</template>

<script setup>
defineEmits([
  'click:action:delete',
  'click:action:edit',
  'click:action:duplicate',
  'click:action:rename',
  'click:action:applicant',
  'click:action:reviewer',
  'click:action:change',
  'click:action:transfer',
  'click:action:withdraw',
  'click:action:continue',
  'click:action:accept',
  'click:action:download',
  'click:action:preview',
  'click:action:update:stage',
  'click:action:destroy',
  'click:action:status',
  'click:action:draft-due-date',
  'click:action:switch',
  'click:action:create',
  'click:action:unsubmit',
  'click:action:release',
  'click:action:publish_to_providers',
  'click:action:publish_to_families',
  'click:action:reset',
  'click:action:run',
  'click:action:go',
  'click:action:move',
  'click:action:footer',
  'click:action:header',
  'click:action:lock',
  'click:action:lock:preferences',
  'click:action:unlock:preferences',
  'click:action:schedule',
  'click:action:seats',
  'click:action:share',
  'click:action:save',
  'click:action:save-preview',
  'open',
]);

const props = defineProps({
  buttonBlock: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: String,
    default: '',
  },
  buttonColor: {
    default: 'black',
    type: String,
  },
  buttonDataCy: {
    type: String,
    default: 'action-menu-button',
  },
  buttonTitle: {
    type: String,
    default: '',
  },
  buttonIcon: {
    default: 'expand_more',
    type: String,
  },
  buttonIconSize: {
    default: 22,
    type: Number,
  },
  buttonIconSide: {
    type: String,
    default: null,
  },
  buttonOutlined: {
    type: Boolean,
    default: false,
  },
  buttonUnderlined: {
    type: Boolean,
    default: false,
  },
  dark: Boolean,
  disabled: {
    type: Boolean,
    default: false,
  },
  hoverUnderline: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array,
    default: null,
  },
  label: {
    type: String,
    default: 'Modify element',
  },
  large: Boolean,
  left: Boolean,
  right: Boolean,
  small: Boolean,
  xLarge: Boolean,
  xSmall: Boolean,
});

const open = ref(false);

const fullButtonClass = computed(() => {
  const classes = [];
  if (props.buttonColor) {
    classes.push(`c-${props.buttonColor}`);
    classes.push(`bc-${props.buttonColor}`);
  }

  if (props.xLarge) {
    classes.push('fs-20');
  } else if (props.large) {
    classes.push('fs-18');
  } else if (props.small) {
    classes.push('fs-14');
  } else if (props.xSmall) {
    classes.push('fs-12');
  } else {
    classes.push('fs-16');
  }

  if (props.xLarge) {
    classes.push('px-8 py-4');
  } else if (props.large) {
    classes.push('px-5 py-3');
  } else if (props.small) {
    classes.push('px-2 py-2');
  } else {
    classes.push('px-4 py-2');
  }

  if (props.buttonOutlined) classes.push('b-1');

  if (props.buttonUnderlined) {
    classes.push('bb-1');
  }

  if (props.buttonBlock) {
    classes.push('w-100pc mb-2');
  }

  if (props.hoverUnderline) {
    classes.push('hover-underline');
  }

  classes.push(props.buttonClass);
  classes.push('action-menu-button');
  return classes.join(' ');
});

function avatarWidth(item) {
  return item?.subtitle ? 25 : 20;
}
</script>

<style scoped>
.v-btn:not(.v-btn--round).x-x-large {
  height: 70px !important;
  min-width: 120px !important;
  font-size: 20px;
}

.action-menu-button.hover-underline span:hover {
  text-decoration: underline !important;
}

:deep(.v-list-item-subtitle) {
  line-height: 1.5rem;
}
</style>
