import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/manager/access_requests/${id}`;
    return `${window.api_url}/manager/access_requests`;
  },

  create(resource) {
    return axios.post(this.baseUrl(), { access_request: resource }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  index() {
    return axios.get(this.baseUrl()).catch(handleError);
  },
};
