<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="fs-20 fw-600">
      {{ $t(`About this ${terms.program.toLowerCase()}`) }}
    </v-card-title>
    <v-card-text>
      <div
        v-if="$store.state.config.enable_automatic_translations"
        :key="locale"
        v-translate="provider.description || 'No description available'"
        class="c-black fs-16 fw-400 ws-pre-line"
      />
      <div
        v-else
        class="c-black fs-16 fw-400 ws-pre-line"
      >
        {{ $t(provider.description || 'No description available') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import useTerms from '@/shared/composables/useTerms';
import { useI18n } from 'vue-i18n';

defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const { locale } = useI18n();
const { terms } = useTerms();
</script>
