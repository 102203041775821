import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  onDemandAuthorization: {
    async create() {
      return axios.post(`${window.api_url}/dwolla/on_demand_authorizations`).catch(handleError);
    },
  },
};
