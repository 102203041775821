import _ from 'lodash';

const isJsonApiResponse = (response) => _.has(response, 'data.data');

const denormalizeRelationships = ({ relationships, included }) => {
  const denormalizeRelationship = (relationship) => {
    if (_.isEmpty(relationship)) return {};

    const include =
      _.find(included, (item) => item.type === relationship.type && item.id === relationship.id) ||
      {};
    return _.omit({ ...relationship, ...include.attributes }, ['type']);
  };

  const normalizedRelationships = _.chain(relationships)
    .mapKeys((_value, key) => _.camelCase(key))
    .mapValues((relationship) =>
      _.isArray(relationship.data)
        ? _.map(relationship.data, denormalizeRelationship)
        : denormalizeRelationship(relationship.data),
    )
    .value();
  return normalizedRelationships;
};

const denormalizeResponseDatum = ({ datum, included }) => {
  const withoutMetadataProperties = _.omit(datum, ['attributes', 'relationships', 'type']);
  const normalizedRelationships = denormalizeRelationships({
    relationships: datum.relationships,
    included,
  });
  return {
    ...withoutMetadataProperties,
    ...datum.attributes,
    ...normalizedRelationships,
  };
};

export const denormalizeResponse = (response) => {
  if (!isJsonApiResponse(response)) return response;

  const {
    data: { data, included },
  } = response;
  const normalizedData = _.isArray(data)
    ? _.map(data, (datum) => denormalizeResponseDatum({ datum, included }))
    : denormalizeResponseDatum({ datum: data, included });
  return { data: normalizedData, headers: response.headers };
};
