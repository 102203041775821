import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/manager/ownership_requests/${id}`;
    return `${window.api_url}/manager/ownership_requests`;
  },

  create(resource) {
    return axios.post(this.baseUrl(), { ownership_request: resource }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },
};
