<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(video, index) in videos"
          :key="video.id"
          cols="12"
          md="4"
        >
          <v-card
            flat
            tile
          >
            <video
              :aria-label="`${$t('Virtual tour number')} ${index + 1}`"
              class="w-100pc"
              controls
            >
              <source :src="video.url" />
            </video>

            <template v-if="removable">
              <v-btn
                @click="destroy(video)"
                color="red"
                block
                tile
              >
                {{ $t('Remove') }}
              </v-btn>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['progress']);
const { t } = useI18n();

const props = defineProps({
  removable: {
    type: Boolean,
    default: false,
  },
  providerId: {
    type: String,
    default: null,
  },
});

const videos = ref([]);

watch(
  () => props.providerId,
  () => {
    videos.value = [];
    load();
  },
  { immediate: true },
);

function destroy(video) {
  if (!confirm(t('Are you certain you want to remove this video?'))) return;

  api.manager.provider.asset.destroy(props.providerId, video.id, () => load());
}

function load() {
  if (!props.providerId) return;

  api.public_api.provider.asset.index(props.providerId, { category: 'video' }, (resp) => {
    videos.value = resp.data;
    emit('progress', videos.value.length > 0 ? 100 : 0);
  });
}
</script>
