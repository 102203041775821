<template>
  <div>
    <span
      v-for="(item, index) in itemStrings"
      :key="index"
      :class="classes"
    >
      <span>{{ $t(item) }}</span>
      <span
        v-if="index < itemStrings.length - 1"
        class="me-1"
      >
        {{ ',' }}
      </span>
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
  classes: {
    type: String,
    default: null,
  },
  items: {
    type: [Array, String],
    default: null,
  },
});

const itemStrings = computed(() => {
  return typeof props.items === 'string' ? [props.items] : props.items;
});
</script>
