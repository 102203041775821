/**
 * Stepper composable to manage the progress of the user through a Subsidy application.
 *
 * @param processing
 * @param route
 *
 * @returns {{
 * progress
 * section
 * step
 * stepBack
 * stepForward
 * stepCount
 * stepTotal
 * transitionName
 * }}
 *
 */
export default function useStepper({ processing, route, updateQuery }) {
  const progress = ref(2);
  const stepCount = ref(1);
  const stepTotal = ref(50);
  const section = ref(null);
  const transitionName = ref('slide-left');

  function progressBackward() {
    stepCount.value -= 1;
  }

  function progressForward() {
    stepCount.value += 1;
  }

  async function step(newSection, params = {}) {
    section.value = newSection;

    const programId = route.query?.programId;
    progress.value = (stepCount.value / stepTotal.value) * 100;
    await updateQuery(
      Object.assign(params, { section: section.value, step: stepCount.value, programId }),
    );
    processing.value = false;
  }

  function stepBack(section, params) {
    progressBackward();
    transitionName.value = 'slide-right';
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    step(section, params);
  }

  function stepForward(section, params) {
    progressForward();
    transitionName.value = 'slide-left';
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    step(section, params);
  }

  return {
    progress,
    section,
    step,
    stepBack,
    stepForward,
    stepCount,
    stepTotal,
    transitionName,
  };
}
