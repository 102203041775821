import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(subsidyProgramId) {
    return `${window.api_url}/member/subsidy_programs/${subsidyProgramId}/seat_types`;
  },

  index(subsidyProgramId, params) {
    return axios.get(this.baseUrl(subsidyProgramId), { params }).catch(handleError);
  },
};
