import axios from 'axios';
import baseApi from '@/shared/services/bright_finder';
import deepmerge from 'deepmerge';
import { asyncApi, handleError } from '@/shared/services/api';

axios.defaults.withCredentials = true;
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.headers.patch.Accept = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';

const adminApi = {
  admin: {
    api_key: {
      baseUrl(id) {
        let url = `${window.api_url}/admin/api_keys`;
        if (id) {
          url += `/${id}`;
        }
        return url;
      },

      create(apiKey, success, failure) {
        axios.post(this.baseUrl(), { api_key: apiKey }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(params, success) {
        axios.get(this.baseUrl(), { params }).then(success);
      },

      update(id, apiKey, success, failure) {
        axios.patch(this.baseUrl(id), { api_key: apiKey }).then(success).catch(failure);
      },
    },

    api_permission: {
      baseUrl() {
        return `${window.api_url}/admin/api_permissions`;
      },

      index(success) {
        axios.get(this.baseUrl()).then(success);
      },
    },

    course_group: {
      baseUrl(id) {
        let url = `${window.api_url}/admin/course_groups`;
        if (id) {
          url += `/${id}`;
        }
        return url;
      },

      create(course_group, success, failure) {
        axios.post(this.baseUrl(), { course_group }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(success) {
        axios.get(this.baseUrl()).then(success);
      },

      update(id, course_group, success, failure) {
        axios.patch(this.baseUrl(id), { course_group }).then(success).catch(failure);
      },
    },

    member: {
      baseUrl() {
        return `${window.api_url}/admin/members`;
      },

      create(member, success, failure) {
        axios.post(this.baseUrl(), { member }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success);
      },

      get(id, success, failure) {
        axios.get(`${this.baseUrl()}/${id}`).then(success).catch(failure);
      },

      index(success, failure) {
        axios.get(this.baseUrl()).then(success).catch(failure);
      },

      update(id, member, success, failure) {
        if (!success) return axios.patch(`${this.baseUrl()}/${id}`, { member });

        return axios.patch(`${this.baseUrl()}/${id}`, { member }).then(success).catch(failure);
      },
    },

    organization: {
      base_url() {
        return `${window.api_url}/admin/organization`;
      },

      get(success) {
        if (success) {
          return axios.get(this.base_url()).then(success);
        }
        return axios.get(this.base_url());
      },

      update(organization, success, failure) {
        if (success) {
          return axios.patch(this.base_url(), { organization }).then(success).catch(failure);
        }
        return axios.patch(this.base_url(), { organization });
      },
    },
  },

  organization: {
    brand: asyncApi({
      endpoint: '/organization/brands',
      payloadKey: 'brand',
    }),

    dashboard: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/organization/dashboards/${id}`;
        }
        return `${window.api_url}/organization/dashboards`;
      },

      create(dashboard, success, failure) {
        axios.post(this.baseUrl(), dashboard).then(success).catch(failure);
      },

      destroy(dashboardId, success) {
        axios.delete(this.baseUrl(dashboardId)).then(success);
      },

      get(dashboardId, success) {
        axios.get(this.baseUrl(dashboardId)).then(success);
      },

      index(success) {
        axios.get(this.baseUrl()).then(success);
      },

      update(id, dashboard, success, failure) {
        axios.patch(this.baseUrl(id), { dashboard }).then(success).catch(failure);
      },
    },

    metric: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/organization/metrics/${id}`;
        }
        return `${window.api_url}/organization/metrics`;
      },

      create(metric, success, failure) {
        axios.post(this.baseUrl(), metric).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      update(id, metric, success, failure) {
        axios.patch(this.baseUrl(id), { metric }).then(success).catch(failure);
      },
    },

    profile: {
      base_url() {
        return `${window.api_url}/organization/profile`;
      },

      get(success) {
        const params = {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
          withCredentials: true,
        };
        if (!success) return axios.get(this.base_url(), params).catch(handleError);
        return axios.get(this.base_url(), params).then(success);
      },

      update(profile, success, failure) {
        axios.patch(this.base_url(), { profile }).then(success).catch(failure);
      },
    },

    question: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/organization/questions/${id}`;
        }
        return `${window.api_url}/organization/questions`;
      },

      create(question, success, failure) {
        axios.post(this.baseUrl(), question).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(params, success) {
        axios.get(this.baseUrl(), { params }).then(success);
      },

      update(id, question, success, failure) {
        axios.patch(this.baseUrl(id), { question }).then(success).catch(failure);
      },
    },

    siteTemplate: {
      baseUrl(id = null) {
        let url = `${window.api_url}/organization/site_templates`;
        if (id) url += `/${id}`;
        return url;
      },

      create(site_template, success, failure) {
        axios.post(this.baseUrl(), site_template).then(success).catch(failure);
      },

      pageTemplate: {
        url(siteTemplateId, pageTemplateId) {
          if (pageTemplateId) {
            return `${window.api_url}/organization/site_templates/${siteTemplateId}/page_templates/${pageTemplateId}`;
          }
          return `${window.api_url}/organization/site_templates/${siteTemplateId}/page_templates`;
        },

        create(siteTemplateId, page_template, success, failure) {
          axios.post(this.url(siteTemplateId), { page_template }).then(success).catch(failure);
        },

        destroy(siteTemplateId, pageTemplateId, success) {
          axios.delete(this.url(siteTemplateId, pageTemplateId)).then(success);
        },

        index(siteTemplateId, success) {
          axios.get(this.url(siteTemplateId)).then(success);
        },

        get(siteTemplateId, pageTemplateId, success) {
          axios.get(this.url(siteTemplateId, pageTemplateId)).then(success);
        },

        update(siteTemplateId, pageTemplateId, page_template, success, failure) {
          axios
            .patch(this.url(siteTemplateId, pageTemplateId), { page_template })
            .then(success)
            .catch(failure);
        },

        updateOrCreate(siteTemplateId, page_template, success, failure) {
          if (page_template.id) {
            this.update(siteTemplateId, page_template.id, page_template, success, failure);
          } else {
            this.create(siteTemplateId, page_template, success, failure);
          }
        },
      },

      destroy(siteTemplateId, success) {
        axios.delete(this.baseUrl(siteTemplateId)).then(success);
      },

      get(siteTemplateId, success) {
        axios.get(this.baseUrl(siteTemplateId)).then(success);
      },

      async index(params = {}, successCallback = null, failureCallback = null) {
        return axios
          .get(this.baseUrl(), { params })
          .then((response) => {
            if (successCallback) successCallback(response);
            return response;
          })
          .catch((error) => {
            if (failureCallback) return failureCallback(error);
            return handleError(error);
          });
      },

      update(siteTemplateId, site_template, success, failure) {
        axios.patch(this.baseUrl(siteTemplateId), { site_template }).then(success).catch(failure);
      },

      updateOrCreate(site_template, success, failure) {
        if (site_template.id) {
          this.update(site_template.id, site_template, success, failure);
        } else {
          this.create(site_template, success, failure);
        }
      },
    },
  },

  upload: {
    base_url() {
      return `${window.api_url}/organization/uploads`;
    },

    create(upload, success, failure) {
      axios.post(this.base_url(), { upload }).then(success).catch(failure);
    },
  },

  registration: {
    create(registration, success, failure) {
      axios.post(`${window.api_url}/registrations`, { registration }).then(success).catch(failure);
    },
  },
};

export default deepmerge.all([baseApi, adminApi]);
