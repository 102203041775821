export default function attrsOmitDataPrefix(attrs) {
  return Object.entries(attrs).reduce((acc, [key, value]) => {
    if (key.startsWith('data-')) {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});
}
