<template>
  <div>
    <!-- Vuetify 3 changed 'outlined' to 'border' -->
    <v-card
      v-for="(att, index) in attachments"
      :key="att.id"
      :border="outlined"
      :class="[cardClass, index + 1 < attachments.length ? 'mb-2' : 'mb-0'].join(' ')"
      elevation="0"
      tile
    >
      <v-card-text :class="dense || veryDense ? 'pa-2' : 'pa-4'">
        <AttachmentRow
          @change:note="setNote(att, $event)"
          @change:status="setStatus(att, $event)"
          @remove="removeAttachment(att)"
          :att="att"
          :dense="dense"
          :removable="!hideRemove && removeAllowed(att)"
          :status="status"
        />
      </v-card-text>
    </v-card>
    <template v-if="emptyLabel && attachments.length === 0">
      <p class="fs-16 c-light-black mt-2 mb-0">
        {{ $t(emptyLabel) }}
      </p>
    </template>
  </div>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import AttachmentRow from '@/shared/components/attachments/AttachmentRow.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  attachments: {
    default: () => [],
    type: Array,
  },
  dense: {
    default: false,
    type: Boolean,
  },
  emptyLabel: {
    default: null,
    type: String,
  },
  hideRemove: {
    default: false,
    type: Boolean,
  },
  outlined: {
    default: true,
    type: Boolean,
  },
  confidential: {
    default: false,
    type: Boolean,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  status: {
    type: Boolean,
    default: false,
  },
  veryDense: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change', 'delete']);

const role = computed(() => store.state.role);
const isSpecialist = computed(() => role.value === 'specialist');
const isManager = computed(() => role.value === 'manager');

const cardClass = computed(() => {
  if (props.dense || props.veryDense) {
    return 'mb-0';
  }
  return 'mb-3';
});

function getDestroyApi(attachment) {
  if (isSpecialist.value) return Api.organization;
  if (isManager.value && !!attachment.child_id) return Api.manager;
  return Api.member;
}

async function removeAttachment(attachment) {
  const destroyApi = getDestroyApi(attachment);

  const resp = await destroyApi.attachment.destroy(attachment.id);
  if (resp?.status !== 200) return;
  emit('delete');
}

async function setNote(att, val) {
  await updateAttachment({ id: att.id, note: val });
}

async function setStatus(att, val) {
  await updateAttachment({ id: att.id, status: val });
}

async function updateAttachment(att) {
  const updateApi = isSpecialist.value ? Api.organization : Api.member;

  const resp = await updateApi.attachment.update(att.id, att);
  if (resp?.status !== 200) return;

  emit('change');
}

function removeAllowed(att) {
  if (isManager.value && !!att.child_id) return att.member_id === store.state.profile.id;
  return true;
}
</script>
