<template>
  <div />
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const { locale } = useI18n();
const router = useRouter();
const store = useStore();

onMounted(signOut);

async function signOut() {
  await Api.member.session.promiseDestroy();
  if (store.state.profile?.is_parent) {
    store.commit('setAuthenticated', false);
  }
  store.commit('setProfile', null);
  router.replace({ path: '/', query: { lang: locale } });
}
</script>
