<template>
  <div v-if="program">
    <div class="fs-16 fw-500 mb-2">
      <span>{{ $t(program.name) }}</span>
      <PublicNotice
        v-if="program.public_notice"
        :public-notice="program.public_notice"
        class="pt-1"
        small
      />
    </div>

    <v-row
      v-if="program.age_max || program.grade_max"
      class="d-flex align-center"
      dense
    >
      <v-col
        class="w-40"
        cols="auto"
      >
        <v-icon
          color="primary"
          icon="child_care"
          size="24"
        />
      </v-col>
      <v-col>
        <div
          v-if="program.grade_max"
          class="fs-16 fw-400"
        >
          {{ $t('Grades') }}: {{ $a.assets.programs.grades[program.grade_min].text }} -
          {{ $a.assets.programs.grades[program.grade_max].text }}
        </div>
        <div
          v-if="ageMaxText"
          class="fs-16 fw-400"
        >
          {{ $t('Ages') }}: {{ ageMinText }} - {{ ageMaxText }}
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="program.season || (program.hours_description && program.hours_description.length > 0)"
      class="d-flex align-center"
      dense
    >
      <v-col
        class="w-40"
        cols="auto"
      >
        <v-icon
          color="primary"
          icon="date_range"
          size="24"
        />
      </v-col>
      <v-col>
        <div
          v-if="program.season"
          v-text="$t(program.season)"
        />
        <HoursChunks :chunks="program.hours_chunks" />
      </v-col>
    </v-row>

    <v-row
      v-if="hasRates"
      class="d-flex align-center"
      dense
    >
      <v-col
        class="w-40"
        cols="auto"
      >
        <v-icon
          color="primary"
          icon="monetization_on"
          size="24"
        />
      </v-col>
      <v-col>
        <div
          v-if="program.rate_hourly"
          class="fs-16 fw-400"
        >
          {{ currency(program.rate_hourly) }} {{ $t('paid hourly') }}
        </div>
        <div
          v-if="program.rate_daily"
          class="fs-16 fw-400"
        >
          {{ currency(program.rate_daily) }} {{ $t('paid daily') }}
        </div>
        <div
          v-if="program.rate_weekly"
          class="fs-16 fw-400"
        >
          {{ currency(program.rate_weekly) }} {{ $t('paid weekly') }}
        </div>
        <div
          v-if="program.rate_monthly"
          class="fs-16 fw-400"
        >
          {{ currency(program.rate_monthly) }} {{ $t('paid monthly') }}
        </div>
        <div
          v-if="program.rate_bimonthly"
          class="fs-16 fw-400"
        >
          {{ currency(program.rate_bimonthly) }} {{ $t('paid twice a month') }}
        </div>
        <div
          v-if="program.rate_biweekly"
          class="fs-16 fw-400"
        >
          {{ currency(program.rate_biweekly) }} {{ $t('paid every two weeks') }}
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="!hasRates && program.rate"
      class="d-flex align-center"
      dense
    >
      <v-col
        class="w-40"
        cols="auto"
      >
        <v-icon
          color="primary"
          icon="monetization_on"
          size="24"
        />
      </v-col>
      <v-col>
        <div class="fs-16 fw-400">
          {{ currency(program.rate) }}
          {{ program.period ? [$t('paid'), $t(program.period).toLowerCase()].join(' ') : '' }}
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="program.contact_name"
      class="d-flex align-center"
      dense
    >
      <v-col
        class="w-40"
        cols="auto"
      >
        <v-icon
          color="primary"
          icon="person_pin"
        />
      </v-col>
      <v-col>
        <div class="fs-16 fw-400">{{ $t('Contact') }} {{ program.contact_name }}</div>
        <div
          v-text="[program.contact_email, program.contact_phone].filter((item) => item).join(' | ')"
          class="fs-16 fw-400"
        />
      </v-col>
    </v-row>

    <v-row v-if="showAvailability">
      <SeatsAvailability
        :program="program"
        :show-tooltip="true"
      />
    </v-row>

    <v-row
      v-if="program.description"
      dense
    >
      <v-col>
        <div
          @click="descriptionVisible = !descriptionVisible"
          id="view_program_description_link"
          class="c-primary underlined pointer fs-16 fw-400"
          tracked
        >
          {{ $t('View program description') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="descriptionVisible">
      <v-col>
        <div
          v-text="program.description"
          class="fs-16 fw-400"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { currency } from '@/plugins/filters';
import HoursChunks from '@/public/components/provider/HoursChunks.vue';
import PublicNotice from '@/shared/components/PublicNotice.vue';
import SeatsAvailability from '@/shared/components/SeatsAvailability.vue';
import useAgeCalculations from '@/shared/composables/useAgeCalculations';

const { monthsToAgeString } = useAgeCalculations();

const props = defineProps({
  program: {
    type: Object,
    default: null,
  },
  showAvailability: {
    type: Boolean,
    default: false,
  },
});

const descriptionVisible = ref(false);

const ageMaxText = computed(() => monthsToAgeString(props.program.age_max));
const ageMinText = computed(() => monthsToAgeString(props.program.age_min));
const hasRates = computed(() => {
  return (
    props.program.rate_hourly ||
    props.program.rate_daily ||
    props.program.rate_weekly ||
    props.program.rate_monthly ||
    props.program.rate_bimonthly ||
    props.program.rate_biweekly
  );
});
</script>
