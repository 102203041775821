import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/manager/seats`;
  },

  create(seat) {
    return axios.post(this.baseUrl(), { seat }).catch(handleError);
  },

  destroy(data) {
    return axios.delete(this.baseUrl(), { data }).catch(handleError);
  },
};
