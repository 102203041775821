import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/schema_shares`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(toSchemaId, fromSchemaId) {
    return axios
      .post(this.baseUrl(), { to_id: toSchemaId, from_id: fromSchemaId })
      .catch(handleError);
  },

  async index(toSchemaId) {
    return axios.get(this.baseUrl(), { to_id: toSchemaId });
  },

  async destroy(schemaShare) {
    return axios.delete(this.baseUrl(schemaShare.id)).catch(handleError);
  },
};
