/**
 * @deprecated This mixin is replaced by useStepper.js
 * @example ```vue
 * <script setup>
 * import useStepper from '@/shared/composables/useStepper';
 *
 * const { calculateStepTotal, progress, section, step, stepBack, stepFoward, stepCount, stepTotal, transitionName } = useStepper({ progress, route });
 * </script>
 * ```
 */

import RouterHelper from '@/shared/mixins/RouterHelper';

export default {
  mixins: [RouterHelper],

  data() {
    return {
      progress: 2,
      progressBeforeFinish: null,
      stepCount: 1,
      stepTotal: 50,
      section: null,
      transitionName: 'slide-left',
    };
  },

  methods: {
    async backFromFinish() {
      this.processing = true;
      await this.validate();
      this.progress = this.progressBeforeFinish;
      this.step_back(this.sectionBeforeFinish());
    },

    calculateStepTotal() {},

    progressBackward() {
      this.stepCount -= 1;
    },

    progressForward() {
      this.stepCount += 1;
    },

    async step(section, params = {}) {
      this.processing = false;
      this.section = null;

      const programId = this.$route.query?.programId;
      await this.updateQuery(Object.assign(params, { section, step: this.stepCount, programId }));
    },

    step_back(section, params) {
      this.progressBackward();
      this.transitionName = 'slide-right';
      this.step(section, params);
    },

    step_forward(section, params) {
      this.progressForward();
      this.transitionName = 'slide-left';
      this.step(section, params);
    },
  },
};
