<template>
  <v-dialog
    v-model="isVisible"
    :max-width="maxWidth"
  >
    <v-card
      data-cy="decision-dialog"
      border
      flat
      tile
    >
      <v-card-title>
        <div
          class="fs-20 fw-500 text-wrap"
          data-cy="decision-dialog-title"
        >
          {{ $t(title) }}
        </div>
      </v-card-title>
      <v-divider v-if="title" />

      <v-card-text class="py-5">
        <p
          class="fs-20 fw-500"
          data-cy="decision-dialog-content"
        >
          {{ $t(content) }}
        </p>
      </v-card-text>
      <v-divider v-if="content" />

      <v-card-actions
        :class="[
          $vuetify.display.smAndDown ? 'd-flex flex-column mb-6' : 'd-flex flex-row justify-end',
          'py-4',
        ]"
      >
        <v-btn
          @click="cancel"
          :class="[
            $vuetify.display.smAndDown ? 'btn-small' : 'btn-large',
            'mt-2',
            'me-3',
            'mb-4',
            'focus-very-visible',
            'd-flex',
          ]"
          :loading="processing"
          color="primary"
          data-cy="decision-cancel-button"
          variant="outlined"
        >
          {{ $t(cancelButtonText) }}
        </v-btn>

        <v-btn
          @click="$emit('confirm')"
          :class="[
            $vuetify.display.smAndDown ? 'btn-small' : 'btn-large',
            'me-2',
            'focus-very-visible',
            'd-flex',
          ]"
          :loading="processing"
          color="primary"
          data-cy="decision-confirm-button"
          data-testid="decision-confirm-button"
          variant="outlined"
        >
          <span
            v-t="confirmButtonText"
            class="d-flex"
          />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
defineExpose({ open, close });

defineProps({
  cancelButtonText: {
    type: String,
    default: 'Cancel',
  },
  confirmButtonText: {
    type: String,
    default: null,
  },
  content: {
    type: String,
    default: null,
  },
  maxWidth: {
    default: 600,
    type: Number,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['cancel', 'confirm']);

const isVisible = ref(false);

function cancel() {
  emit('cancel');
  close();
}

function close() {
  isVisible.value = false;
}

function open() {
  isVisible.value = true;
}
</script>
<style scoped>
.btn-small {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.btn-large {
  font-size: 1.125rem;
  padding: 0.75rem 1.5rem;
}

@media (max-width: 37.5rem) {
  .me-3 {
    margin-right: 0 !important;
  }
  .btn-small {
    padding: 0.1rem;
    font-size: 1rem;
  }
}
</style>
