import api from '@/shared/services/bright_finder';
import LinkedList from '@/shared/services/linked-list';

const BASE_VALUE = { assertion: { conditions: [], value: {} } };

/**
 * Use, organize, and validate questions from a specified owner
 * (note: similar to subsidy and family subsidy utils, but non-specific to owner
 *        potentially consider combining behaviors if possible)
 *
 * @param {
 *  ownerDataType
 *  ownerId
 *  displayAll
 *  loadQuestionsCallback
 *  syncedObject
 * } param0
 *
 * @returns {{
 *  questions: array
 *  questionsIncomplete: boolean (ref)
 *  questionsLoaded: boolean (ref)
 *  validVerificationQuestions: array
 *  validEligibilityQuestions: array
 *  validOtherQuestions: array
 *  validQuestions: array
 *  validPublishedQuestions: array
 *  validateAnswers: array
 * }}
 */
export default function useQuestionable({
  ownerDataType,
  ownerId,
  displayAll = false,
  loadQuestionsCallback,
  syncedObject,
}) {
  const questions = ref([]);
  const questionsLoaded = ref(false);

  watch([ownerDataType, ownerId, syncedObject], async () => {
    if (ownerDataType && ownerId) await loadQuestions();
  });

  const questionsIncomplete = computed(() => {
    if (!syncedObject.value) return false;

    const mandatoryQuestions = validQuestions.value.filter(
      (question) => !question.is_section && question.mandatory,
    );

    const incompleteMandatoryQuestions = mandatoryQuestions.filter((question) =>
      question.synced_attributes.some((syncedAttribute) => {
        const attrParts = syncedAttribute.name.split('.');
        let value;

        if (attrParts[0] === 'custom') {
          value = syncedObject.value.custom[attrParts[attrParts.length - 1]];
        } else {
          value = syncedObject.value[attrParts[attrParts.length - 1]];
        }

        return value === null || value === undefined || value === '';
      }),
    );
    return incompleteMandatoryQuestions.length > 0;
  });

  const validVerificationQuestions = computed(() => {
    return questions.value
      .filter((question) => question.verification)
      .filter((question) => displayAll || (question.published && question.valid));
  });

  const validEligibilityQuestions = computed(() => {
    return LinkedList.sort(questions.value.filter((question) => question.eligibility)).filter(
      (question) => displayAll || (question.published && question.valid),
    );
  });

  const validOtherQuestions = computed(() => {
    return LinkedList.sort(questions.value.filter((question) => !question.eligibility)).filter(
      (question) => displayAll || (question.published && question.valid),
    );
  });

  const validQuestions = computed(() => {
    return LinkedList.sort(questions.value).filter((question) => question.valid);
  });

  const validPublishedQuestions = computed(() => {
    return LinkedList.sort(questions.value).filter(
      (question) => question.published && question.valid,
    );
  });

  async function loadQuestions() {
    const resp = await api.public_api.organization.question.index({
      owner_id: ownerId.value,
      owner_type: ownerDataType.value,
    });
    questions.value = LinkedList.sort(resp.data);
    questionsLoaded.value = true;

    await validateAnswers();
    if (loadQuestionsCallback) loadQuestionsCallback();
  }

  async function validateAnswers() {
    const assertions = questions.value.map((question) => {
      if (question.conditions.length == 0) return BASE_VALUE;
      return { assertion: { conditions: question.conditions, value: syncedObject.value } };
    });

    const { data } = await api.public_api.assertion.promiseBulkCreate(assertions);
    const { results } = data;

    if (results.length == 0) return;
    questions.value.forEach((question, index) => {
      question.valid = results[index].result;
    });
  }

  return {
    loadQuestions,
    questions,
    questionsIncomplete,
    questionsLoaded,
    validVerificationQuestions,
    validEligibilityQuestions,
    validOtherQuestions,
    validQuestions,
    validPublishedQuestions,
    validateAnswers,
  };
}
