import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) {
      return `${window.api_url}/organization/forms/${id}`;
    }
    return `${window.api_url}/organization/forms`;
  },

  create(resource) {
    return axios.post(this.baseUrl(), { form: resource }).catch(handleError);
  },

  destroy(id, success) {
    if (!success) return axios.delete(this.baseUrl(id)).catch(handleError);
    return axios.delete(this.baseUrl(id)).then(success);
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id)).catch(handleError);
    return axios.get(this.baseUrl(id)).then(success);
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters });
    return axios.get(this.baseUrl(), { params: filters }).then(success);
  },

  indexVersion2(filters) {
    const baseUrl = `${window.api_url}/v2/forms`;
    return axios.get(baseUrl, { params: filters }).catch(handleError);
  },

  update(id, resource, success, failure) {
    if (!success) return axios.patch(this.baseUrl(id), { form: resource }).catch(handleError);
    return axios.patch(this.baseUrl(id), { form: resource }).then(success).catch(failure);
  },
};
