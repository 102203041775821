export class EventSummary {
  constructor(event) {
    this.event = event;
    this.target = this.nearestTarget(this.event.target);
  }

  asJson() {
    if (this.target) {
      return {
        meta: this.meta(),
        type: this.event.type,
        target: this.targetData(),
      };
    }
  }

  meta() {
    if (this.target.attributes['data-meta']) {
      return JSON.parse(this.target.attributes['data-meta'].value);
    }
    return null;
  }

  nearestTarget(target) {
    if (target.attributes.tracked) return target;
    if (target.parentElement) return this.nearestTarget(target.parentElement);

    return null;
  }

  targetData() {
    return { id: this.target.id, tid: this.target.dataset.tid, href: this.target.href };
  }
}
