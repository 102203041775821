import { inject } from 'vue';

export const SEGMENT_PROVIDE_KEY = 'segment';

export default function useSegment() {
  const segment = inject(SEGMENT_PROVIDE_KEY);

  if (!segment) {
    throw new Error(
      'Segment was not provided in a parent component. Ensure that a Segment instance is made available via provide() in the App.',
    );
  }

  return segment;
}
