import axios from 'axios';
import _ from 'lodash';

import { handleError } from '@/shared/services/api';
import { denormalizeResponse } from './json-api';

const normalizeRequest = (folder) => ({
  data: {
    attributes: {
      ..._.pick(folder, ['name']),
    },
    relationships: {
      reports: { data: _.map(folder.reports, (report) => ({ type: 'reports', id: report.id })) },
      teams: { data: _.map(folder.teams, (team) => ({ type: 'teams', id: team.id })) },
    },
  },
});

export default {
  baseUrl() {
    return `${window.api_url}/organization/report_folders`;
  },

  async create(folder) {
    const response = await axios.post(this.baseUrl(), normalizeRequest(folder)).catch(handleError);
    return denormalizeResponse(response);
  },

  async index(params = {}) {
    const response = await axios.get(this.baseUrl(), { params }).catch(handleError);
    return denormalizeResponse(response);
  },

  async update(folder) {
    const response = await axios
      .patch(`${this.baseUrl()}/${folder.id}`, normalizeRequest(folder))
      .catch(handleError);
    return denormalizeResponse(response);
  },

  async destroy(folder) {
    return axios.delete(`${this.baseUrl()}/${folder.id}`).catch(handleError);
  },
};
