import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/public/organizations/${window.organization_id}/questions`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id));
    axios.get(this.baseUrl(id)).then(success);
  },

  index(params, success) {
    if (success) return axios.get(this.baseUrl(), { params }).then(success);
    return axios.get(this.baseUrl(), { params }).catch(handleError);
  },
};
