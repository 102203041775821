import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/bank_account`;
  },

  async get() {
    return axios.get(this.baseUrl());
  },

  async update(bankAccount) {
    return axios.patch(this.baseUrl(), { bank_account: bankAccount }).catch(handleError);
  },
};
