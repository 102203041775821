import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/manager/operating_logs`;
    if (id) url += `/${id}`;
    return url;
  },

  downloadUrl(id, filename) {
    return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf`;
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  update(id, operatingLog) {
    return axios.patch(this.baseUrl(id), { operating_log: operatingLog }).catch(handleError);
  },
};
