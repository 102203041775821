export default function handleNativeClickEvent(evt) {
  const closestButton = evt.target.closest('button');

  if (!closestButton) return;

  if (closestButton.attributes['data-skip-event']) return;

  const text = (closestButton.textContent || closestButton.innerText || '').trim();

  window.analytics?.track?.('Button Clicked', {
    text,
  });
}
