<template>
  <v-card
    :class="cardClass"
    :variant="variant"
    class="ta-center pa-8"
    data-cy="null-state"
    data-testid="null-state-card"
    tile
  >
    <v-img
      :max-height="imageHeight"
      :src="src"
      class="mx-auto mb-8 mxw-300"
    />

    <div
      v-if="title"
      :class="titleClass"
    >
      {{ $t(title) }}
    </div>

    <div
      v-if="subtitle"
      class="mx-auto mb-6 fs-18 fw-500 mxw-1200"
    >
      {{ $t(subtitle) }}
    </div>

    <v-btn
      v-if="onNew && !hideNewButton"
      @click="$emit('new')"
      :disabled="newButtonDisabled"
      :loading="processing"
      color="primary"
      data-cy="new-resource-button"
      prepend-icon="add"
      size="large"
    >
      {{ $t(newButtonTitle) }}
    </v-btn>
  </v-card>
</template>

<script setup>
const props = defineProps({
  hideNewButton: {
    type: Boolean,
    default: false,
  },
  imageHeight: {
    type: Number,
    default: 300,
  },
  newButtonDisabled: Boolean,
  newButtonTitle: {
    type: String,
    default: 'New',
  },
  onNew: {
    type: Function,
    default: null,
  },
  card: {
    type: Boolean,
    default: false,
  },
  processing: Boolean,
  src: {
    default: 'https://assets.getbridgecare.com/customers/shared/nullstate.svg',
    type: String,
  },
  subtitle: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

defineEmits(['new']);

const cardClass = computed(() => {
  return {
    'mx-auto': true,
    'pa-8': true,
    'ta-center': true,
    'v-card--border': props.card,
  };
});

const titleClass = computed(() => {
  return {
    'mx-auto': true,
    'mb-4': true,
    'mt-8': true,
    'fs-18': !props.subtitle,
    'fw-500': !props.subtitle,
    'fs-24': !!props.subtitle,
    'fw-600': !!props.subtitle,
  };
});

const variant = computed(() => {
  return props.card ? 'elevated' : 'text';
});
</script>
