<template>
  <v-col
    @click="$emit('toggle', field)"
    :class="colClasses"
    :cols="cols"
    :md="md"
    :sm="sm"
  >
    <template v-if="checkable">
      <template v-if="checkLocked">
        <v-checkbox-btn
          @click.stop.prevent.capture="$emit('check')"
          :model-value="checkValue"
          class="me-2 pt-0 mt-0 flex-0-0"
          readonly
        />
      </template>
      <template v-else>
        <v-checkbox-btn
          v-model="checked"
          @update:model-value="$emit('check')"
          class="me-2 pt-0 mt-0 flex-0-0"
        />
      </template>
    </template>

    <span
      v-t="title"
      :aria-sort="ariaSort"
      role="columnheader"
    />

    <v-icon
      v-show="sorted"
      class="ms-1"
      color="black"
      aria-hidden
    >
      {{ sortIcon }}
    </v-icon>
  </v-col>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },

  props: {
    checkable: {
      type: Boolean,
      default: false,
    },
    checkLocked: {
      type: Boolean,
      default: false,
    },
    checkValue: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      default: null,
    },
    horizontalAlign: {
      type: String,
      default: null,
    },
    md: {
      type: String,
      default: null,
    },
    sm: {
      type: String,
      default: null,
    },
    sortDir: {
      type: String,
      default: null,
    },
    sortField: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },

  emits: ['check', 'toggle'],

  data() {
    return {
      checked: false,
    };
  },

  computed: {
    ariaSort() {
      if (this.sorted) {
        return this.sortDir === 'asc' ? 'ascending' : 'descending';
      }
      return null;
    },

    colClasses() {
      const classes = 'fw-600 c-black align-center'.split(' ');

      classes.push(this.dense ? 'fs-14' : 'fs-16');

      if (this.field) classes.push('pointer');

      switch (this.horizontalAlign) {
        case 'center':
          classes.push('d-flex-center', 'd-none', 'd-md-flex');
          break;
        case 'right':
          classes.push('justify-end', 'd-none', 'd-md-flex');
          break;
        default:
          classes.push('d-none', 'd-md-flex');
          break;
      }

      return classes.join(' ');
    },

    sorted() {
      return this.field && this.sortField === this.field;
    },

    sortIcon() {
      if (this.sorted) {
        return this.sortDir === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down';
      }
      return null;
    },
  },

  methods: {
    check(newVal) {
      this.checked = newVal;
    },
  },
};
</script>
