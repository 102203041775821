import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  url(id) {
    if (id) {
      return `${window.api_url}/organization/licenses/${id}`;
    }
    return `${window.api_url}/organization/licenses`;
  },

  create(resource) {
    return axios.post(this.url(), { license: resource }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(this.url(id));
  },

  get(id) {
    return axios.get(this.url(id));
  },

  index(filters, success) {
    return axios.get(this.url(), { params: filters }).then(success);
  },

  update(id, resource) {
    return axios.patch(this.url(id), { license: resource }).catch(handleError);
  },
};
