import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(localeStr) {
    const url = `${window.api_url}/v2/translations`;
    if (!localeStr) return url;

    return `${url}?filter[locale]=${localeStr}`;
  },

  async index(localeStr) {
    const customTranslationsResp = await axios.get(this.baseUrl(localeStr)).catch(handleError);
    return this.transformLocaleJSON(customTranslationsResp.data);
  },

  async translate(inputText, localeStr) {
    const translatedText = await axios
      .post(`${window.api_url}/public/translations`, { keys: [inputText], locale: localeStr })
      .catch(handleError);
    return translatedText.data?.translations[0];
  },

  transformLocaleJSON(jsonData) {
    if (!jsonData?.data) return {};

    return jsonData.data.reduce((c, d) => ((c[d.attributes.key] = d.attributes.value), c), {});
  },
};
