export function dateMaximum(properties, siblingValues) {
  let maximum;
  if (properties.maximum) {
    maximum = new Date(properties.maximum);
  }
  if (properties.dateLessOrEqualToDate) {
    maximum = new Date(properties.dateLessOrEqualToDate);
  }
  if (properties.dateLessOrEqualToDaysFromToday) {
    const date = new Date();
    date.setDate(date.getDate() + parseInt(properties.dateLessOrEqualToDaysFromToday, 10));
    maximum = date;
  }
  if (properties.dateLessOrEqualToValue) {
    const siblingValue = siblingValues[properties.dateLessOrEqualToValue];
    if (!siblingValue) return false;
    maximum = new Date(siblingValue);
  }

  if (maximum) return maximum.toISOString().split('T')[0];
  return undefined;
}

export function dateMinimum(properties, siblingValues) {
  let minimum;
  if (properties.minimum) {
    minimum = new Date(properties.minimum);
  }
  if (properties.dateGreaterOrEqualToDate) {
    minimum = new Date(properties.dateGreaterOrEqualToDate);
  }
  if (properties.dateGreaterOrEqualToDaysFromToday) {
    const date = new Date();
    date.setDate(date.getDate() + parseInt(properties.dateGreaterOrEqualToDaysFromToday, 10));
    minimum = date;
  }
  if (properties.dateGreaterOrEqualToValue) {
    const siblingValue = siblingValues[properties.dateGreaterOrEqualToValue];
    if (!siblingValue) return false;
    minimum = new Date(siblingValue);
  }
  if (minimum) return minimum.toISOString().split('T')[0];
  return undefined;
}
