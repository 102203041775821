import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/schema_function_test_suite_runs`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(schemaFunctionId) {
    return axios
      .post(this.baseUrl(), {
        schema_function_test_suite_run: { schema_function_id: schemaFunctionId },
      })
      .catch(handleError);
  },
};
