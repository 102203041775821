import axios from 'axios';
import { handleError } from '@/shared/services/api';
import schemaProperties from '@/specialist/services/api/schema-properties';

export default {
  url(id) {
    if (id) {
      return `${window.api_url}/organization/schemas/${id}`;
    }
    return `${window.api_url}/organization/schemas`;
  },

  create(schema, success, failure) {
    axios.post(this.url(), { schema }).then(success).catch(failure);
  },

  destroy(schemaId, success) {
    axios.delete(this.url(schemaId)).then(success);
  },

  get(schemaId, format = 'application/json') {
    return axios.get([this.url(schemaId), format.split('/')[1]].join('.'), {
      headers: { Accept: format },
    });
  },

  index(params, success) {
    if (success) {
      return axios.get(this.url(), { params }).then(success).catch(handleError);
    }

    return axios.get(this.url(), { params: params || {} }).catch(handleError);
  },

  schema_properties: schemaProperties,

  update(id, schema, success, failure) {
    if (!success) return axios.patch(this.url(id), { schema });

    axios.patch(this.url(id), { schema }).then(success).catch(failure);
  },

  updateOrCreate(schema, success, failure) {
    if (schema.id) {
      this.update(schema.id, schema, success, failure);
    } else {
      this.create(schema, success, failure);
    }
  },
};
