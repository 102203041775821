<template>
  <v-col
    :cols="cols"
    :lg="lg"
    :md="md"
    :sm="sm"
  >
    <v-row dense>
      <v-col
        :class="labelClass"
        cols="12"
      >
        <span>{{ $t(message) }}</span>
        <template v-if="mandatory">
          <required-indicator />
        </template>
      </v-col>

      <v-col>
        <v-select
          @update:model-value="handleUpdateModelValue"
          :items="colorOptions"
          :model-value="localValue"
          data-cy="labeled-color-select"
          data-testid="labeled-color-select"
          variant="filled"
          hide-details
          tile
          v-bind="attrsOmitDataPrefix"
        >
          <template #item="{ item, props }">
            <v-list-item v-bind="props">
              <template #append>
                <v-avatar
                  :color="item.value"
                  size="20"
                />
              </template>
            </v-list-item>
          </template>
          <template #selection="{ item }">
            <span
              v-text="item.title.toUpperCase()"
              class="me-3"
            />
            <v-avatar
              :color="item.value"
              size="20"
            />
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script>
import colors from 'vuetify/util/colors';
import Labeled from '@/shared/mixins/labeled';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import FilteredAttrs from '@/shared/mixins/FilteredAttrs';

export default {
  compatConfig: { MODE: 3 },

  components: {
    RequiredIndicator,
  },

  mixins: [Labeled, FilteredAttrs],

  props: {
    dividedBottom: Boolean,
    mandatory: Boolean,
    message: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      colorOptions: Object.values(colors).map((value) => value.lighten2),
    };
  },
};
</script>
