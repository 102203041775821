<template>
  <v-row
    :class="dense ? 'mb-1' : 'mb-2'"
    :dense="dense"
    class="d-flex align-center"
  >
    <v-col
      cols="12"
      sm="8"
    >
      <h2
        :class="[bold ? 'fw-600' : 'fw-500'].join(' ')"
        class="fs-20"
      >
        <span
          v-if="prefix"
          v-t="prefix"
          class="me-1"
        />
        <span v-t="title" />
        <v-chip
          v-if="chipText"
          :color="chipColor"
          class="ms-3"
        >
          {{ chipText }}
        </v-chip>
      </h2>
    </v-col>
    <v-col
      :class="$vuetify.display.smAndUp ? 'ta-right' : ''"
      cols="12"
      sm="4"
    >
      <slot name="actions" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    bold: Boolean,
    chipColor: {
      type: String,
      default: null,
    },
    chipText: {
      type: String,
      default: null,
    },
    dense: Boolean,
    prefix: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
