import _ from 'lodash';

const mergeObjectsRemovingNullValues = (currentParams, newParams) => {
  const mergedParams = { ...currentParams, ...newParams };

  Object.keys(mergedParams).forEach((key) => {
    if (mergedParams[key] === null) {
      delete mergedParams[key];
    }
  });

  return mergedParams;
};

const updateQuery = async (router, route, newParams) => {
  const mergedQuery = mergeObjectsRemovingNullValues(route.query, newParams);
  if (!_.isEqual(route.query, mergedQuery)) {
    await router.push({ query: mergedQuery });
  }
};

export { updateQuery, mergeObjectsRemovingNullValues };
