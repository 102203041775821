<template>
  <ResourceDialog
    @save="$emit('save')"
    ref="attachmentDialog"
    :processing="processing"
    :save-button-disabled="uploadedAttachments.length < 1"
    save-button-text="Send"
    title="Send attachments"
  >
    <template #form>
      <UppyDashboard
        :props="uppyProps"
        :uppy="uppy"
      />
    </template>
  </ResourceDialog>
</template>

<script setup>
import ResizeObserver from 'resize-observer-polyfill';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import Uppy from '@uppy/core';
import useUppyUtils from '@/shared/composables/useUppyUtils';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard as UppyDashboard } from '@uppy/vue';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

window.ResizeObserver = ResizeObserver;

const uppyUtils = useUppyUtils();

defineProps({
  processing: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['save', 'upload']);

defineExpose({ cancelAll, close, open });

const uppyProps = {
  proudlyDisplayPoweredByUppy: false,
  inline: true,
  height: 200,
  width: '100%',
};

const attachmentDialog = ref(null);
const uppy = ref(null);
const uploadedAttachments = ref([]);

onBeforeUnmount(() => {
  uppy.value.close();
});

onMounted(() => {
  uppy.value = new Uppy().use(XHRUpload, {
    endpoint: uppyUtils.getUploadAPIEndpoint(),
  });

  uppy.value.on('upload-success', (file, evt) => {
    const asset = {
      file: {
        id: evt.body.id,
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      },
    };

    uploadedAttachments.value.push(asset);
    emit('upload', asset);
  });
});

function close() {
  attachmentDialog.value.close();
}

function open() {
  uploadedAttachments.value = [];
  attachmentDialog.value.open();
}

function cancelAll() {
  uppy.value.cancelAll();
}
</script>
