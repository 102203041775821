<template>
  <v-container
    v-if="$i18n.locale && webpage"
    :class="containerClass"
    class="my-8"
  >
    <grid-layout
      v-model:layout="webpage.layout[size]"
      :auto-size="true"
      :col-num="36"
      :is-draggable="false"
      :is-resizable="false"
      :row-height="8"
    >
      <grid-item
        v-for="item in webpage.layout[size]"
        :key="item.i"
        :h="item.h"
        :i="item.i"
        :w="item.w"
        :x="item.x"
        :y="item.y"
      >
        <PageElement
          v-model="elements[item.i]"
          mode="view"
        />
      </grid-item>
    </grid-layout>
  </v-container>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import PageElement from '@/shared/components/PageElement.vue';
import { GridLayout, GridItem } from 'grid-layout-plus';

export default {
  compatConfig: { MODE: 2 },

  components: {
    GridItem,
    GridLayout,
    PageElement,
  },

  data() {
    return {
      elements: null,
      lang: null,
      webpage: null,
    };
  },

  computed: {
    containerClass() {
      if (this.size === 'lg') {
        return 'mxw-1200';
      }
      if (this.size === 'md') {
        return 'mxw-800';
      }
      return 'mxw-440';
    },

    size() {
      if (this.$vuetify.display.lgAndUp) {
        return 'lg';
      }
      if (this.$vuetify.display.mdAndUp) {
        return 'md';
      }
      return 'sm';
    },
  },

  watch: {
    '$route.params.webpageId': {
      immediate: true,
      handler() {
        this.identifyUser();
      },
    },
  },

  methods: {
    load() {
      Api.public_api.organization.webpage.get(this.$route.params.webpageId, (resp) => {
        this.webpage = resp.data;
        this.setPageLang();
        const title = this.$t([this.$store.state.config.title, this.webpage.name].join(' - '));
        document.title = title;
        const els = {};
        this.webpage.elements.forEach((el) => {
          els[el.i] = el;
        }, this);
        this.elements = els;
      });
    },

    setPageLang() {
      this.lang =
        this.$route.query.lang || this.$i18n.locale || this.$store.state.profile?.default_locale;
    },

    identifyUser() {
      const vm = this;
      this.$store.dispatch('identify', {
        success() {
          vm.load();
        },
        failure() {
          vm.load();
        },
      });
    },
  },
};
</script>
<style scoped>
.v-btn__content {
  white-space: normal !important;
}
</style>
