import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/organization/members`;
    if (id) url += `/${id}`;

    return url;
  },

  create(member, success, failure) {
    if (!success) return axios.post(this.baseUrl(), { member }).catch(handleError);
    return axios.post(this.baseUrl(), { member }).then(success).catch(failure);
  },

  destroy(memberId, success) {
    if (!success) return axios.delete(this.baseUrl(memberId)).catch(handleError);
    return axios.delete(this.baseUrl(memberId)).then(success);
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id)).catch(handleError);
    return axios.get(this.baseUrl(id)).then(success);
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
    return axios.get(this.baseUrl(), { params: filters }).then(success).catch(handleError);
  },

  update(member, success, failure) {
    if (!success) return axios.patch(this.baseUrl(member.id), { member }).catch(handleError);

    return axios.patch(this.baseUrl(member.id), { member }).then(success).catch(failure);
  },

  upload: {
    baseUrl() {
      return `${window.api_url}/organization/members/uploads`;
    },

    create(upload, success, failure) {
      return axios.post(this.baseUrl(), { upload }).then(success).catch(failure);
    },
  },
};
