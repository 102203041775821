<template>
  <Dashboard
    :props="uppyProps"
    :uppy="uppy"
  />
</template>

<script>
import API from '@/shared/mixins/api';
import XHRUpload from '@uppy/xhr-upload';
import Uppy from '@uppy/core';
import UppyUtils from '@/shared/mixins/uppy_utils';
import ResizeObserver from 'resize-observer-polyfill';
import { Dashboard } from '@uppy/vue';

window.ResizeObserver = ResizeObserver;

export default {
  compatConfig: { MODE: 3 },

  components: {
    Dashboard,
  },

  mixins: [API, UppyUtils],

  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    owner: {
      type: Object,
      default: null,
    },
    restrictions: {
      type: Object,
      default: null,
    },
  },

  emits: ['uploaded'],

  data() {
    return {
      uppy: null,
      uppyProps: {
        proudlyDisplayPoweredByUppy: false,
        inline: true,
        height: this.dense ? 175 : 200,
        width: '100%',
        locale: this.getUppyLocale(),
      },
    };
  },

  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newLocale, formerLocale) {
        if (newLocale && formerLocale && formerLocale !== newLocale) {
          window.location.reload();
        }
      },
    },
  },

  beforeUnmount() {
    if (this.uppy) {
      this.uppy.close();
    }
  },

  created() {
    this.uppy = new Uppy({ restrictions: this.restrictions }).use(XHRUpload, {
      endpoint: this.getUploadAPIEndpoint(),
    });

    this.uppy.on('upload-success', async (file, event) => {
      const params = {
        file: {
          id: event.body.id, // remove the Shrine storage prefix
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
        business_id: this.owner.business_id,
        child_id: this.owner.child_id,
        group_id: this.owner.group_id,
        owner_type: this.owner.type,
        owner_id: this.owner.id,
        tag: this.owner.tag,
        tags: this.owner.tags || [],
        description: this.owner.description,
      };

      let response;
      if (this.$role === 'specialist') {
        response = await this.api.organization.attachment.create(params);
      } else if (this.$role === 'manager' && this.owner.child_id) {
        response = await this.api.manager.attachment.create(params);
      } else {
        response = await this.api.member.attachment.create(params);
      }

      if (response?.status !== 201) return this.uppy.cancelAll();

      this.$emit('uploaded', response.data);
      return true;
    });
  },
};
</script>
