import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    const url = `${window.api_url}/manager/members`;
    if (id) return `${url}/${id}`;
    return url;
  },

  create(member) {
    return axios.post(this.baseUrl(), { member }).catch(handleError);
  },

  createOrUpdate(member) {
    if (member.id) {
      return this.update(member.id, member);
    }
    return this.create(member);
  },

  destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  get(id, success) {
    axios.get(this.baseUrl(id)).then(success);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  update(id, member) {
    return axios.patch(this.baseUrl(id), { member }).catch(handleError);
  },
};
