const ENROLLMENT_STATUSES = Object.freeze({
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  ENROLLED: 'Enrolled',
  GRADUATED: 'Graduated',
  OFFERED: 'Offered',
  PLACED: 'Placed',
  PROPOSED: 'Proposed',
  SELECTED: 'Selected',
  STANDBY: 'Standby',
  UNENROLLED: 'Unenrolled',
  WAITLISTED: 'Waitlisted',
  WITHDRAWN: 'Withdrawn',
});

const ROLES = Object.freeze({
  MANAGER: 'manager',
  PARENT: 'parent',
  SPECIALIST: 'specialist',
});

const SCHEMA_DATA_TYPES = Object.freeze({
  ASSESSMENT: 'Assessment',
  BUSINESS: 'Business',
  CHILD: 'Child',
  FORM: 'Form',
  GROUP: 'Group',
  PROGRAM: 'Program',
  PROVIDER: 'Provider',
  REVIEW: 'Review',
  VAULT: 'Vault',
  WORKSHEET: 'Worksheet',
});

const LABELS = Object.freeze({
  NOT_APPLICABLE: 'Not applicable',
});

// Defines a subset of the allowed extensions in the asset upload API as of 2024-05-21
//   Omissions: mov, mpg, mpeg, avi, ogv, webm
//   See https://bridgecare.atlassian.net/browse/CARE-578 for follow-up
const SUPPORTED_DOCUMENT_EXTENSIONS = ['csv', 'doc', 'docx', 'pdf', 'xls', 'xlsx'];
const SUPPORTED_VIDEO_EXTENSIONS = ['mp4'];
const SUPPORTED_IMAGE_EXTENSIONS = [
  'heic',
  'heif',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'tif',
  'tiff',
  'webp',
];

const ALL_SUPPORTED_EXTENSIONS = [
  ...SUPPORTED_DOCUMENT_EXTENSIONS,
  ...SUPPORTED_IMAGE_EXTENSIONS,
  ...SUPPORTED_VIDEO_EXTENSIONS,
];

const ALL_SUPPORTED_EXTENSIONS_SET = new Set(ALL_SUPPORTED_EXTENSIONS);

const UNSUPPORTED_LMS_EXTENSIONS = new Set(['csv', 'xls', 'xlsx', 'doc', 'docx']);

export {
  ALL_SUPPORTED_EXTENSIONS,
  ALL_SUPPORTED_EXTENSIONS_SET,
  SUPPORTED_DOCUMENT_EXTENSIONS,
  SUPPORTED_VIDEO_EXTENSIONS,
  SUPPORTED_IMAGE_EXTENSIONS,
  ENROLLMENT_STATUSES,
  ROLES,
  SCHEMA_DATA_TYPES,
  LABELS,
  UNSUPPORTED_LMS_EXTENSIONS,
};
