import { EventBus } from '@/plugins/event-bus';

export default {
  created() {
    EventBus.$on('chime', this.regularChime);

    EventBus.$on('error', this.error);

    EventBus.$on('longChime', this.longChime);

    EventBus.$on('shortChime', this.shortChime);
  },

  data() {
    return {
      chime: false,
      chimeText: '',
      chimeTime: 3000,
    };
  },

  methods: {
    clearChime() {
      this.chime = false;
      this.chimeText = null;
      this.chimeTime = null;
    },

    error(error) {
      this.clearChime();
      this.chimeTime = 8000;

      if (error?.response?.data?.errors) {
        const errorCollection = error.response.data.errors;

        if (typeof errorCollection[0] === 'object') {
          this.chimeText = errorCollection.map((err) => err.detail).join(', ');
        } else {
          this.chimeText = errorCollection.join(', ');
        }
      } else if (Array.isArray(error)) {
        this.chimeText = error.join('. ');
      } else if (typeof error === 'string') {
        this.chimeText = error;
      } else {
        this.chimeText = 'Unknown error occurred. Please try again or contact support.';
      }

      this.chime = true;
    },

    longChime(text) {
      this.chimeTime = 8000;
      this.postChime(text, 8000);
    },

    postChime(text, time) {
      this.clearChime();
      this.chimeTime = time;
      this.chimeText = text;
      this.chime = true;
    },

    regularChime(text) {
      this.postChime(text, 3000);
    },

    shortChime(text) {
      this.postChime(text, 1500);
    },
  },
};
