<template>
  <v-img
    :height="height"
    :src="link"
    :width="width"
    cover
  />
</template>

<script setup>
const props = defineProps({
  height: {
    type: Number,
    default: 200,
  },
  latitude: {
    type: Number,
    default: null,
  },
  longitude: {
    type: Number,
    default: null,
  },
  width: {
    type: Number,
    default: 400,
  },
});

const link = computed(() => {
  const url = new URL('https://maps.googleapis.com/maps/api/staticmap');
  url.searchParams.set('markers', `${props.latitude},${props.longitude}`);
  url.searchParams.set('zoom', '13');
  url.searchParams.set('size', `${props.width}x${props.height}`);
  url.searchParams.set('maptype', 'roadmap');
  url.searchParams.set('key', 'AIzaSyBeG41-k-Yb9ke_Mzol_LiBYNvn62ZzBVg');
  url.searchParams.set('async', true);

  return url.href;
});
</script>
