export default function usePasswordValidation() {
  function isValidPassword(password, password_confirmation) {
    if (!password) {
      return false;
    }
    if (password_confirmation !== password) {
      return false;
    }
    if (password.length < 8) {
      return false;
    }
    let count = 0;
    if (/[A-Z]/g.test(password)) {
      count += 1;
    }
    if (/[a-z]/g.test(password)) {
      count += 1;
    }
    if (/[0-9]/g.test(password)) {
      count += 1;
    }
    if (/[.,/#!$%^&*;:{}=\-_`~()]/g.test(password)) {
      count += 1;
    }
    return count >= 3;
  }

  return {
    isValidPassword,
  };
}
