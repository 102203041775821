export const maskRegex = (mask) => {
  // Docs: https://github.com/probil/v-mask#default-placeholders
  const regexPattern = mask
    // A question mark indicates that the next character is optional (prefix positioning)
    // Regex uses suffix positioning, so move them up one (e.g. ?# becomes #?)
    .replace(/\?(.)/g, '$1?')
    // Escape special characters
    .replace(/[/\\^$*+.()|[\]{}]/g, '\\$&')
    // Number
    .replace(/#/g, '\\d')
    // Letter in any case
    .replace(/A/g, '[a-zA-Z]')
    // Number or letter
    .replace(/N/g, '[a-zA-Z0-9]')
    // Any symbol
    .replace(/X/g, '.');

  return new RegExp(`^${regexPattern}$`);
};

export const validateInputAgainstAttributeMask = (attributeProperties, input) => {
  if (!attributeProperties) return true;

  let mask;

  if (attributeProperties?.role === 'phone-us') {
    mask = '(###) ###-####';
  } else if (!attributeProperties?.mask) {
    return true;
  } else {
    mask = attributeProperties.mask;
  }

  return maskRegex(mask).test(input);
};
