<template>
  <v-col
    v-if="prop"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <div v-if="mode === 'show'">
      <v-row
        v-if="modelValue.custom[field] && modelValue.custom[field].length > 0"
        dense
      >
        <v-col
          v-if="showIcon && prop.icon"
          class="ta-center"
          cols="2"
          md="1"
        >
          <v-icon class="fs-18"> prop.icon </v-icon>
        </v-col>
        <v-col
          cols="10"
          md="11"
        >
          <div
            v-if="showTitle && prop.alias && prop.type != 'boolean'"
            v-text="$t(prop.alias).toUpperCase()"
            class="bc-prelude mb-1"
          />
          <div v-if="prop.type == 'array'">
            <translated-items
              v-if="modelValue.custom[field]"
              :items="modelValue.custom[field]"
              classes="bc-body c-black"
            />
          </div>
          <div v-if="prop.type == 'boolean'">
            <div
              v-if="prop.icon"
              v-t="prop.alias"
              class="fs-16 c-black"
            />
            <v-checkbox
              v-else
              v-model="modelValue.custom[field]"
              :aria-label="$t(label)"
              :label="$t(prop.alias)"
              :placeholder="$t(prop.alias)"
              :readonly="true"
              class="mt-0"
              hide-details
            />
          </div>
          <div v-if="prop.type == 'string'">
            <div
              v-t="modelValue.custom[field]"
              class="fs-16 c-black"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-if="prop.type == 'array'">
        <v-row dense>
          <v-col
            class="fw-500"
            cols="12"
          >
            {{ $t(prop.alias) }}
          </v-col>
          <v-col>
            <v-select
              v-model="modelValue.custom[field]"
              @blur="handleBlur"
              @update:model-value="handleUpdateModelValue()"
              :append-inner-icon="locked ? 'lock' : ''"
              :aria-label="$t(`Select ${label}`)"
              :density="dense ? 'compact' : undefined"
              :disabled="locked"
              :items="prop.items.enum"
              :placeholder="$t('Select all that apply')"
              :readonly="readonly"
              variant="filled"
              hide-details
              multiple
              tile
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="prop.type == 'boolean'">
        <v-row dense>
          <v-col>
            <v-checkbox
              v-model="modelValue.custom[field]"
              @blur="handleBlur"
              @update:model-value="handleBlur"
              :aria-label="$t(label)"
              :density="dense ? 'compact' : undefined"
              :disabled="locked"
              :label="$t(prop.alias)"
              :placeholder="$t(prop.alias)"
              :readonly="readonly"
              class="mt-0"
              hide-details
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="prop.type == 'string'">
        <v-row dense>
          <v-col
            class="fw-500"
            cols="12"
          >
            {{ $t(prop.alias) }}
          </v-col>
          <template v-if="prop.enum && prop.enum.length > 0">
            <v-select
              v-model="modelValue.custom[field]"
              @blur="handleBlur"
              @update:model-value="handleUpdateModelValue()"
              :append-inner-icon="locked ? 'lock' : ''"
              :aria-label="$t(`Select ${label}`)"
              :density="dense ? 'compact' : undefined"
              :disabled="locked"
              :items="prop.enum"
              :readonly="readonly"
              variant="filled"
              hide-details
              tile
            />
          </template>
          <template v-else>
            <v-col>
              <v-text-field
                v-model="modelValue.custom[field]"
                @blur="handleBlur"
                @update:model-value="handleUpdateModelValue()"
                :append-inner-icon="locked ? 'lock' : ''"
                :aria-label="$t(`Enter ${label}`)"
                :density="dense ? 'compact' : undefined"
                :disabled="locked"
                :readonly="readonly"
                variant="filled"
                hide-details
                tile
              />
            </v-col>
          </template>
        </v-row>
      </div>
    </div>
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';
import TranslatedItems from '@/shared/components/TranslatedItems.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    'translated-items': TranslatedItems,
  },

  mixins: [Schematized, Labeled],

  props: {
    field: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: null,
    },
    schemaId: {
      type: String,
      default: null,
    },
    showIcon: {
      type: String,
      default: null,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    mode(newVal) {
      if (newVal === 'edit') {
        if (this.prop.type === 'array') {
          this.modelValue.custom[this.field] ||= [];
        }
      } else if (this.prop.type === 'array') {
        this.modelValue.custom[this.field] ||= null;
      }
    },
  },

  methods: {
    getProp() {
      if (this.field) {
        return this.namespace(
          this.$store.state.schemas[this.schemaId].definition.properties.custom.properties,
          this.field,
        );
      }
      return true;
    },
  },
};
</script>
