<template>
  <div>
    <v-tooltip
      v-if="passing === true"
      location="bottom"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          :aria-label="$t('Correct')"
          aria-hidden="false"
          class="ml-4 focus-visible"
          color="green"
          role="tooltip"
          size="24"
          tabindex="0"
        >
          check_circle
        </v-icon>
      </template>

      <span>
        {{ $t('Correct') }}
      </span>
    </v-tooltip>

    <v-tooltip
      v-if="passing === false"
      location="bottom"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          :aria-label="$t('Incorrect')"
          aria-hidden="false"
          class="ml-4 focus-visible"
          color="red"
          role="tooltip"
          size="24"
          tabindex="0"
        >
          cancel
        </v-icon>
      </template>

      <span>
        {{ $t('Incorrect') }}
      </span>
    </v-tooltip>

    <v-tooltip
      v-if="published === false"
      location="bottom"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          :aria-label="$t('Unpublished')"
          :color="unpublishedColor"
          aria-hidden="false"
          class="ml-4 focus-visible"
          role="tooltip"
          size="24"
          tabindex="0"
        >
          hide_source
        </v-icon>
      </template>

      <span>
        {{ $t('Unpublished') }}
      </span>
    </v-tooltip>

    <v-tooltip
      v-if="mandatory"
      location="bottom"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          :aria-label="$t('required')"
          :color="statusColor('red')"
          aria-hidden="false"
          class="ml-4 focus-visible"
          role="tooltip"
          size="x-small"
          tabindex="0"
        >
          emergency
        </v-icon>
      </template>

      <span>
        {{ $t('Required') }}
      </span>
    </v-tooltip>

    <v-tooltip
      v-if="conditional"
      location="bottom"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          :aria-label="$t('Conditional')"
          :color="statusColor()"
          aria-hidden="false"
          class="ml-4 focus-visible"
          role="tooltip"
          size="24"
          tabindex="0"
        >
          dynamic_form
        </v-icon>
      </template>

      <span>
        {{ $t('Conditional') }}
      </span>
    </v-tooltip>

    <v-tooltip
      v-if="verification"
      location="bottom"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          :aria-label="$t('Requires verification')"
          :color="statusColor()"
          aria-hidden="false"
          class="ml-4 focus-visible"
          role="tooltip"
          size="24"
          tabindex="0"
        >
          add_moderator
        </v-icon>
      </template>

      <span>
        {{ $t('Requires verification') }}
      </span>
    </v-tooltip>

    <v-tooltip
      v-if="published === true"
      location="bottom"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          :aria-label="$t('Published')"
          aria-hidden="false"
          class="ml-4 focus-visible"
          color="green"
          role="tooltip"
          size="24"
          tabindex="0"
        >
          circle
        </v-icon>
      </template>

      <span>
        {{ $t('Published') }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    conditional: {
      type: Boolean,
      default: null,
    },
    mandatory: {
      type: Boolean,
      default: null,
    },
    passing: {
      type: Boolean,
      default: null,
    },
    published: {
      type: Boolean,
      default: null,
    },
    verification: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      publishedColor: 'primary',
      unpublishedColor: 'gray',
    };
  },

  methods: {
    statusColor(color = this.publishedColor) {
      return this.published ? color : this.unpublishedColor;
    },
  },
};
</script>
