<template>
  <v-col
    :class="classes"
    :cols="cols"
    :lg="lg"
    :md="md"
    :sm="sm"
  >
    <v-row dense>
      <template v-if="message">
        <v-col
          :class="labelClass"
          :cols="removable ? '8' : '12'"
        >
          <span data-testid="labeled-control-message">
            {{ $t(message) }}
          </span>

          <required-indicator v-if="mandatory" />
        </v-col>

        <template v-if="removable">
          <v-col
            class="d-flex justify-end"
            cols="4"
          >
            <v-btn
              @click="$emit('remove')"
              class="c-white"
              color="red"
              size="small"
            >
              <span>{{ $t('Remove') }}</span>
            </v-btn>
          </v-col>
        </template>
      </template>

      <template v-if="description">
        <v-col
          class="c-light-black my-1"
          cols="12"
        >
          {{ $t(description) }}
        </v-col>
      </template>

      <v-col
        cols="12"
        data-testid="labeled-control-input"
      >
        <slot />
      </v-col>
    </v-row>

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    RequiredIndicator,
  },

  mixins: [Labeled],

  props: {
    description: {
      type: String,
      default: null,
    },
    dividedBottom: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: null,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },

  emits: ['remove'],

  computed: {
    classes() {
      // The date selector doesn't work at any narrower width then 300.
      if (this.type === 'string' && this.format === 'date') {
        return 'mnw-300';
      }
      return 'mnw-200';
    },
  },
};
</script>
