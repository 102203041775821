import axios from 'axios';
import _ from 'lodash';

import { handleError } from '@/shared/services/api';
import { denormalizeResponse } from './json-api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/teams`;
  },

  create(team, success = _.identity, failure = handleError) {
    return axios.post(this.baseUrl(), { team }).then(success).catch(failure);
  },

  destroy(id, success = _.identity) {
    return axios.delete(`${this.baseUrl()}/${id}`).then(success).catch(handleError);
  },

  async index(filters, success) {
    if (success) {
      return axios.get(this.baseUrl(), { params: filters }).then(success);
    }

    const response = await axios.get(this.baseUrl(), { params: filters }).catch(handleError);
    return denormalizeResponse(response);
  },

  async get(id, success = _.identity) {
    const response = await axios.get(`${this.baseUrl()}/${id}`).then(success).catch(handleError);
    return denormalizeResponse(response);
  },

  update(id, team, success, failure) {
    axios.patch(`${this.baseUrl()}/${id}`, { team }).then(success).catch(failure);
  },
};
