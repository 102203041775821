<template>
  <div>
    <v-row
      v-for="(id, index) in filteredSubsidyProgramIds"
      :key="id"
      :class="getClasses(index)"
    >
      <v-col
        class="pb-0"
        cols="12"
        lg="4"
        xl="3"
      >
        <h3 class="fs-18 fw-600">
          {{ $t(`${terms.programs} offered`) }}
        </h3>
        <div class="fs-14">
          {{ getSubsidyProgramName(id) }}
        </div>
      </v-col>
      <v-col
        class="c-black fs-16 fw-400"
        cols="12"
        lg="8"
        xl="9"
      >
        <div>
          <Program
            v-for="(program, programIndex) in programsBySubsidy[id]"
            :key="program.id"
            :class="programIndex != 0 ? 'mt-4' : ''"
            :program="program"
            :rows="rows"
            :show-availability="isAvailabilityFlagEnabled(id)"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import Program from '@/shared/components/search/program.vue';
import { useStore } from 'vuex';
import useTerms from '@/shared/composables/useTerms';
import _ from 'lodash';

const store = useStore();
const { terms } = useTerms();
const UNASSOCIATED_PROGRAM_ID_VALUE = 'null';

const props = defineProps({
  hideTopBorder: {
    type: Boolean,
    default: false,
  },
  provider: {
    type: Object,
    default: null,
  },
  rows: {
    type: Number,
    default: null,
  },
});

const programsBySubsidy = ref({});
const subsidyPrograms = ref([]);

const filteredSubsidyProgramIds = computed(() => {
  const ids = Object.keys(programsBySubsidy.value);
  const hasUnassociatedPrograms = ids.includes(UNASSOCIATED_PROGRAM_ID_VALUE);

  // Filter subsidy program ids based on contextually returned programs
  // parent context will only return published programs, specialists will return all
  const filteredIds = ids.filter((id) => subsidyPrograms.value.find((sp) => sp.id === id));

  if (hasUnassociatedPrograms) filteredIds.unshift(UNASSOCIATED_PROGRAM_ID_VALUE);

  return filteredIds;
});

watch(
  () => props.provider,
  (newVal) => {
    if (newVal) void loadPrograms();
  },
);

onMounted(() => {
  void loadPrograms();
  void loadSubsidyPrograms();
});

function isAvailabilityFlagEnabled(id) {
  const subsidyProgram = subsidyPrograms.value.find((sp) => sp.id === id);
  return subsidyProgram?.enable_show_seat_availability;
}

function getClasses(index) {
  if (props.hideTopBorder && index === 0) return 'c-black mb-3 pt-1';
  return 'bt-1 bc-very-light-gray c-black mb-3 pt-1';
}

function getSubsidyProgramName(id) {
  if (id === UNASSOCIATED_PROGRAM_ID_VALUE) return null;
  return subsidyPrograms.value.find((sp) => sp.id === id)?.name;
}

async function loadPrograms() {
  const params = {
    provider_id: props.provider.id,
    include: 'seat_availability_flag',
  };
  const { data } = await api.public_api.organization.program.index(params);
  programsBySubsidy.value = _.groupBy(data, 'subsidy_program_id');
}

async function loadSubsidyPrograms() {
  let resp;

  if (store.state.profile?.org_subsidies_admin) {
    resp = await api.organization.subsidy_program.index();
  } else {
    resp = await api.public_api.organization.subsidy_program.index();
  }

  subsidyPrograms.value = resp.data;
}
</script>
