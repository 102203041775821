import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  url(id) {
    if (id) {
      return `${window.api_url}/organization/sites/${id}`;
    }
    return `${window.api_url}/organization/sites`;
  },

  create(site, success, failure) {
    axios.post(this.url(), site).then(success).catch(failure);
  },

  destroy(siteId, success) {
    axios.delete(this.url(siteId)).then(success);
  },

  get(siteId, success) {
    if (!success) return axios.get(this.url(siteId));

    axios.get(this.url(siteId)).then(success);
  },

  index(filters, success) {
    if (success) {
      return axios.get(this.url()).then(success);
    }
    return axios.get(this.url(), { params: filters });
  },

  page: {
    url(siteId, pageId) {
      if (pageId) {
        return `${window.api_url}/organization/sites/${siteId}/pages/${pageId}`;
      }
      return `${window.api_url}/organization/sites/${siteId}/pages`;
    },

    get(siteId, pageId, success) {
      axios.get(this.url(siteId, pageId)).then(success);
    },

    update(siteId, pageId, page, success) {
      axios.patch(this.url(siteId, pageId), { page }).then(success);
    },
  },

  site_schema: {
    url(siteId, siteSchemaId) {
      if (siteSchemaId) {
        return `${window.api_url}/organization/sites/${siteId}/site_schemas/${siteSchemaId}`;
      }
      return `${window.api_url}/organization/sites/${siteId}/site_schemas`;
    },

    create(siteId, site_schema, success, failure) {
      axios.post(this.url(siteId), { site_schema }).then(success).catch(failure);
    },

    destroy(siteId, siteSchemaId, success) {
      axios.delete(this.url(siteId, siteSchemaId)).then(success);
    },

    index(siteId, success) {
      axios.get(this.url(siteId)).then(success);
    },
  },

  update(id, site, success, failure) {
    if (!success) return axios.patch(this.url(id), { site }).catch(handleError);

    return axios.patch(this.url(id), { site }).then(success).catch(failure);
  },

  updateOrCreate(site, success, failure) {
    if (site.id) {
      this.update(site.id, site, success, failure);
    } else {
      this.create(site, success, failure);
    }
  },
};
