import baseAssets from '@/shared/assets/assets.json';

export default {
  assets: baseAssets,

  assetBase: 'https://assets.getbridgecare.com',

  data(key) {
    let url = this.seek(key.split('.'), this.assets);
    if (!url.startsWith('http')) {
      url = this.assetBase + url;
    }
    return url;
  },

  seek(keyAry, obj) {
    if (keyAry.length === 0) {
      return obj;
    }
    const key = keyAry.shift();
    return this.seek(keyAry, obj[key]);
  },

  url(key) {
    let url = this.seek(key.split('.'), this.assets);
    if (!url.startsWith('http')) {
      url = this.assetBase + url;
    }
    return url;
  },
};
