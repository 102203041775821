import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/organization/assessments`;
    if (id) url += `/${id}`;
    return url;
  },

  create(resource, success, failure) {
    if (!success) return axios.post(this.baseUrl(), { assessment: resource }).catch(handleError);

    axios.post(this.baseUrl(), { assessment: resource }).then(success).catch(failure);
  },

  destroy(id, success) {
    if (!success) return axios.delete(this.baseUrl(id)).catch(handleError);

    axios.delete(this.baseUrl(id)).then(success);
  },

  downloadUrl(id, filename) {
    return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf`;
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters }).catch(handleError);

    axios.get(this.baseUrl(), { params: filters }).then(success).catch(handleError);
  },

  get(id, success) {
    if (!success) return axios.get(this.baseUrl(id)).catch(handleError);

    axios.get(this.baseUrl(id)).then(success);
  },

  update(id, resource, success, failure) {
    if (!success) return axios.patch(this.baseUrl(id), { assessment: resource }).catch(handleError);

    axios.patch(`${this.baseUrl()}/${id}`, { assessment: resource }).then(success).catch(failure);
  },
};
