import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/schema_function_tests`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(schemaFunctionId, name) {
    return axios
      .post(this.baseUrl(), {
        schema_function_test: { name, schema_function_id: schemaFunctionId },
      })
      .catch(handleError);
  },

  async destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  async get(id) {
    return axios.get(this.baseUrl(id));
  },

  async index(schemaFunctionId) {
    return axios.get(this.baseUrl(), { params: { schema_function_id: schemaFunctionId } });
  },

  async update(id, newValues) {
    return axios.patch(this.baseUrl(id), { schema_function_test: newValues }).catch(handleError);
  },
};
