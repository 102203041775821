import Api from '@/shared/services/bright_finder';
import LinkedList from '@/shared/services/linked-list';

const BASE_VALUE = { assertion: { conditions: [], value: {} } };

/**
 * @deprecated This mixin is replaced by useQuestionable.js
 * see ClaimReviewDialog.vue for usage
 */
export default {
  computed: {
    validVerificationQuestions() {
      return this.questions
        .filter((question) => question.verification)
        .filter((question) => this.displayAll || (question.published && question.valid));
    },

    validEligibilityQuestions() {
      return LinkedList.sort(this.questions.filter((question) => question.eligibility)).filter(
        (question) => this.displayAll || (question.published && question.valid),
      );
    },

    validOtherQuestions() {
      return LinkedList.sort(this.questions.filter((question) => !question.eligibility)).filter(
        (question) => this.displayAll || (question.published && question.valid),
      );
    },

    validQuestions() {
      return LinkedList.sort(this.questions).filter((question) => question.valid);
    },

    validPublishedQuestions() {
      return LinkedList.sort(this.questions).filter(
        (question) => question.valid && question.published,
      );
    },
  },

  data() {
    return {
      displayAll: false,
      ownerId: null,
      ownerType: null,
      questions: [],
    };
  },

  methods: {
    async validate(model) {
      const assertions = this.questions.map((question) => {
        const value = this[model || question.synced_model.toLowerCase()];
        if (question.conditions.length == 0) return BASE_VALUE;
        return { assertion: { conditions: question.conditions, value } };
      });

      const { data } = await Api.public_api.assertion.promiseBulkCreate(assertions);
      const { results } = data;

      if (results.length == 0) return;
      this.questions.forEach((question, index) => {
        question.valid = results[index].result;
      });
    },

    async loadQuestions(callback) {
      const resp = await Api.public_api.organization.question.index({
        owner_id: this.ownerId,
        owner_type: this.ownerType,
      });
      this.questions = LinkedList.sort(resp.data);
      this.validate();
      if (callback) callback();
    },
  },
};
