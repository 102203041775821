/**
 * @deprecated This mixin is replaced by useTerms.js
 * @example ```vue
 * <script setup>
 * import useTerms from '@/shared/composables/useTerms';
 *
 * const { terms } = useTerms();
 * </script>
 * ```
 */
export default {
  computed: {
    terms() {
      const organizationTerms = this.$store.state.config.terms;
      const siteTerms = this.$store.state.site.terms;

      return { ...siteTerms, ...organizationTerms };
    },
  },
};
