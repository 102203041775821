<template>
  <!-- TODO: This is super broken - not ready for vue3 -->
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row v-if="openTitle && closeTitle">
      <v-col
        :class="titleClass || 'fs-18'"
        class="fw-600"
      >
        {{ $t(openTitle) }}
      </v-col>
      <v-col
        :class="titleClass || 'fs-18'"
        class="fw-600 ta-right"
      >
        {{ $t(closeTitle) }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="d-flex align-center">
        <div
          v-text="openText"
          class="fs-15 fw-500 pe-2 ta-left"
        />
        <v-range-slider
          v-model="timeRange"
          @blur="handleBlur"
          @end="handleChange"
          @update:model-value="handleInput"
          :aria-label="$t('start and finish time')"
          :disabled="locked"
          :max="maxMins"
          :min="minMins"
          :step="stepValue"
          color="primary"
          hide-details
        />
        <div
          v-text="closeText"
          class="fs-15 fw-500 ps-2 ta-right"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';
import { parseTimeToMil, parseMilToTime, parseTimeToString } from '@/shared/services/time-parser';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized],

  props: {
    maxMins: {
      type: Number,
      default: 1440,
    },
    minMins: {
      type: Number,
      default: 0,
    },
    openTitle: {
      type: String,
      default: null,
    },
    titleClass: {
      type: String,
      default: null,
    },
    closeTitle: {
      type: String,
      default: null,
    },
    stepValue: {
      type: Number,
      default: 15,
    },
    modelValue: Array,
  },

  emits: ['blur', 'change', 'input', 'update:modelValue'],

  data() {
    return {
      defaultTimeRange: [0, 1440],
      timeRange: this.modelValue
        ? [this.parseMilToTime(this.modelValue[0]), this.parseMilToTime(this.modelValue[1])]
        : [0, 1440],
    };
  },

  computed: {
    openText() {
      return this.parseTimeToString(this.timeRange[0]);
    },

    closeText() {
      return this.parseTimeToString(this.timeRange[1]);
    },
  },

  watch: {
    modelValue(newVal) {
      if (!newVal) {
        this.timeRange = this.defaultTimeRange;
      } else {
        this.timeRange = [this.parseMilToTime(newVal[0]), this.parseMilToTime(newVal[1])];
      }
    },
  },

  methods: {
    handleBlur() {
      const newVal = [
        this.parseTimeToMil(this.timeRange[0]),
        this.parseTimeToMil(this.timeRange[1]),
      ];
      this.$emit('blur', newVal);
      if (this.global) {
        this.$eventBus.$emit('blur', newVal);
      }
    },

    handleChange() {
      const newVal = [
        this.parseTimeToMil(this.timeRange[0]),
        this.parseTimeToMil(this.timeRange[1]),
      ];
      this.$emit('change', newVal);
      if (this.global) {
        this.$eventBus.$emit('change', newVal);
      }
    },

    handleInput() {
      const newVal = [
        this.parseTimeToMil(this.timeRange[0]),
        this.parseTimeToMil(this.timeRange[1]),
      ];
      this.$emit('update:modelValue', newVal);
      if (this.global) {
        this.$eventBus.$emit('input', newVal);
      }
    },

    parseTimeToMil,
    parseMilToTime,
    parseTimeToString,
  },
};
</script>
