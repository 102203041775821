import Ordinals from '@/shared/services/ordinals.json';

export function capitalize(value) {
  if (!value) return '';
  const strVal = value.toString();
  return strVal.charAt(0).toUpperCase() + strVal.slice(1);
}

export function lowercase(value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toLowerCase() + value.slice(1);
}

export function toDate(value) {
  if (!value) return '';
  return new Date(value).toLocaleDateString('en', {
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'pst',
  });
}

export function ordinalize(value) {
  if (!value) return '';
  value = value.toString();
  return Ordinals[value];
}

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export function currency(value) {
  return currencyFormat.format(value);
}
