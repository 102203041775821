<template>
  <ResourceDialog
    @cancel="$emit('cancel', $event)"
    @save="$emit('confirm', $event)"
    ref="dialog"
    :cancel-button-text="cancelButtonText"
    :max-width="600"
    :save-button-text="confirmButtonText"
    :title="title"
    close-on-save
    scrim
  >
    <template #form="{ localValue }">
      <v-row v-if="localValue?.value">
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            class="mb-4"
            border
          >
            <v-card-title>
              <h3 class="fs-20">
                {{ $t('You entered') }}
              </h3>
            </v-card-title>
            <v-card-text data-testid="current-address">
              <p>
                {{ localValue.value[addressKey] }}
                <br />
                {{
                  `${localValue.value[cityKey]}, ${localValue.value[stateKey]} ${localValue.value[zipKey]}`
                }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-card
            class="mb-4"
            border
          >
            <v-card-title>
              <h3 class="fs-20">
                {{ $t('Recommended') }}
              </h3>
            </v-card-title>
            <v-card-text data-testid="recommended-address">
              <p>
                {{ localValue.standardizedAddress.firstAddressLine }}
                <br />
                {{ localValue.standardizedAddress.city }},
                {{ localValue.standardizedAddress.state }}
                {{ localValue.standardizedAddress.zipCode }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </ResourceDialog>
</template>

<script>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    ResourceDialog,
  },

  props: {
    addressKey: {
      default: 'home_address',
      type: String,
    },
    cancelButtonText: {
      default: 'Back',
      type: String,
    },
    cityKey: {
      default: 'home_city',
      type: String,
    },
    confirmButtonText: {
      default: 'Use recommended',
      type: String,
    },
    stateKey: {
      default: 'home_state',
      type: String,
    },
    title: {
      default: 'Verify address',
      type: String,
    },
    value: {
      default: null,
      type: Object,
    },
    zipKey: {
      default: 'home_zip',
      type: String,
    },
  },

  emits: ['cancel', 'confirm'],

  mounted() {
    if (this.value) this.open(this.value);
  },

  methods: {
    open(newValue) {
      this.$refs.dialog.open(newValue);
    },
  },
};
</script>
