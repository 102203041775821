import { ENROLLMENT_STATUSES as STATUSES, ROLES } from '@/shared/assets/constants';
import { useStore } from 'vuex';
import useTerms from '@/shared/composables/useTerms';

const STRICT_INVALID_STATUSES = {
  Placed: [STATUSES.ENROLLED, STATUSES.UNENROLLED, STATUSES.GRADUATED],
};

const PUBLISHES_NOTICE_STATUSES = [
  STATUSES.ACCEPTED,
  STATUSES.ENROLLED,
  STATUSES.GRADUATED,
  STATUSES.OFFERED,
  STATUSES.UNENROLLED,
  STATUSES.WITHDRAWN,
];

const ENROLLMENT_AGENT_STATUSES = [STATUSES.OFFERED, STATUSES.ACCEPTED, STATUSES.WITHDRAWN];

/**
 * @returns {
 *  allStatuses: () => array
 *  pendingStatuses: () => array
 *  releasedCurrentStatuses: () => array
 *  releasedNewStatuses: () => array
 *  removedStatuses: () => array
 *  getStatusText: (statusValue: string) => string
 *  getValidStatusChanges: (currentStatus: string, string: boolean) => array
 *  isPublishNoticeStatus: (status: string) => boolean
 * }
 */
export default function useEnrollmentStatuses() {
  const store = useStore();
  const { terms } = useTerms();

  const allStatuses = computed(() => {
    return pendingStatuses.value.concat(removedStatuses.value).concat(enrolledStatuses.value);
  });

  const enrolledStatuses = computed(() => {
    return filterExcludedEnrollmentStatuses([
      {
        text: terms.value.enrolled,
        value: STATUSES.ENROLLED,
        term: STATUSES.ENROLLED.toLowerCase(),
      },
      {
        text: terms.value.unenrolled,
        value: STATUSES.UNENROLLED,
        term: STATUSES.UNENROLLED.toLowerCase(),
      },
      {
        text: terms.value.graduated,
        value: STATUSES.GRADUATED,
        term: STATUSES.GRADUATED.toLowerCase(),
      },
    ]);
  });

  const pendingStatuses = computed(() => {
    return filterExcludedEnrollmentStatuses([
      {
        text: terms.value.selected,
        value: STATUSES.SELECTED,
        term: STATUSES.SELECTED.toLowerCase(),
      },
      {
        text: terms.value.proposed,
        value: STATUSES.PROPOSED,
        term: STATUSES.PROPOSED.toLowerCase(),
      },
      { text: terms.value.placed, value: STATUSES.PLACED, term: STATUSES.PLACED.toLowerCase() },
      { text: terms.value.offered, value: STATUSES.OFFERED, term: STATUSES.OFFERED.toLowerCase() },
      {
        text: terms.value.waitlisted,
        value: STATUSES.WAITLISTED,
        term: STATUSES.WAITLISTED.toLowerCase(),
      },
      {
        text: terms.value.accepted,
        value: STATUSES.ACCEPTED,
        term: STATUSES.ACCEPTED.toLowerCase(),
      },
    ]);
  });

  const releasedCurrentStatuses = computed(() => {
    return filterExcludedEnrollmentStatuses([
      { text: terms.value.offered, value: STATUSES.OFFERED, term: STATUSES.OFFERED.toLowerCase() },
      {
        text: terms.value.accepted,
        value: STATUSES.ACCEPTED,
        term: STATUSES.ACCEPTED.toLowerCase(),
      },
    ]);
  });

  const releasedNewStatuses = computed(() => {
    return filterExcludedEnrollmentStatuses([
      {
        text: terms.value.declined,
        value: STATUSES.DECLINED,
        term: STATUSES.DECLINED.toLowerCase(),
      },
      {
        text: terms.value.selected,
        value: STATUSES.SELECTED,
        term: STATUSES.SELECTED.toLowerCase(),
      },
      {
        text: terms.value.withdrawn,
        value: STATUSES.WITHDRAWN,
        term: STATUSES.WITHDRAWN.toLowerCase(),
      },
    ]);
  });

  const removedStatuses = computed(() => {
    return filterExcludedEnrollmentStatuses([
      {
        text: terms.value.declined,
        value: STATUSES.DECLINED,
        term: STATUSES.DECLINED.toLowerCase(),
      },
      {
        text: terms.value.withdrawn,
        value: STATUSES.WITHDRAWN,
        term: STATUSES.WITHDRAWN.toLowerCase(),
      },
      { text: terms.value.standby, value: STATUSES.STANDBY, term: STATUSES.STANDBY.toLowerCase() },
    ]);
  });

  function filterExcludedEnrollmentStatuses(statuses) {
    if (store.state.role === ROLES.MANAGER) {
      return statuses.filter(
        (status) =>
          !store.state.config.excluded_provider_enrollment_statuses.includes(status.value),
      );
    }
    if (store.state.role === ROLES.SPECIALIST) {
      return statuses.filter(
        (status) =>
          !store.state.config.excluded_specialist_enrollment_statuses.includes(status.value),
      );
    }
    return statuses;
  }

  function getStatusText(statusValue) {
    return allStatuses.value.find((status) => status.value === statusValue)?.text || statusValue;
  }

  function getValidStatusChanges(currentStatus, strict) {
    if (store.state.profile?.org_enrollments_edit) {
      if (!strict) return allStatuses.value;

      return allStatuses.value.map((status) => ({
        ...status,
        disabled: STRICT_INVALID_STATUSES[currentStatus]?.includes(status.value),
      }));
    }
    if (store.state.profile?.org_enrollments_agent) return ENROLLMENT_AGENT_STATUSES;
    return [];
  }

  function isPublishNoticeStatus(status) {
    return PUBLISHES_NOTICE_STATUSES.includes(status);
  }

  return {
    allStatuses,
    pendingStatuses,
    releasedCurrentStatuses,
    releasedNewStatuses,
    removedStatuses,

    getStatusText,
    getValidStatusChanges,
    isPublishNoticeStatus,
  };
}
