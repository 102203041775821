<template>
  <slot v-bind="slotProps" />
</template>

<script setup>
import { useIMask } from 'vue-imask';

const props = defineProps({
  mask: {
    type: String,
    default: null,
  },
});

const model = defineModel({ type: undefined });

const { el, mask, masked } = useIMask(
  computed(() => ({
    mask: props.mask,
    definitions: {
      '#': /\d/,
      A: /[a-zA-Z]/,
      N: /[a-zA-Z0-9]/,
      X: /./,
    },
    eager: true,
  })),
  {
    emit: false,
    defaultValue: model.value ?? '',
  },
);

const inputRef = ref();

const readonlyMasked = computed({
  get: () => (props.mask ? masked.value : model.value),
  set: (val) => {
    if (!props.mask) model.value = val;
  },
});
const slotProps = computed(() => ({
  inputRef,
  masked: readonlyMasked,
}));

let scope;
watch(
  () => props.mask,
  () => {
    if (!props.mask) {
      scope?.stop();
      scope = null;
    } else if (!scope) {
      scope = effectScope();
      scope.run(() => {
        watchEffect(() => {
          el.value = inputRef.value?.$el.querySelector('input');
        });
        onScopeDispose(() => {
          el.value = null;
        });
        watch(masked, (val) => {
          if (val !== model.value) {
            model.value = val;
          }
        });
        watch(
          model,
          (val) => {
            if (val !== masked.value && !mask.value?.el.isActive) {
              masked.value = val ?? '';
            }
          },
          { immediate: true },
        );
      });
    }
  },
  { immediate: true },
);
</script>
