import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default function useAgeCalculations() {
  const store = useStore();
  const { t } = useI18n();
  const siteAgeGroups = store.state.ageGroups.filter((group) => !group.hidden);

  function ageGroupFromDate(date, from) {
    return ageGroupFromMonths(dateToMonthsOld(date, from));
  }

  function monthsToAgeString(val) {
    if (val <= 0) return t('Newborn');
    if (val < 24) return [val, t('months')].join(' ');

    const years = parseInt(val / 12);
    const months = val % 12;

    if (months == 0) return [years, t('years')].join(' ');
    return [years, t('years'), months, t('months')].join(' ');
  }

  function ageGroupFromMonths(months) {
    if (months < 0) months = 0;
    return siteAgeGroups.find((group) => {
      return months >= group.min && months <= group.max;
    });
  }

  function dateToMonthsOld(date, from) {
    const today = from || new Date();
    const msOld = today - date;
    return parseInt(msOld / (1000 * 60 * 60 * 24 * 30.4));
  }

  return {
    ageGroupFromDate,
    monthsToAgeString,
  };
}
