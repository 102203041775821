import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/event_alert_configurations`;
  },

  create(eventAlert) {
    return axios.post(this.baseUrl(), { event_alert_configuration: eventAlert }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(`${this.baseUrl()}/${id}`).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  get(id) {
    return axios.get(`${this.baseUrl()}/${id}`).catch(handleError);
  },

  update(id, eventAlert) {
    return axios
      .patch(`${this.baseUrl()}/${id}`, {
        event_alert_configuration: eventAlert,
      })
      .catch(handleError);
  },
};
