import axios from 'axios';
import _ from 'lodash';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/subsidies`;
    if (id) url += `/${id}`;
    return url;
  },

  create(subsidy, success = _.identity, failure = _.identity) {
    return axios.post(this.baseUrl(), { subsidy }).then(success).catch(failure);
  },

  destroy(subsidyId, success = _.identity) {
    return axios.delete(this.baseUrl(subsidyId)).then(success);
  },

  downloadUrl(id, locale, filename) {
    return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf&locale=${locale}`;
  },

  get(subsidyId, success) {
    if (!success) return axios.get(this.baseUrl(subsidyId));

    axios.get(this.baseUrl(subsidyId)).then(success);
  },

  index(filters, success) {
    if (success) {
      axios.get(this.baseUrl(), { params: filters }).then(success);
    } else {
      return axios.get(this.baseUrl(), { params: filters });
    }
  },

  promiseDestroy(subsidyId) {
    return axios.delete(this.baseUrl(subsidyId));
  },

  update(subsidyId, subsidy, success, failure) {
    if (!success) return axios.patch(this.baseUrl(subsidyId), { subsidy }).catch(handleError);

    axios.patch(this.baseUrl(subsidyId), { subsidy }).then(success).catch(failure);
  },
};
