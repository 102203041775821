/**
 * Use the event bus to communicate messages to users.
 *
 * @returns {{
 *   chime: (data: any) => void,
 *   longChime: (data: any) => void,
 *   error: (data: any) => void,
 *   dispatch: (action: string, data: any) => void,
 * }}
 */
export default function useEventBus() {
  const eventBus = inject('eventBus');
  if (!eventBus) {
    throw new Error(
      'useEventBus() must be used inside a component with an eventBus provider. Something may have gone wrong with main.js.',
    );
  }

  function blur() {
    eventBus.$emit('blur');
  }

  function chime(data) {
    eventBus.$emit('chime', data);
  }

  function longChime(data) {
    eventBus.$emit('longChime', data);
  }

  function error(data) {
    eventBus.$emit('error', data);
  }

  function on(event, data) {
    eventBus.$on(event, data);
  }

  function off(event) {
    eventBus.$off(event);
  }

  /**
   * Use this method to dispatch an arbitrary event to the event bus.
   */
  function dispatch(action, data) {
    if (!action) {
      throw new Error('dispatch() must be called with an action');
    }
    if (['chime', 'error', 'longChime'].includes(action)) {
      throw new Error('dispatch() should not be used when another method is available');
    }

    eventBus.$emit(action, data);
  }

  return {
    blur,
    chime,
    longChime,
    error,
    dispatch,
    on,
    off,
  };
}
