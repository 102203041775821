<template>
  <v-card
    v-show="images && images.length > 0"
    class="pa-0"
    border
    flat
    tile
  >
    <v-carousel
      v-if="images"
      v-model="image_index"
      :show-arrows="images.length > 1"
      next-icon="chevron_right"
      prev-icon="chevron_left"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(image, i) in images"
        :key="i"
      >
        <div class="align-center d-flex fill-height justify-center">
          <v-img
            :src="image.url"
            max-height="500"
            eager
          />
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
import Api from '@/shared/services/bright_finder';

export default {
  compatConfig: { MODE: 3 },

  props: {
    elevation: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    provider_id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      images: [],
      image_index: 0,
    };
  },

  watch: {
    images() {
      this.image_index = 0;
    },

    '$route.params.providerId': {
      immediate: true,
      handler(newVal) {
        if (newVal) this.load();
      },
    },

    provider_id: {
      immediate: true,
      handler(newVal) {
        if (newVal) this.load();
      },
    },
  },

  methods: {
    load() {
      Api.public_api.provider.asset.index(
        this.provider_id || this.$route.params.providerId,
        { category: 'image' },
        (resp) => {
          this.images = resp.data;
        },
      );
    },
  },
};
</script>
