<template>
  <v-card>
    <v-card-title class="fs-20 fw-800">
      {{ $t('Virtual tour') }}
    </v-card-title>
    <v-card-text v-if="videos[0]">
      <v-img class="pointer">
        <video
          @click.stop.prevent="showPlayer = true"
          class="w-100pc"
        >
          <source :src="videos[0].url" />
        </video>
      </v-img>
    </v-card-text>

    <v-dialog v-model="showPlayer">
      <v-card
        border
        flat
        tile
      >
        <v-card-title>
          <v-row class="d-flex align-center">
            <span>{{ $t('Virtual tour') }}</span>
            <v-spacer />
            <v-btn
              @click="showPlayer = false"
              :aria-label="$t('Close')"
              icon="close"
              variant="text"
            />
          </v-row>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <VirtualTourGallery :provider-id="$route.params.providerId" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script setup>
import VirtualTourGallery from '@/shared/components/provider/VirtualTourGallery.vue';

defineProps({
  videos: {
    type: Array,
    default: null,
  },
});

const showPlayer = ref(false);
</script>
