<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-checkbox
      @update:model-value="handleUpdateModelValue"
      ref="check_box"
      :append-inner-icon="appendIcon"
      :density="dense ? 'compact' : undefined"
      :disabled="locked"
      :label="$t(label)"
      :model-value="localValue"
      class="mt-2"
      false-icon="check_box_outline_blank"
      true-icon="check_box"
      variant="filled"
      hide-details
      tile
      v-bind="attrsOmitDataPrefix"
    />
  </v-col>
</template>
<script>
import FilteredAttrs from '@/shared/mixins/FilteredAttrs';
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized, FilteredAttrs],
};
</script>
