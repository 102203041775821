<template>
  <v-row
    :class="rowClass"
    class="d-flex align-center"
  >
    <v-col
      class="d-flex align-center"
      cols="12"
      sm="9"
      tag="h1"
    >
      <div
        :class="titleContentClass"
        data-testid="title-content"
      >
        <template v-if="backBackName">
          <router-link
            v-if="backBackRoute"
            :class="textClass"
            :to="backBackRoute"
            class="fw-600 bc-link-hover td-none c-black"
            data-cy="back-back-button"
            >{{ $t(backBackName) }}</router-link
          >

          <span
            v-else
            :class="textClass"
            class="fw-600"
            data-cy="back-back-button"
          >
            {{ $t(backbackName) }}
          </span>

          <span
            v-text="'/'"
            :class="textClass"
            class="mx-2 fs-20"
          />
        </template>

        <template v-if="backName">
          <router-link
            v-if="backRoute"
            :class="textClass"
            :to="backRoute"
            class="fw-600 bc-link-hover td-none c-black"
            data-cy="back-button"
            >{{ $t(backName) }}</router-link
          >

          <span
            v-else
            :class="textClass"
            class="fw-600"
            data-cy="back-button"
          >
            {{ $t(backName) }}
          </span>

          <span
            v-text="'/'"
            :class="textClass"
            class="mx-2 fs-20"
          />
        </template>

        <slot name="name">
          <span
            v-text="name"
            :class="nameClass"
            class="fw-600 me-2"
            data-cy="page-title"
          />
        </slot>

        <slot name="append" />

        <slot name="inline-actions" />
      </div>
    </v-col>

    <v-col
      class="d-flex align-center justify-end"
      cols="12"
      sm="3"
    >
      <slot name="actions" />

      <v-btn
        v-if="createable"
        @click="$emit('create')"
        :prepend-icon="createIcon"
        color="primary"
        data-cy="title-bar-create-button"
        variant="flat"
      >
        {{ createText }}
      </v-btn>

      <v-text-field
        v-if="searchable"
        @update:model-value="$emit('query', $event)"
        class="ms-3"
        density="compact"
        prepend-inner-icon="search"
        variant="filled"
        hide-details
        rounded
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    backBackName: {
      type: String,
      default: null,
    },
    backBackRoute: {
      type: Object,
      default: null,
    },
    backName: {
      type: String,
      default: null,
    },
    backRoute: {
      type: Object,
      default: null,
    },
    createable: {
      type: Boolean,
      default: false,
    },
    createIcon: {
      type: String,
      default: 'add',
    },
    createText: {
      type: String,
      default: 'New',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    indented: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    shouldWrapText: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['create', 'query'],

  computed: {
    rowClass() {
      if (this.dense) return '';

      return 'mt-2 mb-4';
    },

    textClass() {
      if (this.$vuetify.display.smAndDown) {
        return 'fs-18';
      }
      if (this.$vuetify.display.mdAndDown) {
        return 'fs-24';
      }
      return 'fs-28 fw-800';
    },

    titleContentClass() {
      return this.indented ? 'ps-4' : null;
    },

    nameClass() {
      const classes = [this.textClass];
      if (!this.shouldWrapText) {
        classes.push('o-dotted');
      }
      return classes;
    },
  },
};
</script>
