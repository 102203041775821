<template>
  <div>
    <a
      @click="openPasswordResetDialog"
      :loading="processing"
      class="fw-500 pointer"
    >
      {{ $t('Forgot password?') }}
    </a>

    <ResourceDialog
      @cancel="closePasswordResetDialog()"
      @save="sendPasswordReset()"
      ref="confirmPasswordResetDialog"
      :cancel-button-text="$t('Cancel')"
      :content="$route.query.email_sent_to"
      :fields="passwordResetFields"
      :processing="processing"
      :save-button-text="$t('Confirm and send')"
      :title="$t('Send password to') + ':'"
    />
  </div>
</template>
<script setup>
import baseApi from '@/shared/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  scope: {
    type: String,
    default: null,
  },
});

const passwordResetFields = [{ value: 'email' }];
const processing = ref(null);
const confirmPasswordResetDialog = ref(null);

function openPasswordResetDialog() {
  return confirmPasswordResetDialog?.value.open();
}

function closePasswordResetDialog() {
  return confirmPasswordResetDialog?.value.close();
}

async function sendPasswordReset() {
  const email = confirmPasswordResetDialog.value.getLocalValue().email;
  if (!validateEmail(email)) return;

  processing.value = true;
  const resp = await baseApi.member.passwordReset({ email, scope: props.scope });
  processing.value = null;
  closePasswordResetDialog();
  if (resp.status !== 200) return;

  eventBus.chime(`An email with password reset instructions has been sent to ${email}`);
}

function validateEmail(email) {
  if (!email) {
    eventBus.error('Email is a required field');
    return false;
  }

  return true;
}
</script>
