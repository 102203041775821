import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/parent/enrollments/${id}`;
    return `${window.api_url}/parent/enrollments`;
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters }).catch(handleError);

    return axios.get(this.baseUrl(), { params: filters }).then(success).catch(handleError);
  },
};
