<template>
  <div
    class="mb-4 px-4"
    data-cy="message"
  >
    <template v-if="message.attachment_id">
      <v-btn
        @click="openAttachment"
        :class="
          message.member_id == currentMemberId
            ? 'bg-dark-blue c-white'
            : 'c-black bg-super-light-blue'
        "
        size="large"
        variant="flat"
      >
        <v-icon start> attachment </v-icon>
        <span
          v-text="message.text"
          class="fs-16 fw-500 message-container text-decoration-underline"
          data-cy="message-text"
        />
      </v-btn>
    </template>
    <template v-else>
      <div
        :class="
          message.member_id == currentMemberId
            ? 'bg-dark-blue c-white'
            : 'c-black bg-super-light-blue'
        "
        class="d-inline-block py-2 px-4 rounded-sm"
      >
        <MarkdownContent
          :content="message.text"
          class="fs-16 fw-500 message-container"
        />
      </div>
    </template>

    <div class="fs-12 c-light-black">
      <span
        v-if="message.member_id != currentMemberId"
        v-text="messageAuthor"
      />
      <span
        v-if="message.member_id != currentMemberId"
        class="me-1"
        >,</span
      >
      <LongDateTime :date="message.created_at" />
    </div>
  </div>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDateTime,
    MarkdownContent,
  },

  props: {
    message: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      currentMemberId: this.$store.state.profile.id,
    };
  },

  computed: {
    messageAuthor() {
      if (this.message.meta.member && this.message.meta.member.is_specialist) {
        return [this.message.meta.member.name, this.$store.state.brand.organization_name].join(
          ' @ ',
        );
      }
      return this.message.meta.member
        ? this.message.meta.member.name
        : this.message.meta.member_name;
    },
  },

  methods: {
    async openAttachment() {
      const resp = await Api.member.attachment.get(this.message.attachment_id);
      if (resp?.status !== 200) return;

      window.open(resp.data.url, '_blank');
    },
  },
};
</script>

<style>
.message-container p:not(:last-child) {
  margin-bottom: 12px;
}

.message-container p:last-child {
  margin-bottom: 0px;
}
</style>
