<template>
  <v-dialog
    v-model="visible"
    max-width="700"
    scrollable
  >
    <v-card
      :title="form?.name || undefined"
      tile
    >
      <template #append>
        <v-col>
          <v-btn
            @click="download"
            class="ms-1"
            color="primary"
            size="small"
            variant="flat"
          >
            {{ $t('Download') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            @click="close"
            icon="close"
            variant="text"
          />
        </v-col>
      </template>

      <v-card-text class="mnh-250">
        <template v-if="loaded">
          <QuestionSet
            v-model="form"
            :attachment-owner-id="form.id"
            :attachment-owner-type="'Form'"
            :attachments="attachments"
            :questions="formQuestions"
            :schema="schema.definition"
            key-name="question"
            attachment-readonly
            divided
            expanded
            hide-actions
            paddingless
            readonly
            very-dense
          />
        </template>

        <template v-else>
          <v-progress-linear
            class="my-8"
            indeterminate
          />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import { useStore } from 'vuex';

defineExpose({ open });

const props = defineProps({
  showUnpublishedQuestions: {
    type: Boolean,
    default: false,
  },
});

let attachments = ref([]);
let form = ref(null);
let ownerId = ref(null);
let ownerDataType = ref('Schema');
let schema = ref(null);
let visible = ref(false);

const { questions, questionsLoaded, validQuestions, validPublishedQuestions } = useQuestionable({
  ownerDataType,
  ownerId,
  syncedObject: form,
});

const store = useStore();

let loaded = computed(() => questionsLoaded.value && questions.value.length > 0);
let formQuestions = computed(() =>
  props.showUnpublishedQuestions ? validQuestions.value : validPublishedQuestions.value,
);

function close() {
  visible.value = false;
}

async function loadAttachments() {
  const { data } = await Api.manager.attachment.index({
    form_id: form.value.id,
    schema_id: schema.value.id,
  });
  attachments.value = data;
}

async function open(newForm) {
  form.value = newForm;
  schema.value = store.state.schemas[form.value.schema_id];
  ownerId.value = form.value.schema_id;
  visible.value = true;
  await loadAttachments();
}

function download() {
  const url = `${window.api_url}/manager/providers/${form.value.providerId}/forms/${form.value.id}.pdf`;
  const params = `filename=${encodeURIComponent(form.value.name)}.pdf`;
  window.open(`${url}?${params}`);
}
</script>
