<template>
  <v-tooltip
    v-if="showTooltip"
    location="bottom"
  >
    <template #activator="{ props }">
      <v-chip
        v-bind="props"
        :color="availabilityColor(program)"
      >
        {{ $t(availabilityLabel(program)) }}
      </v-chip>
    </template>
    <span>
      {{ $t(tooltipText) }}
    </span>
  </v-tooltip>

  <v-chip
    v-else
    :color="availabilityColor(program)"
  >
    {{ $t(availabilityLabel(program)) }}
  </v-chip>
</template>

<script setup>
defineProps({
  program: {
    type: Object,
    default: null,
  },
  tooltipText: {
    type: String,
    default:
      'Note: Some of these seats may require additional criteria. Please start an application to learn more.',
  },
  showTooltip: {
    type: Boolean,
    default: false,
  },
});

// The below are functions instead of computed for testing purposes
// see SeatsAvailability.test.js
function availabilityColor(programValue) {
  switch (programValue?.meta?.seat_availability_flag) {
    case 'available':
      return 'green accent-1';
    case 'low':
      return 'green accent-1';
    default:
      return 'yellow lighten-3';
  }
}

function availabilityLabel(programValue) {
  switch (programValue?.meta?.seat_availability_flag) {
    case 'available':
      return 'Seats available';
    case 'low':
      return 'Seats available - Low availability!';
    case 'none':
      return 'No seats available';
    default:
      return null;
  }
}
</script>
