<template>
  <v-card
    :elevation="mode == 'edit' ? 4 : 0"
    class="w-100pc h-100pc bg-transparent overflow-visible"
    tile
  >
    <template v-if="mode == 'edit'">
      <div class="p-absolute top-0 right-0 pa-1">
        <v-btn
          @click="$emit('destroy')"
          size="x-small"
          variant="text"
          icon
        >
          <v-icon>close</v-icon>
        </v-btn>
      </div>

      <div class="d-flex align-center p-absolute bottom-0 w-100pc">
        <v-row class="pa-2">
          <v-col>
            <v-icon class="draggable-handle"> drag_indicator </v-icon>
          </v-col>
          <v-col class="ta-right" />
        </v-row>
      </div>

      <div class="w-100pc h-100pc bg-extra-light-grey bb-4 bt-4 bc-primary">
        <template v-if="modelValue.type == 'text'">
          <textarea
            v-model="modelValue.text[currentLocale]"
            aria-label="Edit text content"
            class="pt-2 px-3 w-100pc h-100pc markdown-editor"
            data-testid="input-type-text-edit"
          />
        </template>

        <template v-if="modelValue.type == 'image'">
          <div class="px-2 d-flex align-center w-100pc h-100pc">
            <v-text-field
              v-model="modelValue.url"
              placeholder="Enter image URL"
              variant="solo"
              rounded
            />
          </div>
        </template>

        <template v-if="modelValue.type == 'button'">
          <div class="px-4 d-flex align-center justify-center w-100pc h-100pc">
            <v-text-field
              v-model="modelValue.text[currentLocale]"
              density="compact"
              placeholder="Enter label URL"
              variant="filled"
              hide-details
            />
            <v-text-field
              v-model="modelValue.url"
              density="compact"
              placeholder="Enter link URL"
              variant="filled"
              hide-details
            />
          </div>
        </template>
      </div>
    </template>

    <template v-if="mode == 'view'">
      <template v-if="modelValue.type == 'text'">
        <MarkdownContent
          :content="modelValue.text[currentLocale]"
          class="no-drag py-1"
        />
      </template>

      <template v-if="modelValue.type == 'image'">
        <v-img
          :src="modelValue.url"
          data-testid="image-content"
          max-height="100%"
          max-width="100%"
        />
      </template>

      <template v-if="modelValue.type == 'button'">
        <v-btn
          @click="handleClick"
          class="focus-very-visible"
          color="primary"
          size="x-large"
          tabindex="0"
          block
          data-skip-event
          rounded
        >
          <span>{{ modelValue.text[currentLocale] }}</span>
        </v-btn>
      </template>
    </template>
  </v-card>
</template>

<script setup>
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import useSegment from '@/shared/composables/analytics/useSegment';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const segment = useSegment();

const modelValue = defineModel();

const props = defineProps({
  lang: {
    type: String,
    default: null,
  },
  mode: {
    type: String,
    default: null,
  },
});

defineEmits(['destroy']);

const currentLocale = computed(
  () => props.lang || route.query.lang || locale.value || store.state.profile?.default_locale,
);

const handleClick = async () => {
  const destination = new URL(link.value);
  const isSameOrigin = window.location.origin === destination.origin;
  await track();
  await navigateSafely(isSameOrigin, destination.pathname);
};

const link = computed(() => {
  if (modelValue.value.type === 'button' && modelValue.value.url) {
    if (modelValue.value.url.includes('?')) {
      return `${modelValue.value.url}&lang=${currentLocale.value}`;
    }
    return `${modelValue.value.url}?lang=${currentLocale.value}`;
  }
  return null;
});

async function track() {
  await segment.track('Landing Page Link Clicked', {
    url: link.value,
  });
}

function navigateSafely(isSameOrigin, path) {
  if (isSameOrigin) {
    router.push({ path });
  } else {
    window.location.href = link.value;
  }
}
</script>

<style>
textarea.markdown-editor {
  border: none;
  resize: none;
  outline: none;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
}
</style>
