import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/schema_functions`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(schemaId, name) {
    return axios
      .post(this.baseUrl(), { schema_function: { name, schema_id: schemaId } })
      .catch(handleError);
  },

  async get(id) {
    return axios.get(this.baseUrl(id));
  },

  async index(schemaId) {
    return axios.get(this.baseUrl(), { params: { schema_id: schemaId } });
  },

  async update(id, newValues) {
    return axios.patch(this.baseUrl(id), { schema_function: newValues }).catch(handleError);
  },
};
