<template>
  <v-col
    v-if="enabled"
    :aria-label="$t('Enter a date of birth')"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row
      v-if="message"
      dense
    >
      <v-col
        class="labeled-input"
        cols="12"
      >
        <span>{{ $t(label) }}</span>
        <template v-if="mandatory">
          <required-indicator />
        </template>
      </v-col>
      <template v-if="description">
        <v-col
          class="fs-16 c-light-black my-1"
          cols="12"
        >
          <span>{{ $t(description) }}</span>
        </v-col>
      </template>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        lg="4"
        md="4"
      >
        <v-select
          v-model="month"
          :append-inner-icon="locked ? 'lock' : undefined"
          :aria-label="birthMonthAriaLabel"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :item-title="(i) => $t(i.text)"
          :items="monthOptions"
          :label="$t('Birth Month')"
          :readonly="readonly"
          color="primary"
          data-cy="birth_month"
          data-testid="birth_month"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="4"
      >
        <v-select
          v-model="day"
          :append-inner-icon="locked ? 'lock' : undefined"
          :aria-label="birthDayAriaLabel"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :item-title="(i) => $t(i.text)"
          :items="dayOptions"
          :label="$t('Birth Day')"
          :readonly="readonly"
          color="primary"
          data-cy="birth_day"
          data-testid="birth_day"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="4"
      >
        <v-select
          v-model="year"
          :append-inner-icon="locked ? 'lock' : undefined"
          :aria-label="birthYearAriaLabel"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :item-title="(i) => $t(i.text)"
          :items="yearOptions"
          :label="$t('Birth Year')"
          :readonly="readonly"
          color="primary"
          data-cy="birth_year"
          data-testid="birth_year"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import DateOptions from '@/shared/assets/date-options';
import Labeled from '@/shared/mixins/labeled';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import Schematized from '@/shared/mixins/schematized';

export default {
  compatConfig: { MODE: 3 },

  components: {
    RequiredIndicator,
  },

  mixins: [Labeled, Schematized],

  props: {
    readonly: Boolean,
  },

  data() {
    return {
      month: null,
      monthOptions: DateOptions.month_options,
      day: null,
      dayOptions: DateOptions.month_day_options,
      year: null,
      yearOptions: [],
    };
  },

  computed: {
    birthMonthAriaLabel() {
      const base = this.$t('Birth Month');
      const selectedMonth = this.monthOptions.find((month) => month.value === this.month);
      const monthText = selectedMonth ? this.$t(selectedMonth.text) : '';

      if (!this.mandatory) return monthText ? `${base}: ${monthText}` : base;

      return monthText ? `${base} - ${monthText} - required` : `${base} - required`;
    },

    birthDayAriaLabel() {
      const base = this.$t('Birth Day');
      const selectedDay = this.dayOptions.find((dayOption) => dayOption.value === this.day);
      const dayText = selectedDay ? this.$t(selectedDay.text) : '';
      if (!this.mandatory) return dayText ? `${base}: ${dayText}` : base;

      return dayText ? `${base} - ${dayText} - required` : `${base} - required`;
    },

    birthYearAriaLabel() {
      const base = this.$t('Birth Year');
      const selectedYear = this.yearOptions.find((year) => year.value === this.year);
      const yearText = selectedYear ? this.$t(selectedYear.text) : '';
      if (!this.mandatory) return yearText ? `${base}: ${yearText}` : base;

      return yearText ? `${base} - ${yearText} - required` : `${base} - required`;
    },
  },

  watch: {
    modelValue() {
      this.split();
    },

    day() {
      this.combine();
    },

    month() {
      this.combine();
    },

    year() {
      this.combine();
    },
  },

  created() {
    this.split();
    this.yearOptions = this.generateYearOptions();
  },

  methods: {
    combine() {
      if (this.year && this.month && this.day) {
        const newValue = [this.year, this.month, this.day].join('-');
        if (newValue === this.modelValue) return;

        this.handleUpdateModelValue(newValue);
        this.handleBlur(newValue);
      }
    },

    generateYearOptions() {
      let currentYear;
      if (this.propMaximum) {
        currentYear = new Date(this.propMaximum).getFullYear();
      } else {
        currentYear = new Date().getFullYear();
      }

      let lastYear;
      if (this.propMinimum) {
        lastYear = new Date(this.propMinimum).getFullYear();
      } else {
        lastYear = currentYear - 95;
      }

      const years = [];
      for (let i = currentYear; i >= lastYear; i -= 1) {
        years.push({ text: i.toString(), value: i.toString() });
      }
      return years;
    },

    split() {
      if (!this.modelValue) {
        this.month = null;
        this.day = null;
        this.year = null;
      } else if (this.modelValue.includes('/')) {
        const splitDob = this.modelValue.split('/');
        this.month = splitDob[0].padStart(2, '0');
        this.day = splitDob[1].padStart(2, '0');
        // eslint-disable-next-line prefer-destructuring
        this.year = splitDob[2];
      } else {
        const splitDob = this.modelValue.split('-');
        this.month = splitDob[1].padStart(2, '0');
        this.day = splitDob[2].padStart(2, '0');
        // eslint-disable-next-line prefer-destructuring
        this.year = splitDob[0];
      }
    },
  },
};
</script>
