import { independentSingleSelectStrategy } from 'vuetify/lib/composables/nested/selectStrategies.mjs';
import { wrapInArray } from 'vuetify/lib/util/index.mjs';

// The default list selection strategy always returns an array, this wraps it to return the first element instead
export function singleSelectStrategy(mandatory) {
  const parentStrategy = independentSingleSelectStrategy(mandatory);
  return {
    ...parentStrategy,
    in: (value, children, parents) => {
      let map = new Map();

      if (value != null) {
        map = parentStrategy.in(wrapInArray(value), children, parents);
      }

      return map;
    },
    out: (value, children, parents) => {
      return parentStrategy.out(value, children, parents)[0];
    },
  };
}
