import axios from 'axios';

export default {
  baseUrl() {
    return `${window.api_url}/public/organizations/${window.organization_id}/family_subsidy_programs`;
  },

  async get(id) {
    return axios.get(`${this.baseUrl()}/${id}`);
  },

  async index(filters) {
    return axios.get(this.baseUrl(), { params: filters });
  },
};
