import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  upload(formData) {
    return axios
      .post(`${window.api_url}/organization/iep_referrals/upload`, { iep_referral: formData })
      .catch(handleError);
  },
};
