import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/task_types`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(task_type) {
    return axios.post(this.baseUrl(), { task_type }).catch(handleError);
  },

  async destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },

  async index() {
    return axios.get(this.baseUrl()).catch(handleError);
  },

  async update(id, task_type) {
    return axios.patch(this.baseUrl(id), { task_type }).catch(handleError);
  },
};
