import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/enrollments`;
  },

  create(enrollment, success, failure) {
    if (!success) return axios.post(this.baseUrl(), { enrollment }).catch(handleError);
    return axios.post(this.baseUrl(), { enrollment }).then(success).catch(failure);
  },

  destroy(id) {
    return axios.delete(`${this.baseUrl()}/${id}`).catch(handleError);
  },

  downloadUrl(filters, filename) {
    const urlParams = { ...filters, filename };
    return [`${this.baseUrl()}.csv`, new URLSearchParams(urlParams).toString()].join('?');
  },

  index(filters, success) {
    if (!success) return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
    return axios.get(this.baseUrl(), { params: filters }).then(success);
  },

  get(id, success) {
    if (!success) return axios.get(`${this.baseUrl()}/${id}`).catch(handleError);
    return axios.get(`${this.baseUrl()}/${id}`).then(success);
  },

  publish(subsidyId) {
    return axios.post(`${this.baseUrl()}/publish`, { subsidy_id: subsidyId }).catch(handleError);
  },

  reorder(enrollmentId, params) {
    const reorderUrl = `${this.baseUrl()}/${enrollmentId}/reorder`;
    return axios.post(reorderUrl, params).catch(handleError);
  },

  update(id, enrollment, success, failure) {
    if (!success) return axios.patch(`${this.baseUrl()}/${id}`, { enrollment }).catch(handleError);
    return axios.patch(`${this.baseUrl()}/${id}`, { enrollment }).then(success).catch(failure);
  },
};
