import axios from 'axios';

export default {
  baseUrl() {
    return `${window.api_url}/organization/family_subsidy_programs`;
  },

  async create(family_subsidy_program) {
    return axios.post(this.baseUrl(), { family_subsidy_program });
  },

  async get(id) {
    return axios.get(`${this.baseUrl()}/${id}`);
  },

  async index() {
    return axios.get(this.baseUrl());
  },

  async update(family_subsidy_program) {
    return axios.patch(`${this.baseUrl()}/${family_subsidy_program.id}`, {
      family_subsidy_program,
    });
  },

  async destroy(family_subsidy_program) {
    return axios.delete(`${this.baseUrl()}/${family_subsidy_program.id}`);
  },
};
