import { updateQuery } from '@/shared/services/router';

/**
 * @deprecated This mixin is replaced by useRouterHelper.js
 * @example ```vue
 * <script setup>
 * import useRouterHelper from '@/shared/composables/useRouterHelper';
 *
 * const { getFiltersFromQuery, updateQuery } = useRouterHelper();
 * </script>
 * ```
 */
export default {
  methods: {
    getFiltersFromQuery({ paged }) {
      const newFilters = { ...this.$route.query };
      if (paged) newFilters.page = parseInt(newFilters.page || 1, 10);
      return newFilters;
    },

    async updateQuery(newQuery) {
      await updateQuery(this.$router, this.$route, newQuery);
    },
  },
};
