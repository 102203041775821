import axios from 'axios';

export default {
  base_url() {
    return `${window.api_url}/member/login_codes`;
  },

  create(loginCode, success, failure) {
    return axios.post(this.base_url(), { login_code: loginCode }).then(success).catch(failure);
  },
};
