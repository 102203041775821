import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/manager/attachments`;
  },

  create(attachment) {
    return axios.post(this.baseUrl(), { attachment }).catch(handleError);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(`${this.baseUrl()}/${id}`).catch(handleError);
  },
};
