<template>
  <v-col
    v-if="propertiesExist"
    :cols="computedCols"
    :data-cols="cols"
    :data-name="attributeName"
    :data-role="attributeProperties?.role"
    :lg="lg"
    :md="md"
    data-cy="defined-property-element"
    data-testid="defined-property-element"
  >
    <slot
      :custom-attribute="maybeCustomAttribute"
      :properties="attributeProperties"
    />
  </v-col>
</template>

<script>
import _ from 'lodash';

export default {
  compatConfig: { MODE: 2 },

  props: {
    attributeName: {
      type: String,
      default: null,
    },
    cols: {
      type: String,
      default: undefined,
    },
    customAttribute: {
      type: String,
      default: null,
    },
    definition: {
      type: Object,
      default: () => ({}),
    },
    lg: {
      type: String,
      default: undefined,
    },
    md: {
      type: String,
      default: undefined,
    },
    properties: {
      type: Object,
      default: null,
    },
  },

  computed: {
    computedCols() {
      if (this.cols) return this.cols;

      if (this.$vuetify.display.smAndDown) return '12';

      if (this.attributeProperties.role === 'text-long') return '12';

      if (this.attributeProperties.format === 'date') return '12';

      return null;
    },

    maybeCustomAttribute() {
      if (this.customAttribute) return this.customAttribute;

      if (this.attributeName?.includes('custom')) return this.attributeName.split('.')[1];

      return null;
    },

    attributeProperties() {
      if (this.properties) return this.properties;

      const isCustomAttribute = this.maybeCustomAttribute;

      if (isCustomAttribute)
        return this.definition.properties.custom.properties[isCustomAttribute] || {};

      return this.definition.properties[this.attributeName] || {};
    },

    propertiesExist() {
      return !_.isEmpty(this.attributeProperties);
    },
  },
};
</script>
