import axios from 'axios';

export default {
  base_url(group_id) {
    return `${window.api_url}/organization/groups/${group_id}/group_activities`;
  },

  create(group_id, group_activity, success, failure) {
    axios.post(this.base_url(group_id), { group_activity }).then(success).catch(failure);
  },

  destroy(group_id, groupActivityTypeId, success) {
    axios.delete(`${this.base_url(group_id)}/${groupActivityTypeId}`).then(success);
  },

  index(group_id, success) {
    axios.get(this.base_url(group_id)).then(success);
  },

  update(group_id, groupActivityTypeId, group_activity, success, failure) {
    axios
      .patch(`${this.base_url(group_id)}/${groupActivityTypeId}`, { group_activity })
      .then(success)
      .catch(failure);
  },

  file: {
    download(group_id, group_activity_id, file_id, file_name) {
      const url = `${window.api_url}/organization/groups/${group_id}/group_activities/${group_activity_id}/group_activity_files/${file_id}`;
      axios.get(url, { responseType: 'blob' }).then((resp) => {
        const respUrl = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = respUrl;
        link.setAttribute('download', file_name); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
