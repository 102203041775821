import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/member/comments`;
    if (id) url += `/${id}`;
    return url;
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  create(cohort) {
    return axios.post(this.baseUrl(), cohort).catch(handleError);
  },

  update(id, data) {
    return axios.patch(this.baseUrl(id, data).catch(handleError));
  },

  destroy(id, params) {
    return axios.delete(this.baseUrl(id), { data: params }).catch(handleError);
  },
};
