export default {
  extract(obj, fields) {
    const newObject = {};
    fields.forEach((field) => {
      if (field.includes('.')) {
        const nestedField = field.split('.');
        newObject[nestedField[0]] ||= {};
        newObject[nestedField[0]][nestedField[1]] = obj[nestedField[0]][nestedField[1]];
      } else {
        newObject[field] = obj[field];
      }
    });
    return newObject;
  },
};
