<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <template v-if="message">
      <v-row dense>
        <v-col
          :aria-label="$t(message)"
          class="labeled-input"
        >
          {{ $t(message) }}
        </v-col>
        <template v-if="description">
          <v-col
            class="fs-16 c-light-black my-1"
            cols="12"
          >
            <span>{{ $t(description) }}</span>
          </v-col>
        </template>
      </v-row>
    </template>
    <v-row
      v-if="maximum == undefined && minimum == undefined"
      density="compact"
    >
      <v-col
        cols="12"
        lg="4"
        md="4"
      >
        <v-select
          v-model="month"
          @update:model-value="combine"
          :append-inner-icon="appendIcon"
          :aria-label="monthAriaLabel"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :item-title="(item) => $t(item.text)"
          :items="months"
          :label="$t('Month')"
          :readonly="readonly"
          color="primary"
          data-cy="month-select"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="4"
      >
        <v-select
          v-model="day"
          @update:model-value="combine"
          :append-inner-icon="appendIcon"
          :aria-label="dayAriaLabel"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :item-title="(item) => $t(item.text)"
          :items="$a.assets.month_day_options"
          :label="$t('Day')"
          :readonly="readonly"
          color="primary"
          data-cy="day-select"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="4"
      >
        <v-select
          v-model="year"
          @update:model-value="combine"
          :append-inner-icon="appendIcon"
          :aria-label="yearAriaLabel"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :item-title="(item) => $t(item.text)"
          :items="year_options"
          :label="$t('Year')"
          :readonly="readonly"
          color="primary"
          data-cy="year-select"
          variant="filled"
          hide-details
          round
          tile
        />
      </v-col>

      <template v-if="clearable">
        <v-col cols="12">
          <v-btn
            @click="clear"
            color="primary"
            size="small"
          >
            <span>{{ $t('Clear') }}</span>
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <v-row
      v-else
      density="compact"
    >
      <v-col cols="12">
        <v-text-field
          @click="maybeOpenOrCloseDialog"
          @click:clear="clear()"
          :append-inner-icon="locked ? 'lock' : ''"
          :aria-label="$t(ariaLabel)"
          :density="dense ? 'compact' : undefined"
          :disabled="locked"
          :model-value="localeDateString"
          data-cy="labeled-date-picker"
          prepend-inner-icon="event"
          variant="filled"
          clearable
          hide-details
          readonly
          tile
          v-bind="attrsOmitDataPrefix"
        />

        <v-dialog
          v-model="openDateDialog"
          width="320px"
        >
          <v-date-picker
            v-model="dateModel"
            :header="$t('Enter date')"
            :max="maximum || null"
            :min="minimum || null"
            :title="$t('Select date')"
            scrollable
          />
        </v-dialog>
      </v-col>
    </v-row>

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script>
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';
import { isDateFormatValid } from '@/shared/services/date-helper';
import FilteredAttrs from '@/shared/mixins/FilteredAttrs';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized, FilteredAttrs],

  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    dividedBottom: {
      type: Boolean,
      default: false,
    },
    maximum: {
      default: null,
      validator(value) {
        if (!value) {
          return true;
        }
        return isDateFormatValid({ name: 'maximum', value });
      },
    },
    minimum: {
      default: null,
      validator(value) {
        if (!value) {
          return true;
        }
        return isDateFormatValid({ name: 'minimum', value });
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      month: null,
      day: null,
      openDateDialog: false,
      year: null,
      year_options: this.generateYearOptions(),
    };
  },

  computed: {
    dateModel: {
      get() {
        if (!this.localValue) return null;

        return this.$vuetify.date.parseISO(this.localValue);
      },
      set(val) {
        this.openDateDialog = false;
        this.handleUpdateModelValue(this.$vuetify.date.toISO(val));
      },
    },

    localeDateString() {
      if (!this.localValue) return null;
      return new Date(this.localValue).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'utc',
      });
    },

    months() {
      return this.$a.assets.month_options.map((monthOption) => ({
        text: this.$t(monthOption.text),
        value: monthOption.value,
      }));
    },

    monthAriaLabel() {
      const base = this.$t('Month');
      const selectedMonth = this.months.find((month) => month.value === this.month);
      const selectedMonthText = selectedMonth ? selectedMonth.text : '';
      if (!this.mandatory) return selectedMonthText ? `${base}: ${selectedMonthText}` : base;

      return selectedMonth ? `${base}: ${selectedMonth} - required` : `${base} - required`;
    },

    dayAriaLabel() {
      const base = this.$t('Day');
      const selectedDay = this.day;
      if (!this.mandatory) return selectedDay ? `${base}: ${selectedDay}` : base;

      return selectedDay ? `${base}: ${selectedDay} - required` : `${base} - required`;
    },

    yearAriaLabel() {
      const base = this.$t('Year');
      const selectedYear = this.year;
      if (!this.mandatory) return selectedYear ? `${base}: ${selectedYear}` : base;

      return selectedYear ? `${base}: ${selectedYear} - required` : `${base} - required`;
    },
  },

  watch: {
    modelValue(newVal) {
      if (newVal) {
        this.split();
      }
    },
  },

  created() {
    if (this.modelValue) this.split();
  },

  methods: {
    clear() {
      this.year = null;
      this.month = null;
      this.day = null;
      this.handleUpdateModelValue(null);
      this.handleBlur(null);
    },

    combine() {
      if (this.year && this.month && this.day) {
        const newValue = [this.year, this.month, this.day].join('-');
        this.handleUpdateModelValue(newValue);
        this.handleBlur(newValue);
      }
    },

    generateYearOptions() {
      let currentYear;
      if (this.maximum) {
        currentYear = new Date(this.maximum).getFullYear();
      } else {
        currentYear = new Date().getFullYear();
      }

      let lastYear;
      if (this.minimum) {
        lastYear = new Date(this.minimum).getFullYear();
      } else {
        lastYear = currentYear - 95;
      }

      const years = [];
      for (let index = currentYear; index >= lastYear; index -= 1) {
        years.push({ text: index.toString(), value: index.toString() });
      }
      return years;
    },

    maybeOpenOrCloseDialog() {
      if (this.locked || this.maximum === false || this.minimum === false) return;

      this.openDateDialog = !this.openDateDialog;
    },

    split() {
      if (!this.modelValue) return;

      if (this.modelValue.includes('/')) {
        const splitDob = this.modelValue.split('/');
        this.month = splitDob[0].padStart(2, '0');
        this.day = splitDob[1].padStart(2, '0');
        // eslint-disable-next-line prefer-destructuring
        this.year = splitDob[2];
      } else {
        const splitDob = this.modelValue.split('-');
        this.month = splitDob[1].padStart(2, '0');
        this.day = splitDob[2].padStart(2, '0');
        // eslint-disable-next-line prefer-destructuring
        this.year = splitDob[0];
      }
    },
  },
};
</script>
