<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col
        class="labeled-input"
        cols="12"
      >
        <span>{{ $t(label) }}</span>
      </v-col>
      <template v-if="description">
        <v-col
          class="fs-16 c-light-black my-1"
          cols="12"
        >
          <span>{{ $t(description) }}</span>
        </v-col>
      </template>
      <v-col>
        <v-combobox
          @blur="handleBlur"
          @update:model-value="handleUpdateModelValue"
          :append-inner-icon="appendIcon"
          :aria-label="label"
          :chips="chips"
          :closable-chips="deletableChips"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked"
          :model-value="localValue"
          :multiple="multiple"
          variant="filled"
          data-cy
          hide-details
          tile
          v-bind="attrsOmitDataPrefix"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import FilteredAttrs from '@/shared/mixins/FilteredAttrs';
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized, FilteredAttrs],

  props: {
    chips: Boolean,
    description: {
      type: String,
      default: null,
    },
    deletableChips: Boolean,
    multiple: Boolean,
  },
};
</script>
