import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/parent/family_subsidies`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(familySubsidy) {
    return axios.post(this.baseUrl(), { family_subsidy: familySubsidy });
  },

  async get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  async index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  async update(familySubsidy) {
    return axios
      .patch(`${this.baseUrl()}/${familySubsidy.id}`, { family_subsidy: familySubsidy })
      .catch(handleError);
  },

  async submit(familySubsidy, params) {
    return axios.patch(`${this.baseUrl()}/${familySubsidy.id}/submit`, params).catch(handleError);
  },

  downloadUrl(id, locale, filename) {
    return `${this.baseUrl(id)}.pdf?locale=${locale}&filename=${encodeURIComponent(filename)}.pdf`;
  },
};
