import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(schemaId) {
    let url = `${window.api_url}/organization/schemas/${schemaId}/schema_properties`;
    return url;
  },

  upload(schemaId, upload) {
    return axios.post(`${this.baseUrl(schemaId)}/upload`, { upload }).catch(handleError);
  },
};
