<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col
        :class="labelClass"
        cols="12"
      >
        {{ $t(label) }}
        <RequiredIndicator v-if="mandatory" />

        <v-tooltip
          v-if="tooltipText"
          location="bottom"
        >
          <template #activator="tooltipOptions">
            <div>
              <v-icon
                v-bind="tooltipOptions.props"
                :color="tooltipColor"
                :icon="tooltipIcon"
                class="d-inline ps-2 pt-0 pb-2"
              />
            </div>
          </template>

          <span>
            {{ $t(tooltipText) }}
          </span>
        </v-tooltip>
      </v-col>

      <v-col>
        <v-autocomplete
          v-model="localValue"
          @blur="handleBlur"
          @update:model-value="handleUpdateModelValue"
          @update:search="$emit('update:search', $event)"
          @vue:mounted="$emit('update:search', '')"
          :append-inner-icon="appendIcon"
          :aria-label="ariaLabel"
          :chips="chips || undefined"
          :closable-chips="deletableChips || undefined"
          v-bind="filteredAttrs"
          :close-text="label"
          :density="dense || veryDense ? 'compact' : undefined"
          :disabled="locked || undefined"
          :item-title="itemTitle"
          :item-value="itemValue"
          :items="items"
          :loading="loading || undefined"
          :open-text="label"
          data-cy="labeled-select"
          data-testid="labeled-select"
          variant="filled"
          hide-details
          tile
        />
      </v-col>
    </v-row>

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script setup>
import attrsOmitDataPrefix from '@/shared/utils/filteredAttrs';
import propsToRefs from '@/shared/utils/propsToRefs';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import useLabeledField from '@/shared/composables/useLabeledField';
import useSchematizedField from '@/shared/composables/useSchematizedField';
import { onUpdated } from 'vue';

const localValue = defineModel({ type: undefined });

const props = defineProps({
  chips: {
    type: Boolean,
    default: undefined,
  },
  deletableChips: {
    type: Boolean,
    default: undefined,
  },
  dividedBottom: {
    type: Boolean,
    default: undefined,
  },
  items: {
    type: Array,
    default: () => [],
  },
  itemTitle: {
    type: [String, Function],
    default: undefined,
  },
  itemValue: {
    type: String,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: undefined,
  },
  tooltipColor: {
    type: String,
    default: null,
  },
  tooltipIcon: {
    type: String,
    default: 'info',
  },
  tooltipText: {
    type: String,
    default: null,
  },
  ...useLabeledField.props,
  ...useSchematizedField.props,
});

const attrs = useAttrs();
const emit = defineEmits(['update:search'].concat(useLabeledField.emits));
defineExpose({ emit }); // Emit is used in composable, this is included for linter

const filteredAttrs = ref(attrsOmitDataPrefix(attrs));

const { appendIcon, labelClass, handleBlur, handleUpdateModelValue } = useLabeledField(
  ...propsToRefs(props, useLabeledField.paramKeys),
);
const { ariaLabel, enabled, label, locked } = useSchematizedField(
  ...propsToRefs(props, useSchematizedField.paramKeys),
);

onUpdated(() => {
  filteredAttrs.value = attrsOmitDataPrefix(attrs);
});
</script>
