import axios from 'axios';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/organization/public_schemas`;
    if (id) url += `/${id}`;
    return url;
  },

  async index() {
    return axios.get(this.baseUrl());
  },
};
