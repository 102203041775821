/**
 * @deprecated This mixin is replaced by useSchematizedField.js
 */
export default {
  props: {
    field: String,
    hardLock: Boolean,
    hardLockMessage: {
      type: String,
      default: 'Locked',
    },
    mandatory: Boolean,
    message: String,
    schemaId: String,
  },

  data() {
    return {
      prop: this.getProp(),
    };
  },

  computed: {
    enabled() {
      if (!this.field) {
        return true;
      }
      if (this.prop) {
        return this.prop.enabled;
      }
    },

    ariaLabel() {
      if (!this.label) return null;

      if (!this.mandatory) return this.$t(this.label);

      return `${this.$t(this.label)} - ${this.$t('required')}`;
    },

    label() {
      if (this.message) {
        return this.message || '';
      }
      if (this.prop) {
        return this.prop.alias || this.prop.title || '';
      }
    },

    locked() {
      if (this.hardLock) {
        return true;
      }
      if (this.prop) {
        return !(this.prop.editRoles || []).includes(this.$role);
      }
      return false;
    },

    propMaximum() {
      if (this.prop) {
        return this.prop.maximum;
      }
      return null;
    },

    propMinimum() {
      if (this.prop) {
        return this.prop.minimum;
      }
      return null;
    },

    propMask() {
      return this.prop?.mask || null;
    },
  },

  methods: {
    getProp() {
      if (this.field) {
        return (
          this.namespace(
            this.$store.state.schemas[this.schemaId].definition.properties,
            this.field,
          ) || {}
        );
      }
    },

    namespace(object, path) {
      return path.split('.').reduce((value, index) => value[index], object);
    },
  },
};
