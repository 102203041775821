<template>
  <v-row dense>
    <v-col>
      <v-btn
        @click="$emit('click')"
        :loading="processing"
        color="primary"
        size="large"
        variant="flat"
      >
        <v-icon :start="!!text"> add </v-icon>
        <span v-if="!!text">
          {{ $t(text) }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
  },

  emits: ['click'],
};
</script>
