import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/v2/cohort_lessons`;
    if (id) url += `/${id}`;
    return url;
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  create(cohortLesson) {
    return axios.post(this.baseUrl(), cohortLesson).catch(handleError);
  },

  update(id, data) {
    return axios.patch(this.baseUrl(id), { data }).catch(handleError);
  },
};
