import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    if (id) return `${window.api_url}/organization/grant_programs/${id}`;
    return `${window.api_url}/organization/grant_programs`;
  },

  create(grantProgram, success, failure) {
    if (!success)
      return axios.post(this.baseUrl(), { grant_program: grantProgram }).catch(handleError);
    axios.post(this.baseUrl(), { grant_program: grantProgram }).then(success).catch(failure);
  },

  destroy(grantProgramId, success) {
    axios.delete(this.baseUrl(grantProgramId)).then(success);
  },

  get(grantProgramId, success) {
    axios.get(this.baseUrl(grantProgramId)).then(success);
  },

  index(success) {
    if (!success) return axios.get(this.baseUrl()).catch(handleError);
    return axios.get(this.baseUrl()).then(success);
  },

  update(id, grantProgram, success, failure) {
    axios.patch(this.baseUrl(id), { grant_program: grantProgram }).then(success).catch(failure);
  },
};
