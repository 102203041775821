import Api from '@/shared/services/all_bright_finder';

/**
 * @deprecated Avoid using this mixin. Use the API directly in your components.
 *
 * Consider importing from the API relevant to your component, e.g.
 *
 * import Api from '@/admin/services/bright_finder';
 *
 * import Api from '@/shared/services/bright_finder';
 *
 * import Api from '@/manager/services/bright_finder';
 *
 * import Api from '@/specialist/services/bright_finder';
 *
 * import Api from '@/shared/services/all_bright_finder';
 */
export default {
  props: {
    api: {
      type: Object,
      default: () => Api,
    },
  },
};
