import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  base_url() {
    return `${window.api_url}/organization/attachments`;
  },

  create(attachment) {
    return axios.post(this.base_url(), { attachment }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(`${this.base_url()}/${id}`).catch(handleError);
  },

  index(params) {
    return axios.get(this.base_url(), { params }).catch(handleError);
  },

  get(id) {
    return axios.get(`${this.base_url()}/${id}`).catch(handleError);
  },

  update(id, attachment) {
    return axios.patch(`${this.base_url()}/${id}`, { attachment }).catch(handleError);
  },
};
