<template>
  <v-dialog
    v-model="visible"
    max-width="800"
    persistent
  >
    <v-card
      border
      tile
    >
      <template v-if="title || !persistent">
        <v-card-title>
          <v-row>
            <template v-if="title">
              <v-col>
                <span>
                  {{ $t(title) }}
                </span>
              </v-col>
            </template>

            <template v-if="!persistent">
              <v-col class="d-flex justify-end">
                <v-btn
                  @click="close"
                  variant="text"
                  icon
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>

        <v-divider />
      </template>

      <v-card-text class="mnh-250 mt-4">
        <template v-if="loaded">
          <QuestionSet
            @back="backFromQuestion($event)"
            @change:attachments="loadAttachments"
            @next="forwardFromQuestion($event)"
            :attachment-group-id="groupId"
            :attachment-owner-id="form?.id"
            :attachment-owner-type="'Form'"
            :attachments="attachments"
            :model-value="form"
            :processing="processing"
            :questions="formQuestions"
            :readonly="form.locked"
            :schema="schema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <template v-else>
          <v-progress-linear
            class="my-8"
            indeterminate
          />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import useQuestionable from '@/shared/composables/useQuestionable';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useStepper from '@/shared/composables/useStepper';
import { useStore } from 'vuex';
import useEventBus from '@/shared/composables/useEventBus';

defineExpose({ open, blankForm });

const props = defineProps({
  draft: {
    type: Boolean,
    default: false,
  },
  enrollmentId: {
    type: String,
    default: null,
  },
  groupId: {
    type: String,
    default: null,
  },
  schemaId: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  persistent: {
    default: true,
    type: Boolean,
  },
  providerId: {
    type: String,
    default: null,
  },
  showUnpublishedQuestions: {
    type: Boolean,
    default: false,
  },
  subsidyId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close', 'save']);

let attachments = ref([]);
let form = ref(null);
let ownerId = ref(null);
let ownerDataType = ref('Schema');
let processing = ref(false);
let schema = ref(null);
let visible = ref(undefined);

const role = computed(() => store.state.role);

const {
  loadQuestions,
  questions,
  questionsLoaded,
  validQuestions,
  validPublishedQuestions,
  validateAnswers,
} = useQuestionable({
  ownerDataType,
  ownerId,
  syncedObject: form,
});

const { updateQuery } = useRouterHelper();
const eventBus = useEventBus();

const { section, transitionName } = useStepper({
  processing,
  updateQuery,
});

const store = useStore();

const loaded = computed(
  () => form.value && schema.value && questionsLoaded.value && questions.value?.length > 0,
);

let formQuestions = computed(() =>
  props.showUnpublishedQuestions ? validQuestions.value : validPublishedQuestions.value,
);

async function backFromQuestion(index) {
  processing.value = true;

  if (index - 1 < 0) {
    processing.value = false;
    await close();
  } else {
    processing.value = false;
    section.value = null;
    setTimeout(() => {
      section.value = `question-${index - 1}`;
    }, 600);
  }
}

function blankForm() {
  return {
    custom: {},
    enrollment_id: props.enrollmentId,
    group_id: props.groupId,
    provider_id: props.providerId,
    schema_id: props.schemaId,
    subsidy_id: props.subsidyId,
  };
}

async function close() {
  visible.value = undefined;
  form.value = null;
  schema.value = null;
  await updateQuery({ section: null, step: null });
  emit('close');
}

async function forwardFromQuestion(index) {
  processing.value = true;

  await validateAnswers();
  const submitted = index + 1 >= formQuestions.value.length;
  if (submitted) form.value.submitted = true;
  if (!form.value.provider_id) form.value.provider_id = props.providerId;

  let response = null;
  // What are you...
  switch (role.value) {
    case 'manager':
      if (form.value.id) {
        response = await Api.manager.provider.form.promiseUpdate(
          props.providerId,
          form.value.id,
          form.value,
        );
      } else {
        response = await Api.manager.provider.form
          .create(props.providerId, form.value)
          .catch((error) => eventBus.error(error));
      }
      break;
    case 'specialist':
      if (!submitted) break;

      form.value.name = schema.value.name;
      response = await Api.organization.form
        .create(form.value)
        .catch((error) => eventBus.error(error));
      break;
    case 'parent':
      if (!submitted) break;

      if (form.value.id) {
        response = await Api.parent.form
          .update(form.value.id, form.value)
          .catch((error) => eventBus.error(error));
      } else {
        response = await Api.parent.form.create(form.value).catch((error) => eventBus.error(error));
      }
      break;
    default:
      eventBus.error('Invalid role');
      return;
  }

  if (submitted) {
    processing.value = false;
    if (!response?.data) return;

    emit('save', response.data);
    await close();
  } else {
    section.value = null;
    setTimeout(() => {
      section.value = `question-${index + 1}`;
    }, 600);

    processing.value = false;
  }
}

async function loadAttachments() {
  const response = await Api.member.attachment.index({
    owner_type: 'Form',
    owner_id: form.value.id,
  });
  if (response?.status !== 200) return;

  attachments.value = response.data;
}

async function open(newForm = {}) {
  await updateQuery({ section: 'question-0', step: 1 });
  schema.value = store.state.schemas[props.schemaId];
  ownerId.value = props.schemaId;

  if (props.draft) {
    let response = null;

    switch (role.value) {
      case 'manager':
        if (newForm.id) {
          response = await Api.manager.form.get(newForm.id);
        } else {
          response = await Api.manager.provider.form
            .create(props.providerId, blankForm())
            .catch((error) => eventBus.error(error));
        }
        break;
      case 'parent':
        response = await Api.parent.form
          .create(blankForm())
          .catch((error) => eventBus.error(error));
        break;
      default:
        eventBus.error('Invalid role');
    }

    if (![200, 201].includes(response?.status)) return;

    form.value = response.data;

    // Deconstruct V2
    if (form.value.data) {
      form.value = {
        id: form.value.data.id,
        provider_id: form.value.data.relationships.provider.data.id,
        schema_id: form.value.data.relationships.schema.data.id,
        ...form.value.data.attributes,
      };
    }

    await loadAttachments();
  } else {
    form.value = blankForm();
  }

  await loadQuestions();
  section.value = 'question-0';
  visible.value = true;
}
</script>
