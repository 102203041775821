<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="fs-20 fw-800">{{ $t('Highlights') }}</v-card-title>
    <v-card-text class="py-3 px-5">
      <v-row class="pt-0 pb-2">
        <v-col
          class="py-0 mb-4"
          cols="12"
          md="6"
        >
          <v-row
            v-for="(key, index) in highlightKeys.slice(0, highlightKeys.length / 2 + 1)"
            :key="index"
          >
            <v-col
              class="d-flex flex-nowrap"
              cols="12"
            >
              <div class="pe-3">
                <v-icon
                  color="secondary"
                  size="24"
                >
                  {{ schemaProperties.highlights.properties[key].icon }}
                </v-icon>
              </div>
              <div>
                <div class="mb-1 fs-16 fw-500">
                  {{ $t(schemaProperties.highlights.properties[key].alias) }}
                </div>
                <TranslatedItems
                  v-if="highlights[key] && highlights[key].length > 0"
                  :items="highlights[key]"
                  classes="fs-16 fw-400"
                />
                <div
                  v-else
                  class="fs-16 fw-400 c-light-black"
                >
                  {{ $t('Not available') }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          md="6"
        >
          <v-row
            v-for="(key, index) in highlightKeys.slice(highlightKeys.length / 2 + 1)"
            :key="index"
          >
            <v-col
              class="d-flex flex-nowrap"
              cols="12"
            >
              <div class="pe-3">
                <v-icon
                  color="secondary"
                  size="24"
                >
                  {{ schemaProperties.highlights.properties[key].icon }}
                </v-icon>
              </div>
              <div>
                <div class="mb-1 fs-16 fw-500">
                  {{ $t(schemaProperties.highlights.properties[key].alias) }}
                </div>
                <TranslatedItems
                  v-if="highlights[key] && highlights[key].length > 0"
                  :items="highlights[key]"
                  classes="fs-16 fw-400"
                />
                <div
                  v-else
                  class="fs-16 fw-400 c-light-black"
                >
                  {{ $t('Not available') }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="provider.other_programs_description">
        <v-col>
          <div class="fs-18 fw-600 mb-2">
            {{ $t(otherProgramsDescriptionTitle) }}
          </div>
          <div class="fs-16 fw-400 mb-2">
            {{ $t(provider.other_programs_description) }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TranslatedItems from '@/shared/components/TranslatedItems.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  provider: {
    type: Object,
    required: true,
  },
});

const highlights = computed(() => props.provider.highlights);

const highlightKeys = computed(() => {
  return Object.keys(schemaProperties.value.highlights.properties).filter(
    (key) => schemaProperties.value.highlights.properties[key].enabled === true,
  );
});

const otherProgramsDescriptionTitle = computed(() => {
  return schemaProperties.value.other_programs_description.alias || 'Other program information:';
});

const schemaProperties = computed(() => {
  return store.state.schemas[props.provider.schema_id].definition.properties;
});
</script>
