<template>
  <div
    v-show="isSsoEnabled()"
    class="mobile-sso"
  >
    <v-row
      v-for="connection in getSSOConnections()"
      :key="connection.id"
    >
      <v-col cols="12">
        <v-btn
          @click="loginViaSSO(connection)"
          class="focus-very-visible"
          color="primary"
          size="x-large"
          variant="outlined"
          block
        >
          <template v-if="connection.name == 'Login.gov'">
            <span v-t="'Login with'" />
            <img
              alt="Login.gov"
              class="pa-1"
              src="/icons/login_dot_gov.svg"
              width="100"
            />
          </template>
          <template v-else>
            <span v-t="`Login with ${connection.name}`" />
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <slot name="append" />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore();

function getSSOConnections() {
  return store.state.site.sso_connections || [];
}

function isSsoEnabled() {
  return getSSOConnections().length > 0;
}

function loginViaSSO(connection) {
  window.location.href = `${window.api_url}/member/session/authenticate?site_id=${window.site_id}&connection_id${connection.id}`;
}
</script>

<style>
@media only screen and (max-width: 767px) {
  .mobile-sso .v-btn__content {
    white-space: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
</style>
