<template>
  <v-row dense>
    <v-col>
      <p class="c-light-black fs-16 mb-4 mt-0">
        <MarkdownContent :content="attestation" />
      </p>

      <template v-if="enableEsign">
        <div class="b-1 bc-very-light-grey b-radius-4 pa-md-6 pa-3">
          <div class="mb-4">
            <v-btn
              @click="mode = 'type'"
              :aria-label="$t(typeToSignAriaLabel)"
              :block="$vuetify.display.smAndDown"
              :variant="mode == 'draw' ? 'outlined' : undefined"
              class="mb-2 me-2"
              color="primary"
              data-cy="text-mode-button"
            >
              <v-icon
                v-if="mode == 'type'"
                icon="check_circle"
                start
              />
              {{ $t('Type your name to sign') }}
            </v-btn>

            <v-btn
              @click="mode = 'draw'"
              :aria-label="$t(drawToSignAriaLabel)"
              :block="$vuetify.display.smAndDown"
              :variant="mode == 'type' ? 'outlined' : undefined"
              class="mb-2 me-2"
              color="primary"
              data-cy="draw-mode-button"
            >
              <v-icon
                v-if="mode == 'draw'"
                icon="check_circle"
                start
              />
              {{ $t('Draw signature to sign') }}
            </v-btn>
          </div>

          <div class="mb-4">
            <div v-show="mode == 'type'">
              <v-row class="mb-4">
                <LabeledTextfield
                  v-model="esignName"
                  @input="unadoptSignature"
                  data-cy="text-name-input"
                  message="Type your full name"
                />
              </v-row>
              <v-row dense>
                <v-col
                  class="fs-16 c-black mb-1"
                  cols="12"
                  data-cy="mode-text"
                >
                  {{ $t('Preview your signature') }}
                </v-col>
                <v-col
                  class="esign-preview"
                  cols="12"
                >
                  <div class="esign-preview-box d-flex align-center">
                    <div
                      v-text="esignName"
                      class="esign-preview-text"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>

            <div v-show="mode == 'draw'">
              <v-row class="mb-4">
                <LabeledTextfield
                  v-model="esignName"
                  @input="unadoptSignature"
                  data-cy="text-name-input"
                  message="Type your full name"
                />
              </v-row>
              <v-row dense>
                <v-col
                  class="fs-16 c-black mb-1"
                  cols="12"
                  data-cy="mode-text"
                >
                  {{ $t('Draw your signature') }}
                </v-col>
                <v-col cols="12">
                  <EsignCanvas
                    ref="esignCanvas"
                    :locked="adopted"
                  />
                </v-col>
              </v-row>
            </div>
          </div>

          <div class="fs-16 c-light-black mb-4">
            {{ $t(adoptSignatureText) }}
          </div>
          <v-row>
            <v-col>
              <v-btn
                @click="clearSignature"
                :ripple="false"
                color="red"
                size="x-large"
                variant="outlined"
              >
                {{ $t('Clear') }}
              </v-btn>
            </v-col>

            <v-col class="d-flex justify-end">
              <v-btn
                @click="adoptSignature"
                :disabled="!complete || adopted"
                :ripple="false"
                color="primary"
                data-cy="adopt-button"
                size="x-large"
              >
                {{ $t('Adopt signature') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>

      <template v-else>
        <v-row class="mb-1">
          <v-col>
            <v-checkbox
              v-model="confirmed"
              @update:model-value="$emit('change', $event)"
              :label="$t(attestationLabel)"
              class="mt-0"
              data-cy="checkbox_pfa"
              hide-details
            />
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script setup>
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import EsignCanvas from '@/shared/components/form/EsignCanvas.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import { useStore } from 'vuex';

const emit = defineEmits(['change']);
const store = useStore();

const props = defineProps({
  attestationText: {
    type: Object,
    default: () => {},
  },
  attestationLabel: {
    type: String,
    default: null,
  },
  enableEsign: {
    type: Boolean,
    default: false,
  },
});

const adoptSignatureText =
  'By clicking "Adopt signature", you agree that your name and initials will be an electronic representation of your signature.';

const adopted = ref(false);
const attestation = ref(
  props.attestationText[store.state.profile.default_locale] || props.attestationText.en || '',
);
const confirmed = ref(null);
const esignCanvas = ref(null);
const esignName = ref(null);
const hasDrawn = ref(false);
const mode = ref('type');

defineExpose({ esignName, getEsignSignature, getAttestationText });

const complete = computed(() => {
  if (!esignName.value || esignName.value.length < 2) return false;
  if (mode.value === 'type') return true;
  if (mode.value === 'draw') return hasDrawn.value;

  return false;
});

const drawToSignAriaLabel = computed(() => {
  return mode.value === 'draw'
    ? 'Selected: Draw signature to sign'
    : 'Select: Draw signature to sign';
});

const typeToSignAriaLabel = computed(() => {
  return mode.value === 'type'
    ? 'Selected: Type your name to sign'
    : 'Select: Type your name to sign';
});

onMounted(() => {
  if (props.enableEsign) checkHasDrawnLoop();
});

function adoptSignature() {
  esignCanvas.value.stopDrawing();
  adopted.value = true;
  emit('change', true);
}

function checkHasDrawnLoop() {
  if (mode.value === 'draw' && esignCanvas.value) {
    hasDrawn.value = esignCanvas.value.filled();
  }
  setTimeout(() => {
    checkHasDrawnLoop();
  }, 100);
}

function getAttestationText() {
  return attestation.value;
}

function clearSignature() {
  esignName.value = null;
  adopted.value = false;
  esignCanvas.value.clear();
  emit('change', false);
}

function getEsignSignature() {
  if (mode.value === 'draw') return esignCanvas.value.getDataUrl();
  return null;
}

function unadoptSignature() {
  adopted.value = false;
  emit('change', false);
}
</script>

<style>
.esign-preview {
  vertical-align: middle;
  background-color: #eee;
  padding: 16px;
  overflow-x: scroll;
}

.esign-preview-box {
  height: 125px;
}

.esign-preview-text {
  white-space: nowrap;
  font-size: 44px;
  font-family: 'The Nautigal', cursive;
}
</style>
