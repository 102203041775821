<template>
  <img
    :alt="$store.state.site.ratings.name + ' star ' + starLength()"
    :src="src"
    class="va-middle"
    height="22"
    size="22"
  />
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  index: {
    type: Number,
    default: null,
  },
  passed: {
    type: Boolean,
    default: null,
  },
  score: {
    type: Number,
    default: null,
  },
});

const src = computed(() => {
  if (props.passed) {
    if (props.index + 1 === props.score) {
      return store.state.site.ratings.icons[props.index].index;
    }
    return store.state.site.ratings.icons[props.index].pass;
  }
  return store.state.site.ratings.icons[props.index].fail;
});

function starLength() {
  return props.index + 1;
}
</script>
