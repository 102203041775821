import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  base_url() {
    return `${window.api_url}/manager/providers`;
  },

  get(id, success) {
    return axios.get(`${this.base_url()}/${id}`).then(success);
  },

  index(filters = {}) {
    return axios.get(this.base_url(), { params: filters }).catch(handleError);
  },

  assessment_program: {
    baseUrl(providerId) {
      return `${window.api_url}/manager/providers/${providerId}/assessment_programs`;
    },

    index(providerId) {
      return axios.get(this.baseUrl(providerId));
    },
  },

  asset: {
    baseUrl(providerId, id) {
      let url = `${window.api_url}/manager/providers/${providerId}/assets`;
      if (id) {
        url += `/${id}`;
      }
      return url;
    },

    create(providerId, asset, success, failure) {
      axios.post(this.baseUrl(providerId), { asset }).then(success).catch(failure);
    },

    destroy(providerId, id, success) {
      axios.delete(this.baseUrl(providerId, id)).then(success);
    },

    index(providerId, filters, success) {
      axios.get(this.baseUrl(providerId), { params: filters }).then(success);
    },
  },

  dashboard: {
    baseUrl(providerId) {
      return `${window.api_url}/manager/providers/${providerId}/dashboard`;
    },

    get(providerId, subsidyProgramId) {
      return axios.get(this.baseUrl(providerId), {
        params: { subsidy_program_id: subsidyProgramId },
      });
    },
  },

  enrollment_step: {
    baseUrl(providerId, id) {
      let url = `${window.api_url}/manager/providers/${providerId}/enrollment_steps`;
      if (id) {
        url += `/${id}`;
      }
      return url;
    },

    create(providerId, step, success, failure) {
      axios.post(this.baseUrl(providerId), { enrollment_step: step }).then(success).catch(failure);
    },

    destroy(providerId, id, success) {
      axios.delete(this.baseUrl(providerId, id)).then(success);
    },

    index(providerId, success) {
      axios.get(this.baseUrl(providerId)).then(success);
    },

    update(providerId, id, step) {
      return axios
        .patch(this.baseUrl(providerId, id), { enrollment_step: step })
        .catch(handleError);
    },
  },

  form: {
    baseUrl(providerId, id) {
      let url = `${window.api_url}/manager/providers/${providerId}/forms`;
      if (id) {
        url += `/${id}`;
      }
      return url;
    },

    create(providerId, form) {
      return axios.post(this.baseUrl(providerId), { form });
    },

    get(providerId, id, success) {
      if (!success) return axios.get(this.baseUrl(providerId, id)).catch(handleError);

      return axios.get(this.baseUrl(providerId, id));
    },

    index(providerId, filters = {}) {
      return axios.get(this.baseUrl(providerId), { params: filters }).catch(handleError);
    },

    update(providerId, id, form, success, failure) {
      axios.patch(this.baseUrl(providerId, id), { form }).then(success).catch(failure);
    },

    promiseUpdate(providerId, id, form) {
      return axios.patch(this.baseUrl(providerId, id), { form });
    },
  },
};
