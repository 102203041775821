import Api from '@/shared/services/api/member';

export default {
  getChildData(subsidyId) {
    return Api.childApplicationData.get(subsidyId);
  },

  getGroupData(subsidyId) {
    return Api.groupApplicationData.get(subsidyId);
  },

  updateChildData(subsidyId, childData) {
    return Api.childApplicationData.update(subsidyId, childData);
  },

  updateGroupData(subsidyId, groupData) {
    return Api.groupApplicationData.update(subsidyId, groupData);
  },
};
