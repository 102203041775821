<template>
  <div class="ta-center">
    <div class="fs-16 fw-500">
      {{ $t(dayTranslation) }}
    </div>

    <div class="fs-16 mb-2">
      <LongDate
        :date="day.day"
        hide-year
        short-month
      />
    </div>

    <v-btn
      v-for="time in day.times"
      @click="selectTime(time)"
      :key="time"
      :variant="!active || selectedTime != time ? 'outlined' : undefined"
      class="my-2"
      color="primary"
      size="small"
      block
    >
      {{
        new Date(time).toLocaleTimeString($store.state.default_locale, {
          hour: 'numeric',
          minute: '2-digit',
        })
      }}
    </v-btn>

    <div
      v-if="day.times.length == 0"
      class="fs-14 c-light-black"
    >
      <v-icon>minimize</v-icon>
    </div>

    <v-divider
      v-if="$vuetify.display.smAndDown"
      class="my-4"
    />
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    day: {
      type: Object,
      default: null,
    },
  },

  emits: ['select'],

  data() {
    return {
      dayAbbreviations: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      selectedTime: null,
    };
  },

  computed: {
    dayTranslation() {
      const selectedDate = new Date(this.day.day);
      const day = selectedDate.getDay();
      const dayAbbreviation = this.dayAbbreviations[day];
      return dayAbbreviation;
    },
  },

  methods: {
    selectTime(time) {
      this.selectedTime = time;
      this.$emit('select', time);
    },
  },
};
</script>
