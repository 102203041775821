<template>
  <v-dialog
    v-model="isVisible"
    :max-width="600"
    scrim="transparent"
    persistent
  >
    <v-card
      data-cy="resource-dialog"
      border
      flat
      tile
    >
      <v-card-title>
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col :cols="closeable ? 10 : 12">
            <div>
              {{ $t('Multi-factor authentication enrollment') }}
            </div>
          </v-col>
          <v-col
            v-if="closeable"
            class="ta-right"
          >
            <v-btn
              @click="close"
              :aria-label="$t('Close')"
              size="x-small"
              variant="text"
              icon
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col>
            <p>{{ $t('1. Enter the number of your SMS enabled device:') }}</p>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <MaskedInput
              v-model="phone"
              v-slot="{ inputRef, masked }"
              mask="(###) ###-####"
            >
              <v-text-field
                v-model="masked.value"
                :ref="inputRef"
                :aria-label="$t('Phone')"
                variant="filled"
                hide-details
              />
            </MaskedInput>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              @click="enroll"
              :disabled="invalidPhone || !!challenge"
              :loading="processing"
              color="primary"
            >
              <span>
                {{ $t('Send one-time passcode') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="my-6" />

        <v-row dense>
          <v-col>
            <p>
              {{ $t('2. Enter the one-time passcode sent to your device:') }}
            </p>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-otp-input
              v-model="code"
              @finish="verify"
              :disabled="!challenge || processing"
              class="mxw-400"
              data-cy="otp-input"
              length="6"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/shared/mixins/api';
import RouterHelper from '@/shared/mixins/RouterHelper';
import MaskedInput from '@/shared/components/form/MaskedInput.vue';

export default {
  components: {
    MaskedInput,
  },

  compatConfig: { MODE: 2 },

  mixins: [API, RouterHelper],

  props: {
    closeable: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  data() {
    return {
      code: '',
      isVisible: false,
      phone: '',
      processing: false,
      challenge: null,
    };
  },

  computed: {
    invalidPhone() {
      return this.phone?.length !== 14;
    },
  },

  watch: {
    '$route.query.action': {
      immediate: true,
      handler(newVal) {
        if (newVal === 'mfa') {
          this.open();
        } else if (this.closeable) {
          this.isVisible = false;
        } else {
          this.$router.push({ query: { action: 'mfa' } });
        }
      },
    },
  },

  async mounted() {
    if (!this.eager) return;

    await this.updateQuery({ action: 'mfa' });
  },

  methods: {
    async close() {
      this.isVisible = false;
      this.code = '';
      this.phone = '';
      this.challenge = null;
      await this.updateQuery({ action: null });
    },

    async enroll() {
      this.processing = true;
      const resp = await this.api.member.factor
        .create({ type: 'sms', phone: this.phone.replace(/[^0-9]/g, '') })
        .catch(this.handleEnrollError);

      this.processing = false;
      if (resp.data) {
        this.$eventBus.$emit('chime', 'One-time passcode sent to your device.');
        this.challenge = resp.data;
      }
    },

    handleEnrollError(error) {
      this.processing = false;
      this.$eventBus.$emit('error', error);
    },

    handleVerifyError(error) {
      this.processing = false;
      this.code = null;
      this.$eventBus.$emit('error', error);
    },

    open() {
      this.isVisible = true;
    },

    async verify() {
      this.processing = true;
      const resp = await this.api.member.factor
        .update({
          code: this.code,
          type: 'sms',
          challenge_id: this.challenge.challenge_id,
        })
        .catch(this.handleVerifyError);

      if (resp?.status !== 200) return;

      const vm = this;
      this.$store.dispatch('identify', {
        success(profile) {
          if (profile.workos_mfa_factor_confirmed) {
            vm.processing = false;
            vm.close();
            vm.$eventBus.$emit('chime', 'MFA Activated');
            vm.$emit('change');
          }
        },
      });
    },
  },
};
</script>
