<!-- Note: This is where all EventBus chime text is translated - If standard text, rely on this
location for translation - If highly customized text (e.g. includes names, numbers, dynamic text),
you may need to break up the text at origin to provide translations (until automatic translations
are implemented/relied upon) -->
<template>
  <v-snackbar
    v-model="modelValue"
    :location="location"
    :rounded="rounded"
    :style="style"
    :timeout="timeout"
    color="primary"
    data-testid="snackbar"
    position="fixed"
    z-index="zIndex"
    tile
  >
    <div
      :class="textClass"
      class="fs-16 fw-500 c-white"
      data-cy="chime"
      data-testid="snackbar-text"
    >
      {{ $t(text) }}
    </div>

    <v-list
      v-if="listItems.length > 0"
      bg-color="transparent"
    >
      <v-list-item
        v-for="listItem in listItems"
        @click="$emit('goToListItem', listItem)"
        :key="listItem.message"
        :prepend-icon="listItemIcon"
        :title="listItem.message"
        class="fs-16 fw-500 c-white"
      />
    </v-list>

    <template #actions>
      <v-btn
        v-if="cancelable"
        @click="$emit('cancel')"
        :loading="processing"
        class="me-2"
        color="white"
        variant="text"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        v-if="saveable"
        @click="$emit('save')"
        :loading="processing"
        color="white"
        data-cy="save-bar-button"
        variant="outlined"
      >
        {{ $t('Save') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { useStore } from 'vuex';

const modelValue = defineModel();

const props = defineProps({
  cancelable: Boolean,
  centered: Boolean,
  listItemIcon: { type: String, default: 'info' },
  listItems: { type: Array, default: () => [] },
  location: { type: String, default: 'bottom' },
  processing: Boolean,
  rounded: Boolean,
  timeout: Number,
  text: String,
  saveable: Boolean,
  zIndex: { type: Number, default: 9999 },
});

defineEmits(['cancel', 'goToListItem', 'save']);

const store = useStore();

const style = computed(() => {
  if (store.state.miniNav) {
    return 'margin-left: -70px;';
  }
  if (store.state.drawer) {
    return 'margin-left: -256px;';
  }
  return 'margin-left: 0;';
});

const textClass = computed(() => {
  if (props.centered) {
    return 'ta-center';
  }
  return null;
});
</script>
