<template>
  <v-dialog
    v-model="visible"
    fullscreen
    persistent
  >
    <v-card class="bg-super-light-blue">
      <v-container class="mxw-1200">
        <v-card
          v-if="loaded"
          data-testid="agreement-dialog"
          border
          flat
          tile
        >
          <v-card-title>
            <v-row class="d-flex align-center">
              <v-col
                class="d-flex align-center"
                cols="8"
              >
                <span>
                  {{ $t(agreement.name) }}
                </span>
              </v-col>

              <v-col class="d-flex justify-end">
                <v-btn
                  @click="close"
                  color="primary"
                  variant="outlined"
                >
                  <v-icon class="me-2"> close </v-icon>
                  {{ $t('Close') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider class="mb-6" />

          <template v-if="loaded">
            <v-card-text>
              <QuestionSet
                v-model="agreement"
                :questions="validQuestions"
                :readonly="readonly"
                :schema="schema.definition"
                expanded
                flat
                hide-actions
                outlined-sections
                paddingless
                very-dense
              />

              <div
                v-for="question in verificationQuestions"
                :key="question.id"
                class="mb-6"
              >
                <FormQuestion
                  :color="null"
                  :elevation="0"
                  :subtitle="question.verification_subtitle"
                  :title="question.verification_title"
                  dense
                  hide-actions
                  outlined
                  tile
                  very-dense
                >
                  <template v-if="!readonly">
                    <AttachmentUploader
                      @uploaded="attachments.push($event)"
                      :ref="['uploader', question.id].join('')"
                      :owner="{
                        business_id: $store.state.profile.business_id,
                        type: 'Agreement',
                        id: agreement.id,
                        tag: question.id,
                        tags: [agreement.id, question.id],
                      }"
                      class="mb-4"
                    />
                  </template>
                  <AttachmentList
                    @change="loadAttachments()"
                    @delete="loadAttachments"
                    :attachments="
                      attachments.filter((attachment) => attachment.tags.includes(question.id))
                    "
                    class="mb-6"
                  />
                </FormQuestion>
              </div>
            </v-card-text>
          </template>

          <template v-else>
            <v-progress-linear indeterminate />
          </template>

          <v-divider class="mb-6" />

          <div class="px-4 pb-6">
            <v-row>
              <v-col>
                <v-btn
                  @click="close"
                  color="primary"
                  size="x-large"
                  variant="outlined"
                >
                  <v-icon class="me-2"> close </v-icon>
                  {{ $t('Close') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="agreement && !agreement.submitted_at && !readonly"
                class="d-flex justify-end"
              >
                <v-btn
                  @click="saveDraft"
                  :loading="processing"
                  class="me-3"
                  color="primary"
                  size="x-large"
                  variant="outlined"
                >
                  {{ $t('Save draft') }}
                </v-btn>
                <v-btn
                  @click="submit"
                  :disabled="!completed"
                  :loading="processing"
                  color="primary"
                  data-testid="submit-button"
                  size="x-large"
                >
                  {{ $t('Finish and submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from '@/shared/services/all_bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import LinkedList from '@/shared/services/linked-list';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AttachmentList,
    AttachmentUploader,
    FormQuestion,
    QuestionSet,
  },

  props: {
    api: {
      type: Object,
      default() {
        return Api;
      },
    },
    role: {
      type: String,
      default: 'member',
    },
  },

  emits: ['change'],

  data() {
    return {
      agreement: null,
      attachments: [],
      loaded: false,
      processing: false,
      schema: null,
      visible: false,
      questions: [],
    };
  },

  computed: {
    incompleteQuestions() {
      return this.mandatoryQuestions.filter((question) =>
        question.synced_attributes.some((sa) => {
          const attrParts = sa.name.split('.');
          return !this.agreement.custom[attrParts[attrParts.length - 1]];
        }),
      );
    },

    incompleteVerificationQuestions() {
      return this.mandatoryQuestions
        .filter((question) => question.verification)
        .filter(
          (question) =>
            this.attachments.filter((attachment) => attachment.tags.includes(question.id))
              .length === 0,
        );
    },

    completed() {
      return (
        this.incompleteQuestions.length === 0 && this.incompleteVerificationQuestions.length === 0
      );
    },

    mandatoryQuestions() {
      return this.validQuestions.filter((question) => question.mandatory);
    },

    readonly() {
      return (
        !!this.agreement.submitted_at || this.agreement.member_id !== this.$store.state.profile.id
      );
    },

    verificationQuestions() {
      return LinkedList.sort(this.questions)
        .filter((question) => question.verification)
        .filter((question) => question.published && question.valid);
    },

    validQuestions() {
      return LinkedList.sort(this.questions).filter((question) => question.valid);
    },
  },

  watch: {
    '$route.query.agreementId': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.open();
        } else {
          this.close();
        }
      },
    },
  },

  methods: {
    close() {
      if (this.$route.query.agreementId) {
        this.$router.push({ query: {} });
      }
      this.visible = false;
      this.resetAgreement();
    },

    async open() {
      this.resetAgreement();
      this.visible = true;
      const resp = await this.api[this.role].agreement.get(this.$route.query.agreementId);
      this.agreement = resp.data;
      this.schema = this.$store.state.schemas[this.agreement.schema_id];
      await this.loadAttachments();
      await this.loadQuestions();
      this.loaded = true;
    },

    resetAgreement() {
      this.agreement = null;
      this.loaded = false;
      this.schema = null;
      this.questions = [];
    },

    saveDraft() {
      this.processing = true;
      this.agreement.submit_requested = false;
      this.api[this.role].agreement.update(this.agreement.id, this.agreement, (resp) => {
        this.agreement = resp.data;
        this.processing = false;
        this.$eventBus.$emit('chime', 'Saved');
      });
    },

    submit() {
      this.processing = true;
      this.agreement.submit_requested = true;
      this.api[this.role].agreement.update(this.agreement.id, this.agreement, () => {
        this.processing = false;
        this.$eventBus.$emit('chime', 'Submitted successfully');
        this.$emit('change');
        this.close();
      });
    },

    async validate() {
      const assertions = this.questions.map((question) => {
        if (question.conditions.length > 0) {
          return {
            assertion: {
              conditions: question.conditions,
              value: this[question.synced_model.toLowerCase()],
            },
          };
        }
        return { assertion: { conditions: [], value: {} } };
      });

      const { data } = await this.api.public_api.assertion.promiseBulkCreate(assertions);
      const { results } = data;

      this.questions.forEach((question, index) => {
        // eslint-disable-next-line no-param-reassign
        question.valid = results[index].result;
      });
    },

    async loadAttachments() {
      const params = { owner_id: this.agreement.id, owner_type: 'Agreement' };
      const role = this.role === 'parent' ? 'member' : this.role;

      const resp = await this.api[role].attachment.index(params);
      if (resp?.status !== 200) return;

      this.attachments = resp.data;
    },

    loadQuestions() {
      this.api.public_api.organization.question.index(
        { owner_id: this.schema.id, owner_type: 'Schema' },
        (resp) => {
          this.questions = resp.data;
          this.validate();
        },
      );
    },
  },
};
</script>
