<template>
  <v-card
    v-show="$store.state.config.enable_mfa_authentication"
    border
    flat
    tile
  >
    <v-card-title>
      <span>
        {{ $t('Multi-factor authentication') }}
      </span>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <p class="fs-16">
            {{
              $t(
                'Receive one-time passcodes to your phone as an additional verification step when you login.',
              )
            }}
          </p>
        </v-col>
        <v-col cols="12">
          <template v-if="profile.workos_mfa_factor_confirmed">
            <v-btn
              @click="removeFactor('sms')"
              :loading="processing"
              color="primary"
            >
              {{ $t('Deactivate') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              @click="updateQuery({ action: 'mfa' })"
              color="primary"
            >
              {{ $t('Activate') }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-text>

    <MFAEnrollmentDialog
      @change="$emit('change')"
      closeable
    />
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';
import MFAEnrollmentDialog from '@/shared/components/MFAEnrollmentDialog.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    MFAEnrollmentDialog,
  },

  mixins: [API, RouterHelper],

  props: {
    profile: {
      type: Object,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      processing: false,
    };
  },

  methods: {
    async removeFactor(type) {
      this.processing = true;
      await this.api.member.factor.destroy({ type });
      window.location.reload();
    },
  },
};
</script>
