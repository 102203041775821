import _ from 'lodash';

/*

This is a little function that ensures a date is:
- a String
- an actual Date String
- and the first 10 characters are in the format of "YYYY-MM-DD"

This allows us to pass Strings like "2023-12-04 xxx" and still be considered valid.

Example inputs, as Strings. known:
1) 2023-10-02                : valid
2) 2020-10-02 00:00:00 -0800 : valid
3) 2023-10                   : invalid

It will either throw, or return true. It is not meant to be fully fledged Date library replacement.

*/

export const isDateFormatValid = ({ name, value }) => {
  if (typeof value !== 'string') {
    throw `argument: ${name} is not a String`;
  }

  // the check here is to enusre that the String that has been passed, can be parsed into a Date object
  // e.g. "1234-56-78" is not a valid date, but it *is* a String, that matches the below desired regex formatting
  const date = new Date(value);
  if (_.isNaN(date.getDate())) {
    throw `argument: ${name} is not a valid Date`;
  }

  // this regex is needed because thanks to... well... JavaScript, things like '0' can be parsed into a valid Date
  // object, but for the use case here, we only want to accept properly formatted, 10 character, strings
  const dateSubstring = value.substring(0, 10);
  const yearMonthDayRegEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateSubstring.match(yearMonthDayRegEx)) {
    throw `argument: ${name} is not in the in the YYYY-MM-DD format`;
  }

  return true;
};

export const newUtcDate = (date) => {
  let utcDate;

  if (typeof date === 'string') {
    const [year, month, day] = date.split('-');

    utcDate = new Date(Date.UTC(year, month - 1, day));
  } else if (date instanceof Date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();

    utcDate = new Date(Date.UTC(year, month, day));
  } else {
    throw new Error(
      'Invalid input. Please provide a date string in the format YYYY-MM-DD or a Date object.',
    );
  }

  return utcDate;
};

export const getDateFromString = (dateValue) => {
  const d = dateValue ? new Date(dateValue) : new Date();
  return isNaN(d.getTime()) ? new Date() : d;
};

export const dateTimeToIso8601 = (dateString) => {
  return dateObjectToIso8601(getDateFromString(dateString));
};

export const dateObjectToIso8601 = (dateObject) => {
  if (dateObject === null) return;

  // Extract the year, month, and date directly from the dateObject
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const iso8601DateStringToLocalDateTime = (date) => {
  // Check if the input is a string in the format 'yyyy-mm-dd'
  if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    throw new Error("Invalid date format. Expected 'yyyy-mm-dd' date string.");
  }

  // Create a Date object from the date string, ex: '2024-06-27'
  const utcDate = new Date(date + 'T00:00:00Z');

  // Adjust for the local timezone offset
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
};

export const toIanaTimeZone = (zone) => {
  const mapping = {
    'Pacific Time (US & Canada)': 'America/Los_Angeles',
    'Mountain Time (US & Canada)': 'America/Denver',
    'Central Time (US & Canada)': 'America/Chicago',
    'Eastern Time (US & Canada)': 'America/New_York',
    Hawaii: 'Pacific/Honolulu',
    Alaska: 'America/Anchorage',
    Arizona: 'America/Phoenix',
    'Indiana (East)': 'America/Indiana/Indianapolis',
  };

  return mapping[zone] || zone;
};
