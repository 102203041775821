import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/parent/eligibility_screeners`;
    if (id) url += `/${id}`;
    return url;
  },

  async create(eligibilityScreener) {
    return axios
      .post(this.baseUrl(), { eligibility_screener: eligibilityScreener })
      .catch(handleError);
  },

  async get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  async index(filters) {
    return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
  },

  async questions(id) {
    return axios.get(`${this.baseUrl(id)}/questions`).catch(handleError);
  },

  async update(eligibilityScreener, attrs) {
    const body = attrs || eligibilityScreener;
    return axios.patch(this.baseUrl(eligibilityScreener.id), body).catch(handleError);
  },
};
