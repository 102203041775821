<template>
  <v-row
    class="mb-3"
    dense
  >
    <v-col
      class="d-flex justify-end"
      cols="12"
    >
      <v-btn
        @click="$emit('jump', section)"
        color="primary"
        size="small"
        variant="outlined"
      >
        {{ $t('Change response') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    section: {
      type: String,
      required: true,
    },
  },

  emits: ['jump'],
};
</script>
