<template>
  <v-container v-if="confirmed != null">
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card
          max-width="700"
          border
          flat
          tile
        >
          <v-card-title>{{ $t('Set your password') }}</v-card-title>
          <v-divider />
          <v-card-text class="pt-4 pb-8">
            <v-row>
              <LabeledTextfield
                v-model="newPassword"
                cols="12"
                message="New password"
                type="password"
              />

              <LabeledTextfield
                v-model="newPasswordConfirmation"
                cols="12"
                message="Confirm new password"
                type="password"
              />
            </v-row>
            <v-col
              v-if="isParent"
              cols="12"
            >
              <p
                v-t="
                  'The password must be 8 characters with 3 out of 4 of the following: Lowercase characters, Uppercase characters, Numbers, Symbols.'
                "
                class="fs-15 fw-500 mb-2"
              />
            </v-col>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              @click="updatePassword"
              :disabled="saveDisabled"
              :loading="processing"
              class="px-8"
              color="primary"
              size="x-large"
            >
              <span>
                {{ $t('Save and continue') }}
              </span>
              <v-icon class="ms-3"> east </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import usePasswordValidation from '@/shared/composables/usePasswordValidation';
import { useStore } from 'vuex';

const confirmed = ref(null);
const eventBus = useEventBus();
const isParent = ref(null);
const { isValidPassword } = usePasswordValidation();
const newPassword = ref(null);
const newPasswordConfirmation = ref(null);
const processing = ref(false);
const route = useRoute();
const router = useRouter();
const store = useStore();

onMounted(check);

const saveDisabled = computed(() => {
  return (
    isParent.value === true && !isValidPassword(newPassword.value, newPasswordConfirmation.value)
  );
});

function updatePassword() {
  if (!newPassword.value || !newPasswordConfirmation.value) {
    return eventBus.chime('Password is required to continue');
  }

  if (newPassword.value !== newPasswordConfirmation.value) {
    return eventBus.chime('Passwords must match to continue');
  }

  if (
    isParent.value === true &&
    !isValidPassword(newPassword.value, newPasswordConfirmation.value)
  ) {
    return eventBus.chime('Password is not valid');
  }

  processing.value = true;
  return Api.member.updatePassword(
    { password: newPassword.value },
    (response) => {
      eventBus.chime('Your password has been updated.');
      processing.value = false;

      if (response.data.initial_password) {
        router.push({ path: '/' });
      } else {
        router.push({ path: '/sign_out' });
      }
    },
    (err) => {
      eventBus.chime(err.response.data.errors.join('. '));
      processing.value = false;
    },
  );
}

function check() {
  isParent.value = store.state.profile.is_parent;
  if (
    store.state.profile.password_set &&
    route.query.debug !== 1 &&
    !route.query.password_reset === 'true'
  ) {
    confirmed.value = true;
    router.push({ name: 'Dashboard' });
  } else {
    confirmed.value = false;
  }
}
</script>
