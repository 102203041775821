import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(groupId, id) {
    return `${window.api_url}/organization/groups/${groupId}/children${id ? `/${id}` : ''}`;
  },

  create(groupId, child) {
    return axios.post(this.baseUrl(groupId), { child }).catch(handleError);
  },

  destroy(groupId, id) {
    return axios.delete(this.baseUrl(groupId, id)).catch(handleError);
  },

  get(groupId, id) {
    return axios.get(this.baseUrl(groupId, id)).catch(handleError);
  },

  index(groupId) {
    return axios.get(this.baseUrl(groupId)).catch(handleError);
  },

  update(groupId, id, child) {
    return axios.patch(this.baseUrl(groupId, id), { child }).catch(handleError);
  },
};
