import { useRoute, useRouter } from 'vue-router';
import { updateQuery as routerUpdateQuery } from '@/shared/services/router';

export default function useRouterHelper() {
  const route = useRoute();
  const router = useRouter();

  function getFiltersFromQuery({ paged }) {
    const newFilters = { ...route.query };
    if (paged) newFilters.page = parseInt(newFilters.page || 1, 10);
    return newFilters;
  }

  async function updateQuery(newQuery) {
    await routerUpdateQuery(router, route, newQuery);
  }

  return {
    getFiltersFromQuery,
    updateQuery,
  };
}
