/**
 * @deprecated This mixin is replaced by useUppyUtils.js
 * @example ```vue
 * <script setup>
 * import useUppyUtils from '@/shared/composables/useUppyUtils';
 *
 * const { getUploadAPIEndpoint, getUppyLocale } = useUppyUtils();
 * </script>
 * ```
 */

export default {
  methods: {
    getUploadAPIEndpoint() {
      const path = 'attachments/upload';
      // Path namespaced API, e.g. https://childcare.twc.texas.gov/api
      if (import.meta.env.VUE_APP_SITE_ID) return [window.api_url, path].join('/');

      // Domain namespaced API, e.g. https://api.getbridgecare.com/api
      const slicedUrl = window.api_url.slice(0, -3);
      return [slicedUrl, path].join('');
    },

    getUppyLocale() {
      return {
        strings: {
          addingMoreFiles: this.$t('Adding more files'),
          addMore: this.$t('Add more'),
          addMoreFiles: this.$t('Add more files'),
          back: this.$t('Back'),
          browseFiles: this.$t('browse files'),
          browseFolders: this.$t('browse folders'),
          cancel: this.$t('Cancel'),
          cancelUpload: this.$t('Cancel upload'),
          complete: this.$t('Complete'),
          done: this.$t('Done'),
          dropHint: this.$t('Drop your files here'),
          dropPasteBoth: this.$t('Drop files here or %{browseFiles}', {
            browseFiles: '%{browseFiles}',
          }),
          dropPasteFiles: this.$t('Drop files here or %{browseFiles}', {
            browseFiles: '%{browseFiles}',
          }),
          dropPasteFolders: this.$t('Drop files here or %{browseFiles}', {
            browseFiles: '%{browseFiles}',
          }),
          removeFile: this.$t('Remove file'),
          save: this.$t('Save'),
          uploadComplete: this.$t('Upload complete'),
          uploadXFiles: {
            0: this.$t('Upload %{smart_count} file'),
            1: this.$t('Upload %{smart_count} files'),
          },
          uploadingXFiles: {
            0: this.$t('Uploading %{smart_count} file'),
            1: this.$t('Uploading %{smart_count} files'),
          },
          xFilesSelected: {
            0: this.$t('%{smart_count} file selected'),
            1: this.$t('%{smart_count} files selected'),
          },
        },
      };
    },
  },
};
