import _ from 'lodash';
import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  base_url() {
    return `${window.api_url}/organization/announcements`;
  },

  create(announcement) {
    return axios.post(this.base_url(), { announcement }).catch(handleError);
  },

  destroy(id) {
    return axios.delete(`${this.base_url()}/${id}`).catch(handleError);
  },

  index(params) {
    return axios.get(this.base_url(), { params }).catch(handleError);
  },

  get(id, success = _.identity) {
    return axios.get(`${this.base_url()}/${id}`).then(success);
  },

  update(id, announcement, success = _.identity, failure = _.identity) {
    return axios.patch(`${this.base_url()}/${id}`, { announcement }).then(success).catch(failure);
  },
};
