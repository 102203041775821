import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(subsidyId) {
    return `${window.api_url}/member/subsidies/${subsidyId}/group_application_data`;
  },

  get(subsidyId) {
    return axios.get(this.baseUrl(subsidyId)).catch(handleError);
  },

  questionSet(subsidyId, options = {}) {
    return axios
      .get(`${this.baseUrl(subsidyId)}/question_set`, { params: options })
      .catch(handleError);
  },

  questionSetForDraft(subsidyId, body = {}) {
    return axios.post(`${this.baseUrl(subsidyId)}/question_set`, body).catch(handleError);
  },

  update(subsidyId, groupData) {
    return axios
      .patch(this.baseUrl(subsidyId), { group_application_data: groupData })
      .catch(handleError);
  },
};
