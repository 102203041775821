import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/public/organizations/${window.organization_id}/grant_programs`;
    if (id) url += `/${id}`;
    return url;
  },

  get(id) {
    return axios.get(this.baseUrl(id)).catch(handleError);
  },

  index() {
    return axios.get(this.baseUrl()).catch(handleError);
  },
};
