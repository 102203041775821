<template>
  <v-dialog
    v-if="provider"
    v-model="visible"
    fullscreen
  >
    <div class="h-100pc w-100pc bg-super-light-blue px-4 oy-scroll">
      <div class="mxw-800 mx-auto p-relative">
        <div class="ta-right">
          <v-btn
            @click="visible = false"
            :aria-label="$t('Close')"
            variant="text"
            icon
          >
            <v-icon
              icon="close"
              size="32"
            />
          </v-btn>
        </div>

        <div class="w-100pc bg-white pa-6 rounded elevation-1">
          <v-card-title
            v-if="step < 5"
            class="d-flex justify-center wrap-container"
          >
            <div class="wrap-center">
              <span class="text-ellipsis">
                {{ $t('Request a tour with') }} {{ provider.name }} ({{ provider.tour_time }}
                {{ $t('minutes') }})
              </span>
            </div>
          </v-card-title>

          <v-card-subtitle
            v-if="step < 5"
            class="c-light-black d-flex justify-center fs-16 py-3 flex-grow-1 text-ellipsis"
          >
            {{ $t('Please fill out the form fields below and someone will be in touch!') }}
          </v-card-subtitle>

          <v-card-text class="py-0">
            <div
              v-if="loading"
              class="pa-16 ta-center"
            >
              <v-progress-circular
                size="40"
                indeterminate
              />
            </div>

            <v-stepper
              v-if="step < 5"
              v-model="step"
              :items="['Request', 'Children', 'Hours', 'Message']"
              flat
              hide-actions
            >
              <!-- Request -->
              <template v-slot:item.1>
                <p class="c-black fs-18 fw-600">
                  {{ $t('Preferred tour time:') }}
                </p>
                <div class="d-flex justify-center align-center mb-2">
                  <v-btn
                    @click="page -= 1"
                    :aria-label="$t('Go back 1 day')"
                    :disabled="page < 2"
                    variant="text"
                    icon
                  >
                    <v-icon>west</v-icon>
                  </v-btn>
                  <div
                    v-if="tour_openings.length > 0"
                    class="mx-4"
                  >
                    <span class="me-2">
                      {{ $t('Week of') }}
                    </span>
                    <span v-text="new Date(tour_openings[0].day).toLocaleDateString()" />
                  </div>
                  <v-btn
                    @click="page += 1"
                    :aria-label="$t('Go forward 1 day')"
                    variant="text"
                    icon
                  >
                    <v-icon>east</v-icon>
                  </v-btn>
                </div>
                <div :class="dayClass">
                  <TourCalendarDay
                    v-for="day in tour_openings"
                    @select="selectTime(day.day, $event)"
                    :key="day.day"
                    :active="selectedTime[0] == day.day"
                    :day="day"
                  />
                </div>
              </template>

              <!-- Children -->
              <template v-slot:item.2>
                <div v-if="children.length > 0">
                  <div
                    v-if="existingChildren.length > 0"
                    class="mb-6"
                  >
                    <p class="fw-600">
                      {{ $t('Which child are you requesting a tour for?') }}
                    </p>
                    <div
                      v-for="(child, index) in children.filter((child) => child.existing)"
                      :key="child.id"
                      class="mb-3"
                    >
                      <Child
                        :included-check="child.existing"
                        :index="index"
                        :initial-child="child"
                        remove-disabled
                      />
                    </div>
                  </div>

                  <p
                    v-if="existingChildren.length > 0"
                    class="fw-600"
                  >
                    {{ $t('Or, add a new child') }}
                  </p>
                  <div
                    v-for="(child, index) in children.filter((child) => !child.existing)"
                    :key="child.id"
                    class="mb-3"
                  >
                    <Child
                      @removal="remove"
                      :included-check="child.existing"
                      :index="index"
                      :initial-child="child"
                      :remove-disabled="index === 0 && existingChildren.length === 0"
                    />
                  </div>
                  <div>
                    <a
                      @click="addChild"
                      class="fs-16"
                      small
                    >
                      {{ $t('Add child') }}
                    </a>
                  </div>
                </div>
              </template>

              <!-- Hours -->
              <template v-slot:item.3>
                <div class="mb-6">
                  <p class="c-black fs-16 fw-600">
                    {{ $t('What are your preferred hours of care?') }}
                  </p>
                  <v-textarea
                    v-model="hoursMessage"
                    :placeholder="
                      $t(
                        'What days and hours do you require care? Do all children require the same hours of care?',
                      )
                    "
                    rows="3  "
                    variant="outlined"
                    hide-details
                  />
                </div>
                <div>
                  <p class="c-black fs-16 fw-600">
                    {{ $t('What date do you need care? This can be an estimate.') }}
                  </p>
                  <v-date-picker
                    v-model="careStartDate"
                    :landscape="$vuetify.display.mdAndUp"
                    class="b-1 dense bc-primary"
                    full-width
                  />
                </div>
              </template>

              <!-- Message -->
              <template v-slot:item.4>
                <p class="c-black fs-16 fw-500">
                  {{ $t("Is there anything you'd like to tell the provider?") }}
                </p>
                <v-textarea
                  v-model="otherMessage"
                  :placeholder="$t('Optional')"
                  variant="outlined"
                  hide-details
                />
              </template>
            </v-stepper>

            <!-- Finishing step -->
            <div
              v-if="step == 5"
              class="pa-8 ta-center mxw-600 mx-auto"
            >
              <div class="mxw-300 mx-auto mb-4">
                <img
                  class="w-100pc"
                  src="https://res.cloudinary.com/bridgecare/image/upload/v1627582695/media/undraw_Mailbox_re_dvds_1_ego0ke.svg"
                />
              </div>

              <div class="fs-24 fw-600 mb-4">
                {{ $t('Your request has been sent!') }}
              </div>

              <div class="fs-16 fw-500 mb-3">
                {{
                  $t(
                    "Remember, your tour isn't confirmed until the provider accepts it. We will send you a confirmation when that happens.",
                  )
                }}
              </div>

              <div class="fs-16 fw-500 mb-4">
                {{ $t('You can schedule a tour with more than one provider!') }}
              </div>

              <v-btn
                @click="visible = false"
                color="primary"
                size="x-large"
              >
                {{ $t('Okay, got it') }}
              </v-btn>
            </div>
          </v-card-text>

          <v-card-actions v-if="step < 5">
            <v-btn
              v-if="step == 1"
              @click="visible = false"
              :ripple="false"
              size="x-large"
              variant="text"
            >
              {{ $t('Cancel') }}
            </v-btn>

            <v-btn
              v-if="step > 1"
              @click="back"
              :ripple="false"
              size="x-large"
              variant="text"
            >
              {{ $t('Back') }}
            </v-btn>

            <v-spacer />

            <v-btn
              v-if="step < 4"
              @click="handleNextClick"
              :ripple="false"
              color="primary"
              size="x-large"
            >
              {{ $t('Next') }}
            </v-btn>

            <v-btn
              v-if="step == 4"
              @click="send"
              :disabled="processing"
              :ripple="false"
              color="primary"
              size="x-large"
            >
              {{ $t('Request a tour') }}
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import Child from '@/parent/components/children/Child.vue';
import TourCalendarDay from '@/shared/components/TourCalendarDay.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    Child,
    TourCalendarDay,
  },

  props: {
    provider: {
      type: Object,
      default: null,
    },
  },

  emits: ['booked'],

  data() {
    return {
      careStartDate: null,
      children: [],
      defaultChild: {
        schema_id: Object.values(this.$store.state.schemas).filter(
          (schema) => schema.data_type === 'Child',
        )[0].id,
      },
      hoursMessage: null,
      loading: true,
      otherMessage: null,
      page: 1,
      processing: false,
      selectedTime: [],
      startDate: null,
      step: 1,
      text: null,
      tourReservations: [],
      tour_openings: [],
      visible: false,
    };
  },

  computed: {
    dayClass() {
      if (this.$vuetify.display.smAndDown) return '';
      return 'd-flex align-start justify-space-between px-2';
    },

    existingChildren() {
      return this.children.filter((child) => child.existing);
    },

    nextDisabled() {
      if (this.step === 1) {
        return [!this.selectedTime[0], 'Select a date and time to continue'];
      }
      if (this.step === 2) {
        if (this.selectedChildren.length === 0) return [true, 'Select a child to continue'];
        if (this.selectedChildren.some((child) => !child.first_name || !child.dob))
          return [true, 'Fill out all child information to continue'];
      }
      if (this.step === 3) {
        if (!this.hoursMessage)
          return [true, 'Let us know what hours you will need care to continue'];
      }

      return [false];
    },

    selectedChildren() {
      return this.children
        .filter((child) => !child.existing)
        .concat(this.children.filter((child) => child.existing && child.included));
    },
  },

  watch: {
    page() {
      this.loadTourOpenings();
    },
  },

  created() {
    if (this.$store.state.profile) {
      this.loadTourReservations();
    }
  },

  methods: {
    addChild() {
      Api.child.create(this.defaultChild, (response) => {
        this.children.push(response.data);
      });
    },

    back() {
      this.step -= 1;
    },

    handleNextClick() {
      const [disabled, message] = this.nextDisabled;

      if (disabled) {
        this.$eventBus.$emit('chime', message);
        return;
      }

      this.next();
    },

    loadTourOpenings() {
      Api.public_api.provider.tour_opening.index(
        { page: this.page },
        this.provider.id,
        (response) => {
          this.tour_openings = response.data;
          this.processing = false;
          this.loading = false;
        },
      );
    },

    loadTourReservations() {
      Api.tour_reservation.index({}, (response) => {
        this.tourReservations = response.data;
        const existingTour = this.tourReservations.find(
          (tourReservation) => tourReservation.provider_id === this.provider.id,
          this,
        );
        this.$emit('booked', existingTour);
      });
    },

    loadChildren() {
      Api.child.index((response) => {
        this.children = response.data;

        this.children = this.children.map((child) => {
          if (child.first_name && child.last_name && child.dob) {
            return {
              ...child,
              existing: true,
            };
          }

          return child;
        });

        if (this.children.length === 0) this.addChild();
      });
    },

    next() {
      this.step += 1;
    },

    open() {
      this.step = 1;
      this.careStartDate = null;
      this.processing = null;
      this.otherMessage = null;
      this.hoursMessage = null;
      this.selectedTime = [];
      this.children = [];

      if (!this.$store.state.profile || this.$store.state.profile.is_anonymous === true) {
        if (this.$route.params.searchId) {
          this.$router.push({
            name: 'Signup',
            query: {
              searchId: this.$route.params.searchId,
              providerId: this.provider.id,
              action: 'tour',
            },
          });
        } else {
          this.$router.push({
            name: 'Signup',
            query: {
              providerId: this.provider.id,
              action: 'tour',
            },
          });
        }
      } else {
        this.text = null;
        this.visible = true;
        this.loading = true;
        if (this.$store.state.profile) {
          this.loadTourOpenings();
          this.loadChildren();
        }
      }
    },

    remove(removeChild) {
      this.children.splice(
        this.children.findIndex((child) => child.id === removeChild.id),
        1,
      );
    },

    selectTime(date, time) {
      this.selectedTime = [date, time];
    },

    send() {
      this.processing = true;
      Api.tour_reservation.create(
        {
          care_start_date: this.careStartDate,
          children: this.selectedChildren.map((child) => child.id),
          hours_message: this.hoursMessage,
          other_message: this.otherMessage,
          provider_id: this.provider.id,
          start_time: this.selectedTime[1],
        },
        (response) => {
          this.step = 5;
          this.$emit('booked', response.data);
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors.join('. '));
          this.processing = false;
        },
      );
    },

    updateChildren(children) {
      this.children = children;
    },
  },
};
</script>
<style scoped>
.wrap-container {
  overflow: hidden;
}

.wrap-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@media (max-width: 600px) {
  .text-ellipsis {
    white-space: normal;
  }
}
</style>
