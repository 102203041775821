export function parseMilToTime(mins100) {
  const hours = parseInt(mins100 / 100, 10);
  const mins = mins100 % 100;
  return hours * 60 + mins;
}

export function parseTimeToMil(mins60) {
  const hours = parseInt(mins60 / 60, 10);
  const mins = mins60 % 60;
  return hours * 100 + mins;
}

export function parseTimeToString(mins) {
  if (mins <= 59) return `12:${mins.toString().padStart(2, '0')} AM`;
  if (mins === 1440) return '12:00 AM';

  const time = mins >= 720 ? 'PM' : 'AM';
  const updatedMins = mins >= 780 ? mins - 720 : mins;
  return `${parseInt(updatedMins / 60, 10)}:${`0${updatedMins % 60}`.slice(-2)} ${time}`;
}
