/**
 * @deprecated This mixin is replaced by using utils/filteredAttrs.js
 * @example ```vue
 * <script setup>
 * import attrsOmitDataPrefixes from '@/shared/utils/filteredAttrs';
 *
 * const attrs = useAttrs();
 * const filteredAttrs = attrsOmitDataPrefixes(attrs);
 * </script>
 * ```
 */
export default {
  compatConfig: { MODE: 3 },

  computed: {
    /**
     * $attrs excluding those that begin with `data-`.
     */
    attrsOmitDataPrefix() {
      return Object.entries(this.$attrs).reduce((acc, [key, value]) => {
        if (key.startsWith('data-')) {
          return acc;
        }

        acc[key] = value;

        return acc;
      }, {});
    },
  },
};
