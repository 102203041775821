import axios from 'axios';

import attachment from '@/shared/services/api/member/attachment';
import childApplicationData from '@/shared/services/api/member/subsidy-child-application-data';
import groupApplicationData from '@/shared/services/api/member/subsidy-group-application-data';
import loginCode from '@/shared/services/api/member/login-code';
import seatType from '@/shared/services/api/member/seat-type';
import { handleError } from '@/shared/services/api';

export default {
  base_url() {
    return `${window.api_url}/members`;
  },

  create(member, success, failure) {
    if (success) {
      return axios
        .post(
          this.base_url(),
          { member },
          {
            headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
            withCredentials: true,
          },
        )
        .then(success)
        .catch(failure);
    }

    return axios.post(
      this.base_url(),
      { member },
      {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        withCredentials: true,
      },
    );
  },

  address_validation: {
    baseUrl() {
      return `${window.api_url}/member/address_validation`;
    },

    validate(params) {
      return axios.patch(this.baseUrl(), params);
    },
  },

  attachment: attachment,

  childApplicationData,
  groupApplicationData,

  factor: {
    base_url() {
      return `${window.api_url}/member/factors`;
    },
    create(factor) {
      return axios.post(this.base_url(), { factor });
    },

    destroy(factor) {
      return axios.delete(`${this.base_url()}/${factor.type}`);
    },

    update(factor) {
      return axios.patch(`${this.base_url()}/${factor.type}`, { factor });
    },
  },

  login_code: loginCode,

  passwordReset(member) {
    const url = `${this.base_url()}/reset_password`;
    return axios
      .post(
        url,
        { member },
        { headers: { 'Content-Type': 'application/json', Accept: 'application/json' } },
      )
      .catch(handleError);
  },

  updatePassword(member, success, failure) {
    const url = `${this.base_url()}/update_password`;
    return axios
      .post(
        url,
        { member },
        { headers: { 'Content-Type': 'application/json', Accept: 'application/json' } },
      )
      .then(success)
      .catch(failure);
  },

  phone: {
    baseUrl() {
      return `${window.api_url}/member/phone`;
    },
    create(phone) {
      return axios.post(this.baseUrl(), { phone });
    },
    destroy() {
      return axios.delete(this.baseUrl());
    },
    update(phone) {
      return axios.patch(this.baseUrl(), { phone });
    },
    skipPhoneDialog() {
      return axios.patch(this.baseUrl(), { member: { skip_phone_dialog: true } });
    },
  },

  profile: {
    base_url() {
      return `${window.api_url}/member/profile`;
    },

    get(success, failure) {
      if (success) {
        axios.get(this.base_url()).then(success).catch(failure);
      }

      return axios.get(this.base_url());
    },

    update(profile, success, failure) {
      if (!success) return axios.patch(this.base_url(), { profile });

      return axios.patch(this.base_url(), { profile }).then(success).catch(failure);
    },
  },

  permission: {
    baseUrl() {
      return `${window.api_url}/member/permissions`;
    },

    create(permission, success, failure) {
      axios.post(this.baseUrl(), { permission }).then(success).catch(failure);
    },

    destroy(id, success) {
      axios.delete(`${this.baseUrl()}/${id}`).then(success);
    },

    index(filters, success) {
      axios.get(this.baseUrl(), { params: filters }).then(success);
    },

    update(id, permission, success) {
      axios.patch(`${this.baseUrl()}/${id}`, { permission }).then(success);
    },
  },

  session: {
    base_url() {
      return `${window.api_url}/member/session`;
    },

    create(session, success, failure) {
      axios
        .post(
          this.base_url(),
          { session },
          {
            headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
            withCredentials: true,
          },
        )
        .then(success)
        .catch(failure);
    },

    destroy(success) {
      axios.delete(this.base_url()).then(success);
    },

    get(scope, success, failure) {
      if (success) {
        return axios.get(`${this.base_url()}?scope=${scope}`).then(success).catch(failure);
      }

      return axios.get(`${this.base_url()}?scope=${scope}`);
    },

    promiseDestroy() {
      return axios.delete(this.base_url());
    },
  },

  subsidy_program: {
    seat_type: seatType,
  },
};
