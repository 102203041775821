import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/public/organizations/${window.organization_id}/screener_programs`;
    if (id) url += `/${id}`;
    return url;
  },

  get(screenerProgramId, success) {
    if (!success) return axios.get(this.baseUrl(screenerProgramId)).catch(handleError);
    axios.get(this.baseUrl(screenerProgramId)).then(success);
  },

  async index(filters) {
    return axios.get(this.baseUrl(), { params: filters });
  },
};
