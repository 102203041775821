import Mustache from 'mustache';

export default {
  methods: {
    renderText(text) {
      if (!text) return '';

      // assumes that translation will avoid disrupting {{}} syntax
      const sanitizedText = this.replaceLiquidSyntax(text);
      const translatedValue = this.$t(sanitizedText);

      return this.isLiquidValue(translatedValue)
        ? this.renderLiquidValue(translatedValue)
        : translatedValue;
    },

    isLiquidValue(value) {
      if (!value) return false;

      return value.includes('-%');
    },

    renderLiquidValue(value) {
      if (!this.liquidRenderingEnabled) return value;

      return Mustache.render(value, this.modelValue, {}, ['-%', '%-']).replace('&#39;', "'");
    },

    replaceLiquidSyntax(text) {
      if (!this.liquidRenderingEnabled) return text;

      return text.replace(/{{/g, '-%').replace(/}}/g, '%-');
    },
  },
};
