<template>
  <v-toolbar
    :class="['px-4', outlined ? 'bc-outlined-gray bb-1' : '']"
    :elevation="flat ? 0 : 2"
    data-cy="page-title"
  >
    <template v-if="title">
      <TitleBar
        @create="$emit('create')"
        :back-back-name="backBackName"
        :back-back-route="backBackRoute"
        :back-name="backName"
        :back-route="backRoute"
        :create-icon="createIcon"
        :create-text="createText"
        :createable="createable"
        :name="title"
        dense
      />
    </template>
    <template v-else>
      <slot />
    </template>
  </v-toolbar>
</template>

<script>
import TitleBar from '@/shared/components/TitleBar.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    TitleBar,
  },

  props: {
    backBackName: {
      type: String,
      default: null,
    },
    backBackRoute: {
      type: Object,
      default: null,
    },
    backName: {
      type: String,
      default: null,
    },
    backRoute: {
      type: Object,
      default: null,
    },
    createable: {
      type: Boolean,
      default: false,
    },
    createIcon: {
      type: String,
      default: 'add',
    },
    createText: {
      type: String,
      default: null,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  emits: ['create'],
};
</script>
