import axios from 'axios';
import { handleError } from '@/shared/services/api';
import { denormalizeResponse } from './json-api';
import _ from 'lodash';

const normalizeRequest = (eligibilityProgram) => ({
  data: {
    type: 'eligibility_program',
    id: eligibilityProgram.id,
    attributes: {
      ..._.omit(eligibilityProgram, [
        'id',
        'eligibilityScreenerSchema',
        'created_at',
        'updated_at',
      ]),
    },
    relationships: {
      eligibility_screener_schema: {
        data: {
          type: 'eligibility_screener_schema',
          id: eligibilityProgram?.eligibilityScreenerSchema?.id || null,
        },
      },
    },
  },
});

export default {
  baseUrl(id = null) {
    let url = `${window.api_url}/v2/eligibility_programs`;
    if (id) url += `/${id}`;
    return url;
  },

  async index(filters) {
    const response = await axios.get(this.baseUrl(), { params: filters }).catch(handleError);
    return denormalizeResponse(response);
  },

  async create(data) {
    return axios.post(this.baseUrl(), normalizeRequest(data)).catch(handleError);
  },

  async get(id) {
    const response = await axios.get(this.baseUrl(id)).catch(handleError);
    return denormalizeResponse(response);
  },

  async update(eligibilityProgram) {
    const response = axios
      .patch(this.baseUrl(eligibilityProgram.id), normalizeRequest(eligibilityProgram))
      .catch(handleError);

    return denormalizeResponse(response);
  },

  async destroy(id) {
    return axios.delete(this.baseUrl(id)).catch(handleError);
  },
};
