/**
 * When Segment is not loaded in a deployment, use function stubs to prevent TypeErrors.
 */
const ANALYTICS_STUB = {
  trackSubmit: () => {},
  trackClick: () => {},
  trackLink: () => {},
  trackForm: () => {},
  pageview: () => {},
  identify: () => {},
  reset: () => {},
  group: () => {},
  track: () => {},
  ready: () => {},
  alias: () => {},
  debug: () => {},
  page: () => {},
  screen: () => {},
  once: () => {},
  off: () => {},
  on: () => {},
  addSourceMiddleware: () => {},
  addIntegrationMiddleware: () => {},
  setAnonymousId: () => {},
  addDestinationMiddleware: () => {},
  register: () => {},
};

/**
 * The SegmentService wraps the Segment analytics library and provides a
 * higher-level interface for sending analytics events. All invocations of
 * `window.analytics` should be encapsulated within this service, to ensure that
 * we can centralize any API updates or breaking changes.
 *
 * To learn more about our implementation of Segment, see docs at
 * https://bridgecare.atlassian.net/l/cp/CHEHV1PL
 */
export default class SegmentService {
  constructor(portal, analytics) {
    this.portal = portal;
    this.analytics = analytics ? analytics : ANALYTICS_STUB;
  }

  static forParent(analytics) {
    return new SegmentService('Parent', analytics);
  }

  static forProvider(analytics) {
    return new SegmentService('Provider', analytics);
  }

  static forSpecialist(analytics) {
    return new SegmentService('Specialist', analytics);
  }

  /**
   * Tracks a page navigation. Generally, this should only be called from a Vue
   * Router navigation callback at the top level of the application.
   *
   * @param {string} pageName
   */
  page(pageName) {
    this.analytics.page(`${this.portal}::${pageName}`);
  }

  /**
   * Saves a user record to Segment CDP. This should be called any time any user
   * successfully logs in to a session. This method should be provided with data
   * from the Member record.
   *
   * Note: If the login process will reload the page after a successful login,
   * this method must be awaited before the reload otherwise the identify call
   * will not be sent. Awaiting is not necessary if the login process performs a
   * client-side navigation.
   */
  async identifyMember(profile = {}) {
    if (!profile || typeof profile !== 'object' || !profile.id || !profile.organization_id) {
      return;
    }

    const {
      business_name: business,
      name,
      email,
      id: memberId,
      organization_id: organizationId,
      organization_name: organization,
    } = profile;

    // Inform Segment who this user is and what organization they belong to
    await Promise.allSettled([
      this.analytics.identify(memberId, { name, email, business, organization }),
      this.analytics.group(organizationId, null, memberId),
    ]);
  }

  /*
  Runs a track for a custom event. The only required parameter is eventName.
  Segment recommends event names built from a noun and past-tense verb, and they
  be specific.
  Question 12 Back Button Clicked >  Button Click

  Properties are arbitrary values passed into the track call, likely specific
  to each track event. Likely some identifying attributes like a program id,
  user id, organization id, if certain options were enabled, etc.

  Options are Segment tracking options, one example being downstream integrations
  to enable or disable for the call:
  {integrations: {'All': false, 'Intercom': true}}

  The callback is to be run after the request finishes, or 300ms. Use to prevent
  navigating away or changing the page too much before the event is properly
  tracked.
  */
  async track(eventName, properties = {}, options = {}, callback) {
    // Enforce certain properties are always present?
    await this.analytics.track(eventName, properties, options, callback);
  }
}
