import _ from 'lodash';
import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  base_url() {
    return `${window.api_url}/providers`;
  },

  destroy(id, success) {
    axios.delete(`${this.base_url()}/${id}`).then(success);
  },

  get(id, success) {
    if (success) return axios.get(`${this.base_url()}/${id}`).then(success);

    return axios.get(`${this.base_url()}/${id}`).catch(handleError);
  },

  index(query, success) {
    axios.get(`${this.base_url()}?query=${query}`).then(success);
  },

  license: {
    base_url(providerId) {
      return `${window.api_url}/providers/${providerId}/license`;
    },

    show(providerId, success) {
      axios.get(this.base_url(providerId)).then(success);
    },
  },

  opening: {
    base_url(providerId) {
      return `${window.api_url}/providers/${providerId}/openings`;
    },

    create(providerId, opening, success, failure) {
      axios.post(this.base_url(providerId), { opening }).then(success).catch(failure);
    },

    destroy(providerId, id, success) {
      axios.delete(`${this.base_url(providerId)}/${id}`).then(success);
    },

    index(providerId, success) {
      axios.get(this.base_url(providerId)).then(success);
    },

    update(providerId, id, opening, success = _.identity, failure = handleError) {
      return axios
        .patch(`${this.base_url(providerId)}/${id}`, { opening })
        .then(success)
        .catch(failure);
    },

    updateOrCreate(providerId, opening, success, failure) {
      if (opening.id) {
        this.update(providerId, opening.id, opening, success, failure);
      } else {
        this.create(providerId, opening, success, failure);
      }
    },
  },

  staff_member: {
    base_url(providerId) {
      return `${window.api_url}/providers/${providerId}/staff_members`;
    },

    create(providerId, staff_member, success, failure) {
      axios.post(this.base_url(providerId), { staff_member }).then(success).catch(failure);
    },

    destroy(providerId, id, success) {
      axios.delete(`${this.base_url(providerId)}/${id}`).then(success);
    },

    index(providerId, success) {
      axios.get(this.base_url(providerId)).then(success);
    },

    update(providerId, id, staff_member, success, failure) {
      axios
        .patch(`${this.base_url(providerId)}/${id}`, { staff_member })
        .then(success)
        .catch(failure);
    },

    updateOrCreate(providerId, staff_member, success, failure) {
      if (staff_member.id) {
        this.update(providerId, staff_member.id, staff_member, success, failure);
      } else {
        this.create(providerId, staff_member, success, failure);
      }
    },
  },

  update(id, provider, success, failure) {
    if (!success) return axios.patch(`${this.base_url()}/${id}`, { provider }).catch(handleError);
    return axios.patch(`${this.base_url()}/${id}`, { provider }).then(success).catch(failure);
  },
};
