import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/parent/forms`;
    if (id) url += `/${id}`;
    return url;
  },

  create(form) {
    return axios.post(this.baseUrl(), { form }).catch(handleError);
  },

  get(id, success) {
    return axios.get(this.baseUrl(id)).then(success);
  },

  index(filters, success) {
    return axios.get(this.baseUrl(), { params: filters }).then(success);
  },

  update(id, form, success) {
    if (!success) return axios.patch(this.baseUrl(id), { form }).catch(handleError);

    return axios.patch(this.baseUrl(), { form }).then(success);
  },
};
