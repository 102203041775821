<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
  >
    <v-row dense>
      <v-col
        class="labeled-input mb-2"
        cols="12"
      >
        {{ $t(label) }}
      </v-col>
    </v-row>
    <v-row
      class="d-flex align-center"
      dense
    >
      <v-col
        cols="12"
        lg="3"
      >
        <v-text-field
          @click="toggleOpenDateDialog"
          :aria-label="$t(label)"
          :density="dense ? 'compact' : undefined"
          :disabled="$attrs.disabled || locked"
          :label="$t('Day')"
          :model-value="localeDateString"
          :placeholder="$attrs.disabled ? $t(disabledMessage) : ''"
          variant="filled"
          hide-details
          readonly
          tile
        />

        <v-dialog
          v-model="open_date_dialog"
          width="320px"
        >
          <v-date-picker
            v-model="dateObject"
            @update:model-value="setDate"
            :allowed-dates="allowedDates"
            scrollable
          />
        </v-dialog>
      </v-col>
      <v-col
        cols="3"
        lg="2"
      >
        <v-text-field
          v-model="hours"
          :label="$t('Hour')"
          placeholder="0"
          suffix=":"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col
        cols="3"
        lg="2"
      >
        <v-text-field
          v-model="minutes"
          :label="$t('Minute')"
          placeholder="00"
          variant="filled"
          hide-details
        />
      </v-col>
      <v-col
        cols="4"
        lg="3"
      >
        <v-select
          v-model="zone"
          :items="['-08:00', '-07:00', '-06:00', '-05:00', '-04:00']"
          :label="$t('Timezone')"
          variant="filled"
          disabled
          hide-details
        />
      </v-col>
      <v-col
        cols="2"
        lg="2"
      >
        <v-btn
          @click="clear"
          color="red"
          data-testid="close-button"
          variant="text"
          icon
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import api from '@/shared/services/all_bright_finder';
import Labeled from '@/shared/mixins/labeled';
import Schematized from '@/shared/mixins/schematized';
import { format, parse } from 'date-fns';
import { format as formatTz, formatInTimeZone } from 'date-fns-tz';
import { toIanaTimeZone } from '@/shared/services/date-helper';

export default {
  compatConfig: { MODE: 3 },

  mixins: [Labeled, Schematized],

  props: {
    disabledMessage: {
      type: String,
      default: null,
    },
    disallowBeforeDate: {
      type: String,
      default: null,
    },
    localeDate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      date: null,
      hours: null,
      minutes: null,
      zone: null,
      open_date_dialog: false,
      orgTimeZone: null,
    };
  },

  created() {
    void this.loadOrganization();
  },

  computed: {
    dateObject() {
      return this.date ? parse(this.date, 'yyyy-MM-dd', new Date()) : new Date();
    },

    localeDateString() {
      if (!this.date) return null;

      if (!this.localeDate)
        return formatInTimeZone(
          new Date(`${this.date} ${this.hours}:${this.minutes}:00 ${this.zone}`),
          this.orgTimeZone,
          'yyyy-MM-dd',
        );

      return new Date(this.date).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'utc',
      });
    },

    watchItems() {
      return [this.modelValue, this.orgTimeZone];
    },
  },

  watch: {
    watchItems: {
      immediate: true,
      handler(newVal) {
        let [value, timezone] = newVal;

        if (value && timezone) {
          value = formatInTimeZone(new Date(value), timezone, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

          const date = value.substring(0, 10);
          const time = value.split('.')[0].split('T')[1];
          const zone = value.substring(23, value.length);
          const [hours, minutes] = time?.split(':') ?? [null, null];

          this.date ||= date;
          this.hours ||= hours;
          this.minutes ||= minutes;
          this.zone ||= zone;
        } else {
          this.date = null;
          this.time = null;
          this.zone = null;
        }
      },
    },

    date() {
      this.processUpdate();
    },

    hours() {
      this.processUpdate();
    },

    minutes() {
      this.processUpdate();
    },

    zone() {
      this.processUpdate();
    },
  },

  methods: {
    allowedDates(date) {
      if (this.disallowBeforeDate) {
        if (new Date(date) >= new Date(this.disallowBeforeDate)) {
          return true;
        }
        return false;
      }
      return true;
    },

    clear() {
      this.date = null;
      this.hours = null;
      this.minutes = null;
      this.zone = null;
      this.handleUpdateModelValue(null);
      this.handleBlur(null);
    },

    processUpdate() {
      if (this.date) {
        if (!this.hours) {
          this.hours = format(new Date(), 'HH');
        }

        if (!this.minutes) {
          this.minutes = format(new Date(), 'mm');
        }

        if (!this.zone) {
          this.zone = formatTz(this.date, 'XXX', {
            timeZone: this.orgTimeZone,
          });
        }
      }

      if (this.date instanceof Date) {
        this.date = format(this.date, 'yyyy-MM-dd');
      }

      if (this.date && this.hours && this.minutes && this.zone) {
        const newVal = [
          this.date,
          'T',
          this.hours.padStart(2, '0'),
          ':',
          this.minutes.padStart(2, '0'),
          ':00.000',
          this.zone,
        ].join('');
        if (newVal !== this.modelValue) {
          this.handleUpdateModelValue(newVal);
          this.handleBlur(newVal);
        }
      }
    },

    setDate(value) {
      this.toggleOpenDateDialog();
      this.date = value;
    },

    toggleOpenDateDialog() {
      this.open_date_dialog = !this.open_date_dialog;
    },

    async loadOrganization() {
      const { data: organizationData } = await api.admin.organization.get();
      this.orgTimeZone = toIanaTimeZone(organizationData.time_zone) ?? 'America/Los_Angeles';
    },
  },
};
</script>
