import axios from 'axios';

import { handleError } from '@/shared/services/api';

export default {
  baseUrl(id) {
    let url = `${window.api_url}/organization/reviews`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  },

  async create(review) {
    try {
      return await axios.post(this.baseUrl(), { review });
    } catch (err) {
      return handleError(err);
    }
  },

  async destroy(id) {
    try {
      return await axios.delete(this.baseUrl(id));
    } catch (err) {
      return handleError(err);
    }
  },

  async index(filters) {
    try {
      return await axios.get(this.baseUrl(), { params: filters });
    } catch (err) {
      return handleError(err);
    }
  },

  async update(id, review) {
    try {
      return await axios.patch(this.baseUrl(id), { review });
    } catch (err) {
      return handleError(err);
    }
  },
};
