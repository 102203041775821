import { EventBus } from '@/plugins/event-bus';

/**
 * @deprecated This mixin is being replaced by useLabeledField.js
 * See LabeledTextfield.vue for usage example
 */
export default {
  compatConfig: { MODE: 3 },

  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: String,
      default: '12',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
    md: {
      type: String,
      default: null,
    },
    lg: {
      type: String,
      default: null,
    },
    global: {
      default: false,
      type: Boolean,
    },
    outlined: {
      default: false,
      type: Boolean,
    },
    sm: {
      type: String,
      default: null,
    },
    veryDense: {
      type: Boolean,
      default: false,
    },
    modelValue: null,
  },

  data() {
    return {
      changed: false,
      localValue: this.modelValue,
    };
  },

  emits: ['blur', 'change', 'input', 'update:modelValue'],

  computed: {
    appendIcon() {
      if (this.$attrs['append-icon']) {
        return this.$attrs['append-icon'];
      }
      return this.locked ? 'lock' : '';
    },

    labelClass() {
      if (this.bold) {
        return 'fw-600 fs-18';
      }
      return 'labeled-input';
    },
  },

  watch: {
    modelValue(val) {
      this.localValue = val;
    },
  },

  methods: {
    handleBlur(event) {
      this.$emit('blur', event);
      if (this.global) {
        EventBus.$emit('blur', event);
      }
    },

    handleUpdateModelValue(event) {
      this.localValue = event;
      this.$emit('update:modelValue', event);
      this.$emit('change', event);
      this.$emit('input', event);
    },
  },
};
