import axios from 'axios';
import _ from 'lodash';

import { denormalizeResponse } from './json-api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/reports`;
  },

  create(report, success, failure) {
    axios.post(this.baseUrl(), { report }).then(success).catch(failure);
  },

  destroy(id, success) {
    return axios.delete(`${this.baseUrl()}/${id}`).then(success);
  },

  async get(id, success = _.identity) {
    const response = await axios.get(`${this.baseUrl()}/${id}`).then(success);
    return denormalizeResponse(response);
  },

  async index(params, success = _.identity) {
    const response = await axios.get(this.baseUrl(), { params }).then((resp) => {
      success(denormalizeResponse(resp));
      return resp;
    });
    return denormalizeResponse(response);
  },

  update(id, report, success, failure) {
    const url = `${this.baseUrl()}/${id}`;
    if (success) {
      return axios.patch(url, { report }).then(success).catch(failure);
    }

    return axios.patch(url, { report });
  },

  report_download: {
    baseUrl(reportId) {
      return `${window.api_url}/organization/reports` + `/${reportId}/report_downloads`;
    },

    create(reportId, report_download, success, failure) {
      axios.post(this.baseUrl(reportId), { report_download }).then(success).catch(failure);
    },

    get(reportId, reportDownloadId, success) {
      axios.get(`${this.baseUrl(reportId)}/${reportDownloadId}`).then(success);
    },

    downloadUrl(reportId, filename, reportDownload) {
      const format = reportDownload.format.split('/')[1];
      return `${this.baseUrl(reportId)}/${reportDownload.id}.${format}?filename=${filename}.${
        format
      }`;
    },

    index(reportId, success) {
      axios.get(this.baseUrl(reportId)).then(success);
    },
  },

  report_schedule: {
    baseUrl(reportId) {
      return `${window.api_url}/organization/reports` + `/${reportId}/report_schedules`;
    },

    create(reportId, reportSchedule, success, failure) {
      axios
        .post(this.baseUrl(reportId), { report_schedule: reportSchedule })
        .then(success)
        .catch(failure);
    },

    destroy(reportId, id, success) {
      axios.delete(`${this.baseUrl(reportId)}/${id}`).then(success);
    },

    get(reportId, id, success) {
      axios.get(`${this.baseUrl(reportId)}/${id}`).then(success);
    },

    index(reportId, success) {
      axios.get(this.baseUrl(reportId)).then(success);
    },

    update(reportId, id, reportSchedule, success, failure) {
      axios
        .patch(`${this.baseUrl(reportId)}/${id}`, {
          report_schedule: reportSchedule,
        })
        .then(success)
        .catch(failure);
    },
  },

  share_token: {
    baseUrl(reportId) {
      return `${window.api_url}/organization/reports/${reportId}/share_token`;
    },

    create(reportId) {
      return axios.post(this.baseUrl(reportId));
    },

    destroy(reportId) {
      return axios.delete(this.baseUrl(reportId));
    },

    get(reportId) {
      return axios.get(this.baseUrl(reportId));
    },
  },
};
